import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewRef } from '@angular/core';
import PERMISSIONS from 'src/app/auth/state/permissions';
import Viewer from 'viewerjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { SafeResourceUrl } from '@angular/platform-browser';

const ZOOM_RATIO = 0.4;
@UntilDestroy()
@Component({
  selector: 'single-image-preview',
  templateUrl: './single-image-preview.component.html',
  styleUrls: ['./single-image-preview.component.scss']
})
export class SingleImagePreviewComponent implements OnInit {
  PERMISSIONS = PERMISSIONS;

  @ViewChild('previewImage') set previewImage(previewImage: ElementRef) {
    if (previewImage?.nativeElement) {
      this.initPreviewImageViewer(previewImage.nativeElement);
    }
  }

  @Input()
  set image(value: SafeResourceUrl | string) {
    this._image = value;
    if (this.viewer) {
      // Must call update on next tick
      setTimeout(() => this.viewer.update(), 0);
    }
  }
  get image() {
    return this._image;
  }
  private _image: SafeResourceUrl | string;

  @Input() name: string;
  @Input() downloadUrl: string;

  private viewer: Viewer;

  viewerFullscreenButton: HTMLElement;
  loadingImage = false;

  constructor(private elementRef: ElementRef, private http: HttpClient) {}

  ngOnInit(): void {}

  private initPreviewImageViewer(previewImageElement: HTMLElement) {
    if (this.viewer) {
      return;
    }

    this.viewer = new Viewer(previewImageElement, {
      zoomRatio: ZOOM_RATIO,
      inline: true,
      title: false,
      navbar: false,
      toolbar: false,
      button: false,
      transition: false,
      tooltip: false,
      toggleOnDblclick: false,
      ready: () => {
        const elements = this.elementRef.nativeElement.getElementsByClassName('viewer-button');
        if (elements && elements.length > 0) {
          this.viewerFullscreenButton = elements[0] as HTMLElement;
        }
      },
      view: () => this.setLoadingImage(true),
      viewed: () => this.setLoadingImage(false)
    });
  }

  north() {
    // todo: not implemented yet
  }

  zoomOut() {
    this.viewer.zoom(-ZOOM_RATIO);
  }

  zoomIn() {
    this.viewer.zoom(ZOOM_RATIO);
  }

  oneToOne() {
    this.viewer.zoomTo(1);
  }

  fit() {
    this.viewer.reset();
  }

  fullScreen() {
    this.viewer.full();
    this.viewerFullscreenButton.classList.remove('viewer-hide');
    this.viewerFullscreenButton.addEventListener('click', this.fullScreenCloseClick);

    const elements = this.elementRef.nativeElement.getElementsByClassName('viewer-fixed viewer-backdrop');
    if (elements && elements.length > 0) {
      const viewerBackdrop = elements[0] as HTMLElement;
      viewerBackdrop.addEventListener('click', this.backdropClick);
    }
  }

  download() {
    this.http
      .get(this.downloadUrl, { responseType: 'blob' })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: blob => saveAs(blob, this.name),
        error: () => {
          // If fails, save URL directly
          saveAs(this.downloadUrl, this.name);
        }
      });
  }

  private fullScreenCloseClick = () => {
    this.viewerFullscreenButton.classList.add('viewer-hide');
    this.viewerFullscreenButton.removeEventListener('click', this.fullScreenCloseClick);
  };

  private backdropClick = (event: MouseEvent) => {
    const element = event.target as Element;
    if (!element.classList.contains('viewer-move')) {
      element.removeEventListener('click', this.backdropClick);
      this.viewerFullscreenButton.classList.add('viewer-hide');
      this.viewer.exit();
    }
  };

  private setLoadingImage(loading: boolean) {
    this.loadingImage = loading;
  }
}
