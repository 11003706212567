import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
import { SnackBarService } from '../services/snackbar.service';

export interface DroppedFile {
  file: File;
  path: string;
}

@Component({
  selector: 'drag-and-drop-container',
  templateUrl: './drag-and-drop-container.component.html',
  styleUrls: ['./drag-and-drop-container.component.scss']
})
export class DragAndDropContainerComponent implements OnInit {
  @ViewChild(NgxFileDropComponent) ngxFileDrop: NgxFileDropComponent;

  @Input() disabled = false;
  @Input() isEmpty = true;
  @Input() isLoading = false;
  @Input() showCustomContent = false;
  @Input() uploadedCount = 0;
  @Input() filteredCount = 0;
  @Input() contentType: string;
  @Input() hideAddDeleteButtons = false;
  @Input() validFileSuffixes: string[];

  @Output() dropped = new EventEmitter<DroppedFile[]>();
  @Output() deleteAll = new EventEmitter();

  constructor(private snackbar: SnackBarService) {}

  ngOnInit() {}

  async onDrop(files: NgxFileDropEntry[]) {
    if (this.disabled) {
      return;
    }
    this.isLoading = true;
    const validFilesPromises = files.filter(f => f.fileEntry.isFile && this.isValidFileType(f)).map(this.fileEntryToFile);
    const validDroppedFiles = await Promise.all(validFilesPromises);

    if (validDroppedFiles.length !== files.length) {
      const invalidTypeCount = files.length - validDroppedFiles.length;
      this.snackbar.open(
        `${invalidTypeCount} invalid file${invalidTypeCount > 1 ? 's' : ''} ${invalidTypeCount > 1 ? 'were' : 'was'} skipped`
      );
    }
    this.dropped.emit(validDroppedFiles);
    this.isLoading = false;
  }

  onDeleteAll() {
    if (this.disabled) {
      return;
    }
    this.deleteAll.emit();
  }

  openFileSelector() {
    if (this.disabled) {
      return;
    }
    this.ngxFileDrop.openFileSelector();
  }

  private isValidFileType = (file: NgxFileDropEntry) => {
    return file ? this.validFileSuffixes.some(type => file.fileEntry.name.toLowerCase().endsWith(type)) : false;
  };

  private fileEntryToFile = (fileDropEntry: NgxFileDropEntry): Promise<DroppedFile> => {
    return new Promise(resolve => {
      const fileEntry = fileDropEntry.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => resolve({ file, path: fileDropEntry.relativePath }));
    });
  };

  get accept() {
    return this.validFileSuffixes.join(',');
  }
}
