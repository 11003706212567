import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';
import { BehaviorSubject } from 'rxjs';
import {
  ACTIVITY_FILTERING_STORAGE_KEY,
  Activity,
  ActivityFiltering,
  ActivityFinishingFilterType,
  ActivityGeometricFilterType,
  ActivityMeasurement,
  ActivityMeasurementEditor,
  ActivitySorting,
  ActivitySortingType,
  ProjectPlan,
  ProjectPlanUploadingState
} from './detailed-site-activities.model';

export interface DetailedSiteActivitiesState {
  projectPlanUploadingState: ProjectPlanUploadingState;
  activitiesLoading: boolean;
  sorting: { type: ActivitySortingType; isDesc: boolean };
  filtering: {
    geometricFilter: ActivityGeometricFilterType;
    finishingFilter: ActivityFinishingFilterType;
  };
  activityMeasurementsLoading: boolean;
  tempPlannedDesignMeasurement: ActivityMeasurement; // is used to keep a new measurement from the moment a user associate it from design until the selected activity and measurements are fetched
  activityMeasurementEditors: ActivityMeasurementEditor[];
}

export function createInitialState(): DetailedSiteActivitiesState {
  return {
    projectPlanUploadingState: null,
    sorting: { type: ActivitySortingType.ID, isDesc: false },
    filtering: { geometricFilter: null, finishingFilter: null },
    activitiesLoading: false,
    activityMeasurementsLoading: false,
    tempPlannedDesignMeasurement: null,
    activityMeasurementEditors: []
  };
}

interface ProjectPlanState extends EntityState<ProjectPlan>, ActiveState {}
interface ActivityState extends EntityState<Activity>, ActiveState {}
interface ActivityMeasurementState extends EntityState<ActivityMeasurement> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-activities' })
export class DetailedSiteActivitiesStore extends Store<DetailedSiteActivitiesState> {
  projectPlans = new EntityStore<ProjectPlanState>({ active: null }, { name: 'project-plan', idKey: 'id' });
  activities = new EntityStore<ActivityState>({ active: null }, { name: 'activity', idKey: 'id' });
  activityMeasurements = new EntityStore<ActivityMeasurementState>({}, { name: 'activity-measurement', idKey: 'id' });
  deletedCountMeasurementIdsSubject$ = new BehaviorSubject<string>(undefined);
  addOrUpdateCountMeasurementsSubject$ = new BehaviorSubject<ActivityMeasurement>(undefined);

  constructor() {
    super(createInitialState());
  }

  resetStore() {
    this.reset();
    this.projectPlans.reset();
    this.activities.reset();
    this.activityMeasurements.reset();
    this.resetActivityMeasurementEditors();
  }

  upsertActivities(activities: Activity[]) {
    this.activities.upsertMany(activities);
  }

  upsertActivityMeasurements(measurements: ActivityMeasurement[]) {
    this.activityMeasurements.upsertMany(measurements);
  }

  setProjectPlanUploadingState(uploadingState: Partial<ProjectPlanUploadingState>) {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        if (uploadingState) {
          draftState.projectPlanUploadingState = {
            totalSize: (uploadingState.totalSize || draftState.projectPlanUploadingState?.totalSize) ?? 0,
            uploadedSize: (uploadingState.uploadedSize || draftState.projectPlanUploadingState?.uploadedSize) ?? 0
          };
        } else {
          draftState.projectPlanUploadingState = null;
        }
      })
    );
  }

  setSorting(sorting: ActivitySorting) {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        draftState.sorting = sorting;
      })
    );
  }

  setFiltering(filtering: ActivityFiltering) {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        draftState.filtering = filtering;
      })
    );
  }

  setActivitiesLoading(isLoading: boolean = true) {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        draftState.activitiesLoading = isLoading;
      })
    );
  }

  setActivityMeasurementsLoading(isLoading: boolean = true) {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        draftState.activityMeasurementsLoading = isLoading;
      })
    );
  }

  setTempPlannedDesignMeasurement(measurement: ActivityMeasurement) {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        draftState.tempPlannedDesignMeasurement = measurement;
      })
    );
  }

  resetActivityMeasurementEditors() {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        draftState.activityMeasurementEditors.forEach(measurementEditor => measurementEditor.editor$.dispose());
        draftState.activityMeasurementEditors = [];
      })
    );
  }

  addActivityMeasurementEditors(editors: ActivityMeasurementEditor[]) {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        draftState.activityMeasurementEditors = [...draftState.activityMeasurementEditors, ...editors];
      })
    );
  }

  removeEditorsByMeasurementIds(measurementIds: string[]) {
    this.update(
      produce((draftState: DetailedSiteActivitiesState) => {
        const editorsToDispose = draftState.activityMeasurementEditors.filter(measurementEditor =>
          measurementIds.includes(measurementEditor.measurementId)
        );
        editorsToDispose.forEach(measurementEditor => measurementEditor.editor$.dispose());

        draftState.activityMeasurementEditors = draftState.activityMeasurementEditors.filter(
          measurementEditor => !measurementIds.includes(measurementEditor.measurementId)
        );
      })
    );
  }

  resetCountMapBehaviorSubject() {
    this.deletedCountMeasurementIdsSubject$ = new BehaviorSubject<string>(undefined);
    this.addOrUpdateCountMeasurementsSubject$ = new BehaviorSubject<ActivityMeasurement>(undefined);
  }

  restoreFiltering() {
    try {
      const filtersStr = localStorage.getItem(ACTIVITY_FILTERING_STORAGE_KEY);
      if (filtersStr) {
        this.setFiltering(JSON.parse(filtersStr));
      }
    } catch (error) {
      console.error('Error while restoring sidenav activity filtering from storage', error);
    }
  }
}
