import { Component, OnInit, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, DialogPosition } from '@angular/material/dialog';

export interface LimitReachedDialogData {
  title: string;
  content: string;
  emailSubject?: string;
  contactSupportButtonCaption?: string;
  position?: DialogPosition;
  direction?: 'TOP_RIGHT' | 'TOP_LEFT' | 'RIGHT' | 'LEFT' | 'BOTTOM_RIGHT' | 'BOTTOM_LEFT';
}

@Component({
  template: `
    <limit-reached
      class="column full-height container"
      [titleText]="data.title"
      [content]="data.content"
      [emailSubject]="data.emailSubject || 'Upgrade program request'"
      [contactSupportButtonCaption]="data.contactSupportButtonCaption || 'Send upgrade request'"
      [class.top-left]="!data.direction || data.direction === 'TOP_LEFT'"
      [class.top-right]="data.direction === 'TOP_RIGHT'"
      [class.bottom-right]="data.direction === 'BOTTOM_RIGHT'"
      [class.bottom-left]="data.direction === 'BOTTOM_LEFT'"
      [class.right]="data.direction === 'RIGHT'"
      [class.left]="data.direction === 'LEFT'"
    ></limit-reached>
    <button mat-icon-button class="close-button" (click)="close()" title="Close">
      <mat-icon>close</mat-icon>
    </button>
  `,
  styleUrls: ['./limit-reached-popup.component.scss']
})
export class LimitReachedPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  backdrop: HTMLElement;

  constructor(@Inject(MAT_DIALOG_DATA) public data: LimitReachedDialogData, private dialogRef: MatDialogRef<LimitReachedPopupComponent>) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.backdrop = document.getElementsByClassName('limit-reached-dialog-backdrop')[0] as HTMLElement;
    if (this.backdrop) {
      this.backdrop.addEventListener('click', this.stopPropagation);
    }
  }

  ngOnDestroy() {
    if (this.backdrop) {
      this.backdrop.removeEventListener('click', this.stopPropagation);
    }
  }

  private stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  close() {
    this.dialogRef.close();
  }
}
