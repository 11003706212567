<div class="row top-row">
  <label class="title">Permission Level</label>
  <label class="caption">Sets what the user can do</label>
</div>

<div class="column slider" [style.width.px]="(accounUserLevels.length - 1) * 150">
  <mat-slider
    [min]="0"
    [max]="accounUserLevels.length - 1"
    [step]="1"
    [disabled]="disabled"
  >
    <input matSliderThumb [value]="sliderValue" (valueChange)="sliderChange($event)" />
  </mat-slider>

  <div class="row space-between slider-ticks">
    <div
      #levelTicks
      class="level-tick"
      [class.disabled]="disabled"
      *ngFor="let level of accounUserLevels; let i = index"
      (click)="levelChange(level)"
      (mouseenter)="onHoveredLevel(level)"
      (mouseleave)="onHoveredLevel(null)"
    >
      <mat-icon
        [class.filled]="i < sliderValue"
        [class.hidden]="i === sliderValue"
        [svgIcon]="i < sliderValue ? 'filled-circle' : 'unfilled-circle'"
      ></mat-icon>
    </div>
  </div>

  <div class="row space-between slider-labels">
    <label
      class="level-label"
      [class.disabled]="disabled"
      *ngFor="let level of accounUserLevels"
      (click)="levelChange(level)"
      (mouseenter)="onHoveredLevel(level)"
      (mouseleave)="onHoveredLevel(null)"
    >
      {{ getAccessLevelLabel(level) }}
    </label>
  </div>

  <div
    class="column space-between permissions-popup"
    *ngIf="hoveredPopup"
    [style.top.px]="hoveredPopup.top"
    [style.left.px]="hoveredPopup.left"
    [class.vertical-top]="hoveredPopup.verticalPosition === 'top'"
  >
    <label class="title">{{ getAccessLevelLabel(hoveredPopup.level) }}</label>
    <div class="row permission" *ngFor="let permission of hoveredPopup.permissions">
      <mat-icon [style.color]="permission.value ? 'var(--good)' : 'var(--error)'">{{ permission.value ? 'done' : 'close' }}</mat-icon>
      <label>{{permission.name}}</label>
    </div>
  </div>
</div>
