import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'filling-square-loader',
  template: `
    <span class="loader" [ngStyle]="{ width: size + 'px', height: size + 'px', borderWidth: borderWidth + 'px' }">
      <span class="loader-inner"></span>
    </span>
  `,
  styleUrls: ['./filling-square-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FillingSquareLoaderComponent implements OnInit {
  @Input() size: number;
  @Input() borderWidth = 4;

  constructor() {}

  ngOnInit() {}
}
