import localforage from 'localforage';

const BACKUP_METADATA_KEY = '__metadata';

export interface BackupMetadata {
  isValid?: boolean;

  // Any other value the user wants to add
  [key: string]: any;
}

export class OfflineBackup {
  private backup: LocalForage;

  private constructor(backup: LocalForage) {
    this.backup = backup;
  }

  static async createBackup(backupName: string) {
    if (!OfflineBackup.isSupported()) {
      return null;
    }

    try {
      const backup = localforage.createInstance({
        driver: localforage.INDEXEDDB,
        name: backupName
      });

      // Clear backup if already exist
      await backup.clear();

      return new OfflineBackup(backup);
    } catch (error) {
      console.error('Error creating backup', error);
      return null;
    }
  }

  static async getBackup(backupName: string) {
    if (!OfflineBackup.isSupported()) {
      return null;
    }

    try {
      let backup = localforage.createInstance({
        driver: localforage.INDEXEDDB,
        name: backupName
      });

      const metadata = await backup.getItem<BackupMetadata>(BACKUP_METADATA_KEY);
      if (!metadata) {
        return null;
      }

      if (!metadata.isValid) {
        // Clear invalid backup before returning it
        await backup.clear();
      }

      return new OfflineBackup(backup);
    } catch (error) {
      console.error('Error getting backup', error);
      return null;
    }
  }

  static async hasBackup(backupName: string) {
    if (!OfflineBackup.isSupported()) {
      return false;
    }

    try {
      const backup = localforage.createInstance({
        driver: localforage.INDEXEDDB,
        name: backupName
      });

      const metadata = await backup.getItem<BackupMetadata>(BACKUP_METADATA_KEY);
      if (!metadata) {
        return false;
      } else if (!metadata.isValid) {
        // Drop invalid backup and create new one
        await backup.dropInstance();
        return false;
      }

      return true;
    } catch (error) {
      console.error('Error getting backup', error);
      return false;
    }
  }

  static isSupported() {
    return localforage.supports(localforage.INDEXEDDB);
  }

  static async dropInstance(name: string) {
    return await localforage.dropInstance({ name });
  }

  async setItem<T>(key: string, value: T) {
    if (key === BACKUP_METADATA_KEY) {
      return null;
    }

    return this.backup.setItem<T>(key, value);
  }

  async setItems<T>(items: [string, T][]) {
    await this.updateValidity(false);

    await Promise.all(items.map(([key, value]) => this.setItem<T>(key, value)));

    await this.updateValidity(true);
  }

  async getItem<T>(key: string) {
    if (key === BACKUP_METADATA_KEY) {
      return null;
    }

    return await this.backup.getItem<T>(key);
  }

  async getAllValues<T>() {
    const values: T[] = [];

    await this.backup.iterate<T, void>((value, key) => {
      if (key !== BACKUP_METADATA_KEY) {
        values.push(value);
      }
    });

    return values;
  }

  async removeItem(key: string) {
    if (key === BACKUP_METADATA_KEY) {
      return null;
    }

    return await this.backup.removeItem(key);
  }

  async removeItems(keys: string[]) {
    await Promise.all(keys.map(key => this.removeItem(key)));
  }

  async setMetadata(metadata: BackupMetadata) {
    return await this.backup.setItem(BACKUP_METADATA_KEY, metadata);
  }

  async getMetadata() {
    return await this.backup.getItem<BackupMetadata>(BACKUP_METADATA_KEY);
  }

  async updateMetadata(updatedMetadata: Partial<BackupMetadata>) {
    const metadata = await this.getMetadata();
    return await this.setMetadata({ ...(metadata || {}), ...updatedMetadata });
  }

  private async updateValidity(isValid: boolean) {
    return await this.updateMetadata({ isValid });
  }

  async dropInstance() {
    return await this.backup.dropInstance();
  }
}
