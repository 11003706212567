/**
 * Softimize Swagger
 *
 *
 * OpenAPI spec version: 1.7.5
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetBatchResponse {
  id?: string;
  jobId?: string;
  state?: GetBatchResponse.StateEnum;
  type?: GetBatchResponse.TypeEnum;
}
export namespace GetBatchResponse {
  export type StateEnum = 'PROCESSING' | 'READY' | 'FAILED';
  export const StateEnum = {
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum
  };
  export type TypeEnum =
    | 'I_DPC'
    | 'DPC'
    | 'LINK'
    | 'DTM_TERRAIN'
    | 'GCP'
    | 'SBA'
    | 'AUTOGEOREF'
    | 'CONTOURS'
    | 'TEXTURE'
    | 'ELEC_POLES_AI'
    | 'ML';
  export const TypeEnum = {
    IDPC: 'I_DPC' as TypeEnum,
    DPC: 'DPC' as TypeEnum,
    LINK: 'LINK' as TypeEnum,
    DTMTERRAIN: 'DTM_TERRAIN' as TypeEnum,
    GCP: 'GCP' as TypeEnum,
    SBA: 'SBA' as TypeEnum,
    AUTOGEOREF: 'AUTOGEOREF' as TypeEnum,
    CONTOURS: 'CONTOURS' as TypeEnum,
    TEXTURE: 'TEXTURE' as TypeEnum,
    ELECPOLESAI: 'ELEC_POLES_AI' as TypeEnum,
    ML: 'ML' as TypeEnum
  };
}
