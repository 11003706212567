/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetCustomFieldResponse {
  id?: string;
  inUse?: boolean;
  name?: string;
  valueType?: GetCustomFieldResponse.ValueTypeEnum;
}
export namespace GetCustomFieldResponse {
  export type ValueTypeEnum = 'STRING' | 'DATE' | 'DOUBLE' | 'BOOLEAN';
  export const ValueTypeEnum = {
    STRING: 'STRING' as ValueTypeEnum,
    DATE: 'DATE' as ValueTypeEnum,
    DOUBLE: 'DOUBLE' as ValueTypeEnum,
    BOOLEAN: 'BOOLEAN' as ValueTypeEnum
  };
}
