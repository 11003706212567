import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth.store';
import { AccessLevelEnum, LogInStateEnum, UserRole, hasAccessLevel, jwtHelper } from './auth.utils';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  // Emits single 'true' value when logged in
  logIn$ = this.select(state => state.loggedIn).pipe(
    skipWhile(loggedIn => !loggedIn),
    take(1)
  );

  // Emits single 'true' value when logged out (after logged in)
  logOut$ = this.logIn$.pipe(
    switchMap(() => this.select(state => state.loggedIn)),
    skipWhile(loggedIn => loggedIn),
    map(() => true),
    take(1)
  );

  loginResponsesQuery = new QueryEntity(this.store.loginResponses);
  loginResponses$ = this.loginResponsesQuery.selectAll();
  loginResponsesLoading$ = this.loginResponsesQuery.selectLoading();
  activeTenantId$ = this.loginResponsesQuery.selectActiveId();

  constructor(protected store: AuthStore) {
    super(store);
  }

  getLoginResponses() {
    return this.loginResponsesQuery.getAll();
  }

  getValidLoginResponses() {
    return this.loginResponsesQuery.getAll({ filterBy: loginResponse => loginResponse.logInState === LogInStateEnum.SUCCESS });
  }

  getFirstValidLoginResponse() {
    return this.getValidLoginResponses()?.[0];
  }

  getLoginResponseByTenantId(tenantId: string) {
    return this.loginResponsesQuery.getEntity(tenantId);
  }

  isSessionResponsesExist() {
    return this.loginResponsesQuery.getCount() !== 0;
  }

  isActiveTokenExistsAndValid() {
    const accessToken = this.getActiveAccessToken();
    return accessToken && !jwtHelper.isTokenExpired(accessToken);
  }

  getActiveAccessToken() {
    return this.getActiveUserResponse()?.accessToken;
  }

  getActiveRefreshToken() {
    return this.getActiveUserResponse()?.refreshToken;
  }

  getAllRefreshTokens() {
    return this.getValidLoginResponses().map(resp => resp.refreshToken);
  }

  private getAllAccessTokens() {
    return this.getLoginResponses().map(resp => resp.accessToken);
  }

  areAccessTokensValid() {
    const tokens = this.getAllAccessTokens();
    return !!tokens && tokens.length && tokens.some(token => !!token && !jwtHelper.isTokenExpired(token));
  }

  getUserId() {
    return this.getActiveUserResponse()?.userId;
  }

  getActiveUserResponse() {
    return this.loginResponsesQuery.getActive();
  }

  getActiveTenantId() {
    return this.loginResponsesQuery.getActiveId() as string;
  }

  getActiveTenantName() {
    return this.getActiveUserResponse()?.tenantName;
  }

  getTenantById(tenantId: string) {
    return this.loginResponsesQuery.getEntity(tenantId);
  }

  getUserRole() {
    return this.getValue().userRole;
  }

  hasAccessLevel(accessLevel: AccessLevelEnum) {
    const userAccessLevel = this.getValue().userAccessLevel;
    return hasAccessLevel(userAccessLevel, accessLevel);
  }

  getDefaultUrl(userRole: UserRole) {
    if (userRole === UserRole.OPERATOR) {
      return '/flights';
    } else if (userRole === UserRole.ACCOUNT_USER) {
      return `/${this.getActiveTenantId()}/sites`;
    } else {
      return '/login';
    }
  }
}
