import { GetRemoteSiteResponse } from '../../generated/integration/model/getRemoteSiteResponse';
import { GetTenantIntegrationsResponse } from '../../generated/integration/model/getTenantIntegrationsResponse';
import { GetTenantConfigResponse } from '../../generated/package/model/getTenantConfigResponse';
import { GetCategoryResponse } from '../../generated/tenant/model/getCategoryResponse';
import { GetCoordinateSystemResponse } from '../../generated/tenant/model/getCoordinateSystemResponse';
import { GetSiteGroupResponse } from '../../generated/tenant/model/getSiteGroupResponse';
import { GetSiteResponse } from '../../generated/tenant/model/getSiteResponse';
import { GetTenantFeatureFlagNamesResponse } from '../../generated/tenant/model/getTenantFeatureFlagNamesResponse';
import { GetUserResponse } from '../../generated/ums/model/getUserResponse';
import { AccessLevelEnum } from '../auth/state/auth.utils';
import { DesignCustomProperty } from '../detailed-site/state/detailed-site-designs/detailed-site-designs.model';

export import FeatureFlagEnum = GetTenantFeatureFlagNamesResponse.FeatureFlagsEnum;
export type TenantConfig = GetTenantConfigResponse;
export type CoordinateSystem = GetCoordinateSystemResponse;
export type IntegrationProject = GetRemoteSiteResponse;

export type TenantIntegration = GetTenantIntegrationsResponse;

export interface SiteGroup extends GetSiteGroupResponse {
  unsaved?: boolean;
}

export interface User extends GetUserResponse {
  name?: string;
}

export interface Site extends GetSiteResponse {
  associations?: SiteAssociations;
  siteGroup?: SiteGroup; // Override siteGroup field to be of custom type
  designsCustomProperties?: DesignCustomProperty[];
}

export import IntegrationEnum = GetRemoteSiteResponse.IntegrationEnum;

export const INTEGRATION_LABEL = {
  [IntegrationEnum.PROCORE]: 'Procore'
};

export type SiteAssociations = {
  [name in IntegrationEnum]?: IntegrationProject;
};

export type SiteType = GetCategoryResponse;

export const CONFIG_UNLIMITED_PLACEHOLDER = 9999;

export const ACCESS_LEVEL_TO_COUNTER_MAP = {
  [AccessLevelEnum.TENANTADMIN]: 'analyticUsers',
  [AccessLevelEnum.ANALYTICSUSER]: 'analyticUsers',
  [AccessLevelEnum.BASICUSER]: 'basicUsers',
  [AccessLevelEnum.VIEWONLY]: 'viewOnlyUsers',
  [AccessLevelEnum.OPERATORUSER]: 'operatorUsers'
};
