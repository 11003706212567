import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'limit-reached',
  template: `
    <div class="row justify-center align-center title">{{ titleText }}</div>
    <div class="content-text full-container">{{ content }}</div>
    <div class="row space-around buttons">
      <button mat-button class="light-btn bottom-button" [disabled]="true" style="opacity: 0">View subscription plans</button>
      <a mat-raised-button class="dark-btn bottom-button" [href]="mailtoString">
        {{ contactSupportButtonCaption }}
      </a>
    </div>
  `,
  styleUrls: ['./limit-reached.component.scss']
})
export class LimitReachedComponent implements OnInit {
  mailtoString: string;

  @Input() emailSubject: string;
  @Input() contactSupportButtonCaption: string;
  @Input() titleText: string;
  @Input() content: string;

  constructor() {}

  ngOnInit() {
    const emailSubject = encodeURIComponent(this.emailSubject);
    this.mailtoString = String.raw`mailto:${environment.whitelabel.supportEmail}?subject=${emailSubject}`;
  }
}
