<dialog-layout [header]="user ? 'Edit user' : 'Create new user'" (closeDialog)="close()">
  <form [formGroup]="userForm" class="full-height details-form">
    <dialog-tab-group class="full-height" [(activeTabIndex)]="activeTabIndex">
      <dialog-tab caption="Personal Details" [formGroupName]="Tab.DETAILS" [error]="!isTabValid(Tab.DETAILS)"
        class="user-data-tab">
        <div class="row field-row">
          <label class="field-label">First Name *</label>
          <input formControlName="firstName" class="field-input-control" />
        </div>
        <div class="row field-row">
          <label class="field-label">Last Name *</label>
          <input formControlName="lastName" class="field-input-control" />
        </div>
        <div class="row field-row">
          <label class="field-label">Email *</label>
          <input formControlName="email" class="field-input-control" email />
        </div>
        <div class="row field-row">
          <label class="field-label">Title</label>
          <ng-select
            id="employeeTitle"
            class="field-select-control"
            bindLabel="title"
            [items]="employeeTitles"
            [selectOnTab]="true"
            [clearable]="true"
            [virtualScroll]="true"
            [appendTo]="'body'"
            formControlName="employeeTitle">
          </ng-select>
        </div>
        <div class="row field-row">
          <label class="field-label">Employee Number</label>
          <input formControlName="employeeNumber" class="field-input-control"/>
        </div>
      </dialog-tab>

      <dialog-tab caption="Permissions" [formGroupName]="Tab.PERMISSIONS" [error]="!isTabValid(Tab.PERMISSIONS)" class="user-data-tab permission-tab">
        <div class="column role-selector">
          <label class="title" [class.disable]="!!user">User Role</label>
          <mat-radio-group class="row" formControlName="userRole" [title]="!!user ? 'Editing user role is not possible' : ''">
            <mat-radio-button class="full-container" color="primary" [value]="UserRole.ACCOUNT_USER">
              Account User
            </mat-radio-button>
            <mat-radio-button class="full-container" color="primary" [value]="UserRole.OPERATOR">
              Operator
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="!isOperator" class="column field-row" #permissionsScrollContainer>
          <permission-level class="field-row" formControlName="permissionLevel"
            [scrollContainer]="permissionsScrollContainer.parentElement"></permission-level>
        </div>

        <!-- TODO: Removed for now -->
        <name-list
          *ngIf="false"
          class="field-row teams-container"
          title="Teams"
          subtitle="Sets which measurements & analytics the user can see"
          formControlName="teams"
          [items]="usersQuery.teams$ | async"
          [addNewButtonCaption]="allowAddTeam ? 'Create & associate new team' : ''"
          associateButtonCaption="Associate team"
          placeholder="Select teams to associate"
          (addNewClick)="addNewTeam()"
        ></name-list>

        <name-list
          *ngIf="!isTenantAdmin"
          class="field-row sites-container"
          title="Sites"
          subtitle="The sites that the user can see"
          formControlName="sites"
          [items]="tenantQuery.sites$ | async"
          associateButtonCaption="Associate site"
          placeholder="Select sites to associate"
        ></name-list>
      </dialog-tab>
    </dialog-tab-group>
  </form>

  <div class="row justify-end bottom-row">
    <button mat-button type="button" class="light-btn" (click)="close()">{{user ? 'Cancel' : 'Discard'}}</button>
    <button mat-flat-button type="button" *ngIf="!isLastTab" (click)="nextTab()" class="dark-btn">Next</button>
    <button mat-flat-button type="submit" *ngIf="isLastTab" [disabled]="!isFormValid" (click)="submit($event)" class="dark-btn" [class.limit-reached]="isAccessLevelLimitReached">
      {{user ? 'Save' : 'Create'}}
    </button>
  </div>
</dialog-layout>
