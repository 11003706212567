import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LimitReachedDialogData, LimitReachedPopupComponent } from './limit-reached-popup.component';

@Injectable({
  providedIn: 'root'
})
export class LimitReachedPopupService {
  constructor(private dialog: MatDialog) {}

  open(data: LimitReachedDialogData) {
    return this.dialog.open<LimitReachedPopupComponent, LimitReachedDialogData>(LimitReachedPopupComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '410px',
      backdropClass: 'limit-reached-dialog-backdrop',
      panelClass: 'limit-reached-dialog-panel',
      position: data.position,
      data
    });
  }
}
