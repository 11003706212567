import {
  DGNFeatureFillProperties,
  DGNFeatureFillType,
  DGN_FEATURE_STYLE_FLAGS,
  isSolidLine,
  isValidPatternSize
} from '../../state/detailed-site-designs/designs-dgn-utils';

export interface CanvasSize {
  w: number;
  h: number;
}

/**
 * @param y - y line value
 * @param k - line slope
 * @param b - line y-intercept
 */
const getStraightLineXValue = (y: number, k: number, b: number) => (y - b) / k;

const isRightAngle = (angle: number) => Math.abs(angle) >= Math.PI / 2 - 0.01 && Math.abs(angle) <= Math.PI / 2 + 0.01;

// round up if positive, round down if negative
const roundUp = (value: number) => (value > 0 ? Math.ceil(value) : Math.floor(value));

const leastCommonMultiple = (a: number, b: number): number => {
  if (a < 0 || b < 0 || a % 1 !== 0 || b % 1 !== 0) {
    return 0;
  }

  const gcd = greatestCommonDivisor(a, b);
  return (a * b) / gcd;
};

const greatestCommonDivisor = (a: number, b: number) => {
  while (a !== b) {
    const d = Math.abs(a - b);
    const min = Math.min(a, b);
    a = d;
    b = min;
  }
  return a;
};

function calculatePatternSize(angle: number, spacing: number, lineWidth: number): CanvasSize {
  let width = 50;
  let height = 50;

  if (isRightAngle(angle)) {
    /**
     * For vertical hatch: angle is 90 or -90 deg, || are lines with lineWidth property, horizontal distance between them - spacing
     * |-----------------|
     * |  ||    ||    || |
     * |  ||    ||    || |
     * |  ||    ||    || |
     * |-----------------|
     * one pattern is
     * |-------|
     * | ||    |
     * |-------|
     * thus, its width depends on line width and spacing, height could be any constant value
     */
    width = spacing + lineWidth;
  } else if (angle === 0) {
    /**
     * For horizontal hatch: angle is 0 deg, ==== are lines with lineWidth property, vertical distance between them - spacing
     * |-----------------|
     * |                 |
     * |=================|
     * |                 |
     * |=================|
     * |                 |
     * |-----------------|
     * one pattern is
     * |-------|
     * |=======|
     * |       |
     * |-------|
     * thus, its height depends on line width and spacing, width could be any constant value
     */
    height = spacing + lineWidth;
  } else {
    /**
     * For diagonal hatch: angle is custom value, \\ are lines with lineWidth property, spacing is perpendicular distance between lines
     * |-----------------|
     * |  \\    \\    \\ |
     * |   \\    \\    \\|
     * |    \\    \\    \|
     * |-----------------|
     * one pattern is
     * |------|
     * |\\    |
     * |------|
     * thus, width and height are calculated according to straight line equation y = kx + b:
     * width is the point of intersection of the line and the x-axis, height is the point of intersection of the line and the y-axis
     */
    // line slope
    const k = Math.tan(angle);
    // line y-intercept
    const b = (spacing + lineWidth) / Math.cos(angle);

    width = Math.abs(b / k);
    height = b;
  }
  return { w: Math.ceil(width), h: Math.ceil(height) };
}

function getPatternSize(fillProps: DGNFeatureFillProperties): CanvasSize {
  if (fillProps.fill_type_detected === DGNFeatureFillType.LINES) {
    return calculatePatternSize(fillProps.pattern_angle_rad, fillProps.pattern_spacing_px, fillProps.width);
  } else if (fillProps.fill_type_detected === DGNFeatureFillType.CROSS_LINES) {
    // calculate potential pattern sizes for both directions (lines and cross-lines)
    const patternSize = calculatePatternSize(fillProps.pattern_angle_rad, fillProps.pattern_spacing_px, fillProps.width);
    const crossPatternSize = calculatePatternSize(fillProps.cross_pattern_angle_rad, fillProps.cross_pattern_spacing_px, fillProps.width);
    // define the width and height as the least common multiple - so that the resulting pattern contains a whole number of stripes for all directions
    return {
      w: leastCommonMultiple(patternSize.w, crossPatternSize.w),
      h: leastCommonMultiple(patternSize.h, crossPatternSize.h)
    };
  }
}

function getPatternScale(patternSize: CanvasSize, canvasSize: CanvasSize) {
  if (!DGN_FEATURE_STYLE_FLAGS.scaleHatch) {
    return { x: 1, y: 1 };
  }
  // detect, how many whole patterns the tile canvas can contain in the x and y directions
  const xWholeNumber = Math.floor(canvasSize.w / patternSize.w);
  const yWholeNumber = Math.floor(canvasSize.h / patternSize.h);

  // detect what pattern width and heigh should be used so that the tile canvas contains whole patterns
  const updPatternWidth = canvasSize.w / xWholeNumber;
  const updPatternHeight = canvasSize.h / yWholeNumber;

  // return x and y directions scale ratio should be used to increase pattern in appropriate way
  return {
    x: updPatternWidth / patternSize.w,
    y: updPatternHeight / patternSize.h
  };
}

export function getHatchPattern(fillProps: DGNFeatureFillProperties, canvasSize: CanvasSize) {
  let scale = { x: 1, y: 1 };

  const patternCanvas = document.createElement('canvas');
  const patternCtx = patternCanvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;

  const calculatedPatternSize = getPatternSize(fillProps);

  if (
    !isValidPatternSize(calculatedPatternSize, canvasSize) ||
    (!isSolidLine(fillProps.pattern_style) && !DGN_FEATURE_STYLE_FLAGS.scaleDashedHatch)
  ) {
    // set pattern size equal to tile canvas size: a) if calculated pattern size is larger than canvas size; b) if no need to scale dashed hatch
    patternCanvas.width = canvasSize.w;
    patternCanvas.height = canvasSize.h;
  } else {
    scale = getPatternScale(calculatedPatternSize, canvasSize);
    const scaledPatternSize = {
      w: calculatedPatternSize.w * scale.x,
      h: calculatedPatternSize.h * scale.y
    };
    if (isValidPatternSize(scaledPatternSize, canvasSize)) {
      // scale pattern size
      patternCanvas.width = scaledPatternSize.w;
      patternCanvas.height = scaledPatternSize.h;
    } else {
      patternCanvas.width = calculatedPatternSize.w;
      patternCanvas.height = calculatedPatternSize.h;
    }
  }

  const withScaling = scale.x !== 1 || scale.y !== 1;

  if (fillProps.color) {
    patternCtx.fillStyle = fillProps.color;
    patternCtx.fillRect(0, 0, patternCanvas.width, patternCanvas.height);
  }

  if (fillProps.pattern_style && !isSolidLine(fillProps.pattern_style)) {
    patternCtx.setLineDash(fillProps.pattern_style);
  }
  patternCtx.lineWidth = fillProps.width;

  const linesData = [
    {
      angle: fillProps.pattern_angle_rad,
      spacing: fillProps.pattern_spacing_px
    }
  ];

  if (fillProps.fill_type_detected === DGNFeatureFillType.CROSS_LINES) {
    linesData.push({
      angle: fillProps.cross_pattern_angle_rad,
      spacing: fillProps.cross_pattern_spacing_px
    });
  }

  patternCtx.strokeStyle = fillProps.pattern_color;

  if (withScaling) {
    // scale pattern context
    patternCtx.scale(scale.x, scale.y);
  }

  /**
   * if pattern scaling is used: all lines positions should be detected base on original calculated pattern size, result will be scaled based on canvas context scaling;
   * otherwise, use resulted pattern canvas size for calculations
   */
  const widthForCalc = withScaling ? calculatedPatternSize.w : patternCanvas.width;
  const heightForCalc = withScaling ? calculatedPatternSize.h : patternCanvas.height;

  linesData.forEach(({ angle, spacing }) => {
    patternCtx.beginPath();

    if (isRightAngle(angle)) {
      // an interval of lines and x-axis intersections
      const dx = Math.ceil(Math.abs(spacing + fillProps.width));
      // how many times the hatch lines intersect X axis
      const countX = Math.ceil(widthForCalc / dx);
      let x = fillProps.width / 2;
      const y1 = 0;
      const y2 = heightForCalc;

      /**
       * for one vertical line:
       *     (x1, y1)
       * 0 --------------|
       * |      |        |
       * |      |        |
       * |      |        |
       * |---------------|
       *     (x2, y2)
       */
      for (let i = 0; i < countX; i++) {
        patternCtx.moveTo(x, y1);
        patternCtx.lineTo(x, y2);
        x += dx;
      }
    } else if (angle === 0) {
      // an interval of lines and y-axis intersections
      const dy = Math.ceil(Math.abs(spacing + fillProps.width));
      // how many times the hatch lines intersect Y axis
      const countY = Math.ceil(heightForCalc / dy);
      const x1 = 0;
      const x2 = widthForCalc;
      let y = fillProps.width / 2;

      /**
       * for one horizontal line:
       *          0 --------------|
       *          |               |
       * (x1, y1) |===============| (x2, y2)
       *          |               |
       *          |---------------|
       */
      for (let i = 0; i < countY; i++) {
        patternCtx.moveTo(x1, y);
        patternCtx.lineTo(x2, y);
        y += dy;
      }
    } else {
      const k = Math.tan(angle);
      const b = (spacing + fillProps.width) / Math.cos(angle);

      // an interval of lines and x-axis intersections
      const dx = Math.ceil(Math.abs((spacing + fillProps.width) / Math.sin(angle)));
      // an interval of lines and y-axis intersections
      const dy = Math.ceil(Math.abs(b));

      // how many times the hatch lines intersect X axis
      const countX = Math.ceil(widthForCalc / dx);
      // how many times the hatch lines intersect Y axis
      const countY = Math.ceil(heightForCalc / dy);
      // the total number of lines in the pattern
      const count = countX + countY + 1;

      const y1 = -dy;
      const y2 = heightForCalc + dy;

      const x1Base = getStraightLineXValue(y1, k, b);
      const x2Base = getStraightLineXValue(y2, k, b);

      let x1 = angle < 0 ? roundUp(x1Base - dx) : roundUp(x1Base - dx * countY);
      let x2 = angle < 0 ? roundUp(x2Base - dx) : roundUp(x2Base - dx * countY);

      /**
       * for one diagonal line:
       *     (x1, y1)
       * 0 --------------|
       * |        \      |
       * |         \     |
       * |          \    |
       * |---------------|
       *            (x2, y2)
       */
      for (let i = 0; i <= count; i++) {
        patternCtx.moveTo(x1, y1);
        patternCtx.lineTo(x2, y2);
        x1 += dx;
        x2 += dx;
      }
    }

    patternCtx.stroke();
  });

  // ADD PATTERN BORDERS
  // patternCtx.lineWidth = 2;
  // patternCtx.setLineDash([15, 15]);
  // patternCtx.strokeStyle = '#1D267D';
  // patternCtx.strokeRect(0, 0, patternCanvas.width, patternCanvas.height);

  return patternCanvas;
}
