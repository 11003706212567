// Partly taken from https://github.com/AbelHeinsbroek/chartjs-plugin-crosshair

import { Interaction } from 'chart.js';

export interface ChartInterpolatePluginOptions {
  enabled?: boolean;
}

export const ChartInterpolatePlugin = {
  id: 'interpolate',

  afterInit: function (chart, args, options: ChartInterpolatePluginOptions) {
    if (!chart.config.options.scales.x || !options.enabled) {
      return;
    }

    const xScaleType = chart.config.options.scales.x.type;

    if (xScaleType !== 'linear' && xScaleType !== 'time' && xScaleType !== 'category' && xScaleType !== 'logarithmic') {
      return;
    }

    chart.interpolatePlugin = {
      x: null
    };
  },

  afterEvent: function (chart, { event }, options: ChartInterpolatePluginOptions) {
    if (chart.config.options.scales.x.length == 0 || !options.enabled) {
      return;
    }

    const xScaleType = chart.config.options.scales.x.type;
    if (xScaleType !== 'linear' && xScaleType !== 'time' && xScaleType !== 'category' && xScaleType !== 'logarithmic') {
      return;
    }

    chart.interpolatePlugin.x = event.x;
  },

  afterDraw: function (chart, args, options: ChartInterpolatePluginOptions) {
    if (!options.enabled) {
      return;
    }

    this.interpolateValues(chart);
  },

  interpolateValues: function (chart) {
    for (let chartIndex = 0; chartIndex < chart.data.datasets.length; chartIndex++) {
      const dataset = chart.data.datasets[chartIndex];

      const meta = chart.getDatasetMeta(chartIndex);

      const xScale = chart.scales[meta.xAxisID];
      const xValue = xScale.getValueForPixel(chart.interpolatePlugin.x);

      if (meta.hidden || !dataset.interpolate) {
        continue;
      }

      const data = dataset.data;
      const index = data.findIndex(function (o) {
        return o.x >= xValue;
      });
      const prev = data[index - 1];
      const next = data[index];

      if (chart.data.datasets[chartIndex].steppedLine && prev) {
        dataset.interpolatedValue = prev.y;
      } else if (prev && next) {
        const slope = (next.y - prev.y) / (next.x - prev.x);
        dataset.interpolatedValue = prev.y + (xValue - prev.x) * slope;
      } else {
        dataset.interpolatedValue = NaN;
      }
    }
  }
};

export const Interpolate = function (chart, e, options) {
  const items = [];

  for (let datasetIndex = 0; datasetIndex < chart.data.datasets.length; datasetIndex++) {
    // check for interpolate setting
    if (!chart.data.datasets[datasetIndex].interpolate) {
      continue;
    }

    const meta = chart.getDatasetMeta(datasetIndex);
    // do not interpolate hidden charts
    if (meta.hidden) {
      continue;
    }

    const xScale = chart.scales[meta.xAxisID];
    const yScale = chart.scales[meta.yAxisID];

    const xValue = xScale.getValueForPixel(e.x);

    if (xValue > xScale.max || xValue < xScale.min) {
      continue;
    }

    const data = chart.data.datasets[datasetIndex].data;

    const index = data.findIndex(function (o) {
      return o.x >= xValue;
    });

    if (index === -1) {
      continue;
    }

    // linear interpolate value
    const prev = data[index - 1];
    const next = data[index];

    let interpolatedValue = NaN;
    if (prev && next) {
      const slope = (next.y - prev.y) / (next.x - prev.x);
      interpolatedValue = prev.y + (xValue - prev.x) * slope;
    }

    if (chart.data.datasets[datasetIndex].steppedLine && prev) {
      interpolatedValue = prev.y;
    }

    if (isNaN(interpolatedValue)) {
      continue;
    }

    const yPosition = yScale.getPixelForValue(interpolatedValue);

    // do not interpolate values outside of the axis limits
    if (isNaN(yPosition)) {
      continue;
    }

    // create a 'fake' event point

    const fakePoint = {
      hasValue: function () {
        return true;
      },
      tooltipPosition: function () {
        return this._model;
      },
      _model: { x: e.x, y: yPosition },
      skip: false,
      stop: false,
      x: xValue,
      y: interpolatedValue
    };

    items.push({ datasetIndex: datasetIndex, element: fakePoint, index: 0 });
  }

  // add other, not interpolated, items
  const xItems = Interaction.modes.x(chart, e, options);
  for (let index = 0; index < xItems.length; index++) {
    const item = xItems[index];
    if (!chart.data.datasets[item.datasetIndex].interpolate) {
      items.push(item);
    }
  }

  return items;
};

export default { Interpolate, ChartInterpolatePlugin };
