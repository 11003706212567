import { ResourceLinksModel } from '../../../generated/resourcelinks/model/resourceLinksModel';

export interface ResourceLink extends ResourceLinksModel {
  markedForSave?: boolean;
  markedForDelete?: boolean;
}

export import ResourceLinkType = ResourceLinksModel.ResourceTypeEnum;

export const RESOURCE_LINK_TYPE_NAMES = {
  [ResourceLinkType.ACTIVITY]: $localize`:@@detailedSite.resourceLinks.activity:Activity`,
  [ResourceLinkType.ANNOTATION]: $localize`:@@detailedSite.resourceLinks.annotation:Annotation`,
  [ResourceLinkType.TASK]: $localize`:@@detailedSite.resourceLinks.flight:Flight`,
  [ResourceLinkType.REPORT]: $localize`:@@detailedSite.resourceLinks.report:Report`,
  [ResourceLinkType.MEASUREMENT]: $localize`:@@detailedSite.resourceLinks.measurement:Measurement`,
  [ResourceLinkType.ANALYTIC]: $localize`:@@detailedSite.resourceLinks.analytic:Analytic`,
  [ResourceLinkType.MODELEDIT]: $localize`:@@detailedSite.resourceLinks.modelEdit:Model edit`,
  [ResourceLinkType.DESIGN]: $localize`:@@detailedSite.resourceLinks.design:Design`,
  [ResourceLinkType.ROADDESIGN]: $localize`:@@detailedSite.resourceLinks.roadDesign:Road Design`
};

export function generateResourceLinksEntityId(id: string, type: ResourceLinkType) {
  return `${id}_${type}`;
}

export interface ResourceLinkEntity extends ResourceLink {
  id: string;
  links: ResourceLink[];
  isDirty?: boolean;
}
