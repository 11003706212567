import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthQuery } from '../state/auth.query';
import { AuthService } from '../state/auth.service';

const SERVER_URLS = [
  environment.serverUrl,
  environment.lambdaGatewayUrl,
  ...(environment.serviceUrls ? Object.values(environment.serviceUrls) : [])
];
const URLS_TO_SKIP = [
  '/users/loginCredentials',
  '/users/current/login',
  '/users/current/refreshToken',
  '/users/forgotPassword',
  '/users/changePassword',
  'domainProviders',
  'authorize'
];

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject = new BehaviorSubject(null);

  constructor(private authQuery: AuthQuery, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip if request URL is not for datumate server or is in exclude list
    if (SERVER_URLS.every(urlName => !request.url.includes(urlName)) || URLS_TO_SKIP.some(urlName => request.url.includes(urlName))) {
      return next.handle(request);
    } else if (!this.authQuery.isActiveTokenExistsAndValid()) {
      return this.refreshAllTokensAndExecuteRequest(next, request);
    } else {
      return next.handle(this.addAuthenticationToken(request)).pipe(
        catchError(error => {
          if (URLS_TO_SKIP.some(urlName => request.url.includes(urlName))) {
            if (request.url.includes('/users/current/refreshToken')) {
              // In this case we want to logout user and to redirect it to login page
              this.authService.logout(true);
            }
            return throwError(() => error);
          }

          // If error status is different than 401 we want to skip refresh token
          // So we check that and throw the error if it's the case
          if (error.status !== 401) {
            return throwError(() => error);
          }

          return this.refreshAllTokensAndExecuteRequest(next, request);
        })
      );
    }
  }

  private refreshAllTokensAndExecuteRequest(next: HttpHandler, req: HttpRequest<any>) {
    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // which means the new token is ready and we can retry the request again
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => next.handle(this.addAuthenticationToken(req)))
      );
    } else {
      this.refreshTokenInProgress = true;
      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);

      return this.authService.refreshAllAccessTokens().pipe(
        switchMap(() => {
          // When the call to refreshToken completes we reset the refreshTokenInProgress to false
          // for the next time the token needs to be refreshed
          this.refreshTokenInProgress = false;
          const updatedActiveRefreshToken = this.authQuery.getActiveRefreshToken();
          this.refreshTokenSubject.next(updatedActiveRefreshToken);

          return next.handle(this.addAuthenticationToken(req));
        }),
        catchError(error => {
          this.refreshTokenInProgress = false;

          this.authService.logout(true);
          if (error.status === 401) {
            // Don't throw error if session expired
            console.error('Session expired. Logging off');
            return of(null);
          } else {
            return throwError(() => error);
          }
        })
      );
    }
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    const accessToken = this.authQuery.getActiveAccessToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }
    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        authorization: `Bearer ${accessToken}`
      }
    });
  }
}
