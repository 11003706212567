/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetImageResponse {
  altitude?: number;
  fisheye?: boolean;
  height?: number;
  id?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  rtkStatus?: GetImageResponse.RtkStatusEnum;
  size?: number;
  thumbnail?: string;
  url?: string;
  warnings?: Array<GetImageResponse.WarningsEnum>;
  width?: number;
  yawAngle?: number;
}
export namespace GetImageResponse {
  export type RtkStatusEnum = 'GOOD' | 'BAD' | 'NONE';
  export const RtkStatusEnum = {
    GOOD: 'GOOD' as RtkStatusEnum,
    BAD: 'BAD' as RtkStatusEnum,
    NONE: 'NONE' as RtkStatusEnum
  };
  export type WarningsEnum =
    | 'TIME_DIFFERENCE'
    | 'DIFFERENT_MODELS'
    | 'DIFFERENT_RESOLUTIONS'
    | 'INVALID_OVERLAP'
    | 'MISSING_COVERAGE'
    | 'NO_LOCATION'
    | 'HEIGHT_DIFFERENCE'
    | 'TOTAL_TIME_DIFFERENCE'
    | 'INVALID_RTK_DATA'
    | 'NO_MODEL';
  export const WarningsEnum = {
    TIMEDIFFERENCE: 'TIME_DIFFERENCE' as WarningsEnum,
    DIFFERENTMODELS: 'DIFFERENT_MODELS' as WarningsEnum,
    DIFFERENTRESOLUTIONS: 'DIFFERENT_RESOLUTIONS' as WarningsEnum,
    INVALIDOVERLAP: 'INVALID_OVERLAP' as WarningsEnum,
    MISSINGCOVERAGE: 'MISSING_COVERAGE' as WarningsEnum,
    NOLOCATION: 'NO_LOCATION' as WarningsEnum,
    HEIGHTDIFFERENCE: 'HEIGHT_DIFFERENCE' as WarningsEnum,
    TOTALTIMEDIFFERENCE: 'TOTAL_TIME_DIFFERENCE' as WarningsEnum,
    INVALIDRTKDATA: 'INVALID_RTK_DATA' as WarningsEnum,
    NOMODEL: 'NO_MODEL' as WarningsEnum
  };
}
