<div class="dialog-layout-container">
  <div class="side-line"></div>
  <div class="content">
    <div class="title-row" [class.small]="!header">
      <div class="header">
        <span [ngStyle]="{ 'font-weight': subHeaders ? '600' : 'normal' }">{{ header || '' }}</span>
        <div class="sub-header" *ngFor="let subHeader of subHeaders; index as i">
          {{ subHeader || '' }}
          <div class="vertical-divider" *ngIf="subHeaders.length > 1 && i !== subHeaders.length - 1"></div>
          <mat-icon *ngIf="isSubHeaderWarning" svgIcon="warning-colored-mark" class="warning-icon"></mat-icon>
        </div>
      </div>
      <button mat-icon-button class="close-button" title="Close" (click)="closeDialog.emit()" [disabled]="disableClose">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>
