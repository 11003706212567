import { FeatureCollection } from '@turf/turf';
import { Cartesian3 } from 'angular-cesium';
import { BaseSurface } from '../../../../generated/activity/model/baseSurface';
import { CreateMergeActivityRequest } from '../../../../generated/activity/model/createMergeActivityRequest';
import { CreateMergeActivityResponse } from '../../../../generated/activity/model/createMergeActivityResponse';
import { GetActivityDependencyResponse } from '../../../../generated/activity/model/getActivityDependencyResponse';
import { GetActivityResponse } from '../../../../generated/activity/model/getActivityResponse';
import { GetMeasurementResponse } from '../../../../generated/activity/model/getMeasurementResponse';
import { GetProjectPlanResponse } from '../../../../generated/activity/model/getProjectPlanResponse';
import { PolygonPolylineEditorObservable } from '../../../shared/utils/cesium-common';

export type ProjectPlan = GetProjectPlanResponse;

export import ActivityType = GetActivityResponse.ActivityTypeEnum;
export import ActivityDependencyType = GetActivityDependencyResponse.DependencyTypeEnum;

export import ActivityMeasurementType = GetMeasurementResponse.MeasurementTypeEnum;
export import ActivityMeasurementSourceType = GetMeasurementResponse.SourceTypeEnum;
export import ActivityMeasurementModelSourceType = GetMeasurementResponse.SourceModelTypeEnum;
export import ActivityMeasurementVolumeType = GetMeasurementResponse.VolumeValueTypeEnum;
export import ActivityMeasurementTerrainModelType = GetMeasurementResponse.TerrainModelTypeEnum;
export import ActivityMeasurementBaseSurfaceType = BaseSurface.TypeEnum;

export import ActivityMeasurementBaseSurface = BaseSurface;

export import ActivityMergeConflict = CreateMergeActivityResponse;
export import ActivityMergeChangeType = CreateMergeActivityResponse.ChangesEnum;
export import ActivityMergeResultType = CreateMergeActivityRequest.MergeTypeEnum;

export interface Activity extends GetActivityResponse {
  type: ActivityEntityType;
  readonly actualStartDate?: Date;
  readonly actualEndDate?: Date;
  pinned?: boolean;
  parentNames?: string[];
  inDrawingMode?: boolean;
  inEditMode?: boolean;
}

export type ActivityEntityType = 'ACTIVITY';
export const ActivityEntityType = {
  ACTIVITY: 'ACTIVITY' as ActivityEntityType
};

export interface ActivityMeasurement extends GetMeasurementResponse {
  isTemp?: boolean; // true for new measurements until create completion (click on 'Done' / 'Cancel')
  isNew?: boolean; // true for new measurements until saved in DB (has temporary id with NEW_ACTIVITY_MEASUREMENT_ID_PREFIX)
  markedForSave?: boolean; // true when editing new or existing measurements until saved in DB
  markedForDelete?: boolean; // true for deleted measurements until updated in DB (hidden for calculations and visualization)
  isEnabledEditor?: boolean;
  geoJson?: FeatureCollection;
  positions?: Cartesian3[];
  date?: Date;
}

export interface ActivityMeasurementEditor {
  measurementId: string;
  editorId: string;
  editor$: PolygonPolylineEditorObservable;
}

export interface ProjectPlanUploadingState {
  totalSize: number;
  uploadedSize: number;
}

export enum ActivitySortingType {
  NAME = 'Name',
  ID = 'ID',
  PLANNED_START_DATE = 'Planned start date',
  PLANNED_END_DATE = 'Planned end date'
}

export type ActivityGeometricFilterType = 'GEOMETRIC' | 'NON_GEOMETRIC';
export const ActivityGeometricFilterType = {
  GEOMETRIC: 'GEOMETRIC' as ActivityGeometricFilterType,
  NON_GEOMETRIC: 'NON_GEOMETRIC' as ActivityGeometricFilterType
};
export type ActivityFinishingFilterType = 'UNFINISHED';
export const ActivityFinishingFilterType = {
  UNFINISHED: 'UNFINISHED' as ActivityFinishingFilterType
};

export type ActivityFilterType = ActivityGeometricFilterType | ActivityFinishingFilterType;
export const ActivityFilterType = { ...ActivityGeometricFilterType, ...ActivityFinishingFilterType };

export const ACTIVITY_FILTERING_STORAGE_KEY = 'ACTIVITY_FILTERING';

export interface ActivitySorting {
  type: ActivitySortingType;
  isDesc: boolean;
}

export interface ActivityFiltering {
  geometricFilter: ActivityGeometricFilterType;
  finishingFilter: ActivityFinishingFilterType;
}

export interface ActivityMeasurementValues {
  values: { [key: string]: number };
}

export interface DatesDiffWithUnits {
  value: number;
  units: DateDiffUnits;
}

export enum DateDiffUnits {
  DAY = 'd',
  MONTH = 'm',
  YEAR = 'y'
}
