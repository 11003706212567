import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

// Approximate tablet width and height in pixels, that below that we assume the window is in tablet size
export const MINIMAL_TABLET_WIDTH = 1400;
export const MINIMAL_TABLET_HEIGHT = 800;

interface DeviceData {
  isTablet: boolean;
  isDesktop: boolean;
  deviceType: string;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private deviceData: DeviceData;

  constructor(private deviceDetector: DeviceDetectorService) {
    // Currently we treat mobile as tablet
    let isTablet = this.deviceDetector.isTablet() || this.deviceDetector.isMobile();

    // Fix issue in ngx-device-detector not detecting new iPad versions
    if (!isTablet && !!navigator.platform && ['MacIntel', 'iPad'].includes(navigator.platform) && navigator.maxTouchPoints > 1) {
      isTablet = true;
    }

    this.deviceData = {
      isTablet,
      isDesktop: this.deviceDetector.isDesktop(),
      deviceType: this.deviceDetector.deviceType
    };
  }

  isTablet() {
    return this.deviceData.isTablet;
  }

  isDesktop() {
    return this.deviceData.isDesktop;
  }

  getDeviceType() {
    return this.deviceData.deviceType;
  }
}
