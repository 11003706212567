import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { DialogTabComponent } from './dialog-tab/dialog-tab.component';

@Component({
  selector: 'dialog-tab-group',
  template: `
    <div class="row full-height container">
      <div class="column full-height tabs-container">
        <div
          class="row space-between tab-item"
          [class.active]="tab.active"
          [class.disabled]="disabled || tab.disabled"
          *ngFor="let tab of tabs; let i = index"
          tabindex
          (click)="selectTab(i)"
        >
          <span class="tab-caption"> {{ tab.caption }}</span>
          <mat-icon class="error-icon" *ngIf="tab.error">error</mat-icon>
        </div>
      </div>

      <div class="column full-height tab-content-container">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./dialog-tab-group.component.scss']
})
export class DialogTabGroupComponent implements OnInit, AfterContentInit {
  @ContentChildren(DialogTabComponent) tabs: QueryList<DialogTabComponent>;
  @Input() disabled = false;

  private activeIndex = -1;
  @Input() set activeTabIndex(index: number) {
    this.selectTab(index);
  }
  get activeTabIndex() {
    return this.activeIndex;
  }
  @Output() activeTabIndexChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    const tabs = this.tabs.toArray();
    if (this.activeTabIndex !== -1) {
      tabs.forEach((tab, i) => (tab.active = i === this.activeTabIndex));
    } else {
      // If there is no active tab set, activate the first enabled tab
      const firstActiveTabIndex = tabs.findIndex(tab => tab.active);
      if (firstActiveTabIndex === -1) {
        const firstEnabledTabIndex = tabs.findIndex(tab => !tab.disabled);
        if (firstEnabledTabIndex !== -1) {
          tabs[firstEnabledTabIndex].active = true;
          this.activeIndex = firstEnabledTabIndex;
        }
      } else {
        this.activeIndex = firstActiveTabIndex;
      }
    }
  }

  selectTab(tabIndex: number) {
    // Wait for initialization
    if (!this.tabs) {
      return;
    }

    const tabs = this.tabs.toArray();
    const tab = tabs[tabIndex];
    if (this.disabled || !tab || tab.disabled) {
      return;
    }

    // Deactivate all tabs
    tabs.forEach(t => (t.active = false));

    // Activate the tab the user has clicked on.
    tab.active = true;
    this.activeIndex = tabIndex;
    this.activeTabIndexChange.emit(tabIndex);
  }
}
