import { Component, Input, OnInit } from '@angular/core';

export type IssueBannerType = 'info' | 'warning' | 'error';

@Component({
  selector: 'issue-banner',
  template: `
    <div class="row balloon" [class]="type">
      <mat-icon class="issue-icon" [svgIcon]="type"></mat-icon>
      <div class="details">
        <span class="issue-type" *ngIf="type !== 'info'">
          {{ issueTypeName }}<span class="superscript" *ngIf="count"> ({{ count }}) </span>:
        </span>
        <span [innerHTML]="caption"></span>
      </div>
    </div>
  `,
  styleUrls: ['./issue-banner.component.scss']
})
export class IssueBannerComponent implements OnInit {
  @Input() type: IssueBannerType = 'error';
  @Input() caption: string;
  @Input() count: number;

  constructor() {}

  ngOnInit() {}

  get issueTypeName() {
    switch (this.type) {
      case 'error':
        return $localize`:@@general.error:Error`;
      case 'warning':
        return $localize`:@@general.warning:Warning`;
      case 'info':
        return $localize`:@@general.info:Info`;
    }
  }
}
