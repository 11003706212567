<div class="row actions-row buttons-container">
  <!--   TODO: Hidden until implemented -->
  <button mat-icon-button [disabled]="loadingImage" title="Rotate image to north" (click)="north()" class="hidden">
    <mat-icon svgIcon="north"></mat-icon>
  </button>

  <button mat-icon-button [disabled]="loadingImage" title="Zoom out" (click)="zoomOut()">
    <mat-icon svgIcon="zoom-out"></mat-icon>
  </button>
  <button mat-icon-button [disabled]="loadingImage" title="Zoom in" (click)="zoomIn()">
    <mat-icon svgIcon="zoom-in"></mat-icon>
  </button>
  <button mat-icon-button [disabled]="loadingImage" title="One to one aspect ratio" (click)="oneToOne()">
    <mat-icon svgIcon="one-to-one-image"></mat-icon>
  </button>
  <button mat-icon-button [disabled]="loadingImage" title="Fit image to container" (click)="fit()">
    <mat-icon svgIcon="fit-image"></mat-icon>
  </button>
  <button mat-icon-button [disabled]="loadingImage" title="Full screen" (click)="fullScreen()">
    <mat-icon svgIcon="fullscreen-image"></mat-icon>
  </button>
  <button mat-icon-button [disableByAccess]="PERMISSIONS.downloads" [disableByAccessExtraConditions]="loadingImage"
          title="Download image" (click)="download()">
    <mat-icon svgIcon="download-full"></mat-icon>
  </button>
</div>
<div class="image-container-wrapper">
  <div class="image-container">
    <img class="preview-image" #previewImage [src]="image" alt=""/>
  </div>
</div>
