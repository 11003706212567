import { Injectable } from '@angular/core';
import { Order, Query, QueryConfig, QueryEntity } from '@datorama/akita';
import { UploadWizardQuery } from '../../state/upload-wizard.query';
import { GCPItem, GCPType, GCPsOffset, Image, MIN_IMAGE_MARKS, MIN_IMAGE_MARKS_LOCAL_CS } from './gcp.model';
import { GcpState, GcpStore } from './gcp.store';

export function isGCPInvalidEstimation(gcp: GCPItem) {
  return (
    ('estimatedX' in gcp && gcp.estimatedX === 0) ||
    ('estimatedY' in gcp && gcp.estimatedY === 0) ||
    ('estimatedZ' in gcp && gcp.estimatedZ === 0)
  );
}

export function calcGCPEstimationResidualsAndDiff(gcp: GCPItem) {
  const invalidEstimation = isGCPInvalidEstimation(gcp);
  const res = invalidEstimation ? [] : [gcp.x - gcp.estimatedX, gcp.y - gcp.estimatedY, gcp.z - gcp.estimatedZ];
  const diff = invalidEstimation ? null : Math.sqrt(res.map(n => n ** 2).reduce((sum, n) => sum + n));

  return { res, diff };
}

@QueryConfig({
  sortBy: 'name',
  sortByOrder: Order.ASC
})
@Injectable({ providedIn: 'root' })
export class GcpQuery extends Query<GcpState> {
  gcpItemsQuery = new QueryEntity(this.store.gcps);
  gcp$ = this.gcpItemsQuery.selectAll();

  imagesQuery = new QueryEntity(this.store.images);
  images$ = this.imagesQuery.selectAll();
  imagesMapping$ = this.imagesQuery.select(state => state.entities);

  processState$ = this.select(state => state.ui.processState);
  loadingModalType$ = this.select(state => state.ui.loadingModalType);

  showImages$ = this.select(state => state.ui.gcpMap.showImages);
  showGcpName$ = this.select(state => state.ui.gcpMap.showGcpName);
  showImageName$ = this.select(state => state.ui.gcpMap.showImageName);
  showGcpWithMarking$ = this.select(state => state.ui.gcpMap.showGcpWithMarking);
  showGcpWithoutMarking$ = this.select(state => state.ui.gcpMap.showGcpWithoutMarking);
  showCheckpointsWithMarking$ = this.select(state => state.ui.gcpMap.showCheckpointsWithMarking);
  showCheckpointsWithoutMarking$ = this.select(state => state.ui.gcpMap.showCheckpointsWithoutMarking);
  showHintCount$ = this.select(state => state.ui.gcpMap.showHintCount);
  mapOrthoTaskId$ = this.select(state => state.ui.mapOrthoTaskId);
  isGCPsDirty$ = this.select(state => state.isGCPsDirty);
  processCounters$ = this.select(['checkAccuracyCounter', 'autoImproveCounter']);
  isGCPsImagesOverlapping$ = this.select(state => state.isGCPsImagesOverlapping);

  gcpsMovingSettings$ = this.select(state => state.ui.gcpsMovingSettings);

  constructor(protected store: GcpStore, private wizardQuery: UploadWizardQuery) {
    super(store);
  }

  isEmpty() {
    return this.gcpItemsQuery.getCount() === 0;
  }

  isLoading() {
    return this.getValue().loading;
  }

  getIsLocalCS() {
    return this.wizardQuery.getIsLocalCS();
  }

  getMinImageMarks() {
    return this.getIsLocalCS() ? MIN_IMAGE_MARKS_LOCAL_CS : MIN_IMAGE_MARKS;
  }

  private isGCPMarked = (item: GCPItem) => {
    const minImageMarks = this.getMinImageMarks();
    return item?.marks && Object.keys(item.marks).length >= minImageMarks;
  };

  getIsGCPMarked(id: string) {
    const gcp = this.getGCP(id);
    return this.isGCPMarked(gcp);
  }

  private isNotCheckpointGCPMarked = (item: GCPItem) => {
    return item.type !== GCPType.CHECKPOINT && this.isGCPMarked(item);
  };

  get markedGCPs$() {
    return this.gcpItemsQuery.selectAll({ filterBy: this.isGCPMarked });
  }

  get unmarkedGCPs$() {
    return this.gcpItemsQuery.selectAll({ filterBy: item => !this.isGCPMarked(item) });
  }

  get currentMarkedGCPs$() {
    return this.getIsLocalCS() ? this.gcpItemsQuery.selectAll({ filterBy: this.isNotCheckpointGCPMarked }) : this.markedGCPs$;
  }

  getGCP(id: string) {
    return this.gcpItemsQuery.getEntity(id);
  }

  getAllGCPs() {
    return this.gcpItemsQuery.getAll();
  }

  getGCPsLonLat() {
    return this.getAllGCPs().map(gcp => ({ longitude: gcp.longitude, latitude: gcp.latitude }));
  }
  getGCPsLonLatOri() {
    return this.getAllGCPs().map(gcp => ({ longitude: gcp.longitudeOri, latitude: gcp.latitudeOri }));
  }

  getGCPsOffset(): GCPsOffset {
    return this.getValue().gcpsOffset;
  }

  getProcessState() {
    return this.getValue().ui.processState;
  }

  getAllImages(filterBy?: (image: Image) => boolean) {
    return this.imagesQuery.getAll({ filterBy });
  }

  getImage(imageId: string) {
    return this.imagesQuery.getEntity(imageId);
  }

  getImagesLonLat() {
    return this.getAllImages().map(image => ({ longitude: image.longitude, latitude: image.latitude }));
  }

  getImageHint(gcpId: string, imageId: string) {
    const hints = this.getGCP(gcpId).hints;
    return imageId ? hints[imageId] : null;
  }

  getImageMark(gcpId: string, imageId: string) {
    const marks = this.getGCP(gcpId).marks;
    return imageId ? marks[imageId] : null;
  }

  getGcpMapOptions() {
    return this.getValue().ui.gcpMap;
  }

  getMapOrthoTaskId() {
    return this.getValue().ui.mapOrthoTaskId;
  }
}
