<div class="left">
  <datu-loader *ngIf="loading"></datu-loader>

  <div class="login-wrapper" [class.hidden]="loading">
    <img src="assets/logo-color.png" alt="logo" class="left-logo" *ngIf="isActiveViewState(ViewType.LOGIN)"/>
    <div class="row full-container form-header">
      <button mat-icon-button class="back-btn" (click)="stepBack()" *ngIf="showBackButton">
        <mat-icon>arrow_backward</mat-icon>
      </button>
      <div class="form-title" [ngClass]="{ email: isActiveViewState(ViewType.ENTER_PASS) }">
        {{ isActiveViewState(ViewType.ENTER_PASS) ? usernamePlaceholder : FormTitles[currentView] }}
      </div>
    </div>

    <div class="login-form" [class.hidden]="isActiveViewState(ViewType.SET_NEW_PASS) || isActiveViewState(ViewType.ACCESS_DENIED)" [class.fixed-height]="allowPasswordLogin">
      <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()" class="form">
        <div class="column full-container" [ngClass]="{'out-of-view': !isActiveViewState(ViewType.LOGIN) && !isActiveViewState(ViewType.FORGOT_PASS)}">
          <label class="input-label" i18n="@@auth.loginPage.emailLabel">Email</label>
          <input #emailInputEl type="text" class="input-control" name="username" formControlName="email" (keydown.enter)="submitUserEmail()" autocomplete="username" autofocus />

          <button mat-flat-button class="submit-btn" type="button" (click)="submitUserEmail()">
            <ng-container *ngIf="!isActiveViewState(ViewType.FORGOT_PASS); else resetPasswordCaption" i18n="@@general.actionButtons.continue">Continue</ng-container>
            <ng-template #resetPasswordCaption i18n="@@auth.loginPage.resetPassword">Reset password</ng-template>
          </button>
        </div>

        <div [ngClass]="{'out-of-view': !isActiveViewState(ViewType.ENTER_PASS)}">

          <ng-template [ngIf]="ssoProviders.length < 4" [ngIfElse]="ssoSelect">
            <div class="column full-container sso-btn-wrap" *ngIf="ssoProviders.length">
              <button class="sso-btn" type="button" *ngFor="let provider of ssoProviders" (click)="openSSOUrl(provider)">
                <img class="sso-btn-logo" [srcset]="provider.logo" width="24px" /> <ng-container i18n="@@auth.loginPage.signInWithProvider">Sign in with {{ provider.name }}</ng-container>
              </button>
            </div>
          </ng-template>

          <ng-template #ssoSelect>
            <div class="row full-container">
              <label class="input-label" i18n="@@auth.loginPage.signInWith">Sign in with</label>
            </div>
            <div class="row full-container">
              <div class="column full-container">
                <mat-select class="field-select-control sso-select" [(value)]="selectedProvider" i18n-placeholder="@@auth.loginPage.selectProviderFromList" placeholder="Select form list">
                  <mat-select-trigger>
                    <ng-container *ngIf="selectedProvider">
                      <img [srcset]="selectedProvider.logo" width="24px" /> {{ selectedProvider.name }}
                    </ng-container>
                  </mat-select-trigger>
                  <mat-option *ngFor="let provider of ssoProviders" [value]="provider" class="sso-select-item">
                    <img class="sso-logo-select" [srcset]="provider.logo" width="24px" /> {{ provider.name }}
                  </mat-option>
                </mat-select>
              </div>
              <div class="column">
                <button mat-flat-button class="sso-submit-btn" type="button" (click)="openSSOUrl(selectedProvider)">
                  <mat-icon>arrow_forward</mat-icon>
                </button>
              </div>
            </div>
          </ng-template>

          <issue-banner *ngIf="bannerMessage.show && bannerMessage.position === BannerPositions.SSO" [type]="bannerMessage.type" [caption]="bannerMessage.caption"></issue-banner>

          <div class="row full-container line-divider" *ngIf="(ssoProviders | isDefined) && allowPasswordLogin">
            <div class="horizontal-line"></div>
            <div class="spacer-text" i18n="@@auth.loginPage.or">OR</div>
            <div class="horizontal-line"></div>
          </div>

          <ng-template [ngIf]="!isNewPassword" [ngIfElse]="setNewPassBtn">
            <div [class.hidden]="!allowPasswordLogin">
              <div class="column full-container password-input-wrap">
                <label class="input-label" i18n="@@auth.loginPage.enterPasswordLabel">Enter password</label>
                <input #passwordInputEl type="password" class="input-control" name="password" formControlName="password" autocomplete="current-password"/>
                <mat-icon class="password-visibility" (click)="setPasswordVisibility(!isPasswordVisible)">
                  {{passwordInputEl.type === 'text' ?  'visibility_off' : 'visibility'}}
                </mat-icon>
              </div>

              <button mat-flat-button #loginButton class="submit-btn" type="submit" [disabled]="loginForm.invalid" i18n="@@auth.loginPage.signIn">Sign in</button>

              <div class="forgot-btn" (click)="showForgotPassword()" i18n="@@auth.loginPage.forgotPasswordButton">Forgot password?</div>
            </div>
          </ng-template>

          <ng-template #setNewPassBtn>
            <button mat-flat-button class="submit-btn m-0" type="button" (click)="moveToSetNewPassword()" i18n="@@auth.loginPage.setNewPasswordButton">Set new password</button>
          </ng-template>
        </div>

        <issue-banner *ngIf="bannerMessage.show && bannerMessage.position === BannerPositions.FORM_BOTTOM" [type]="bannerMessage.type" [caption]="bannerMessage.caption"></issue-banner>
      </form>

    </div>

    <div class="set-new-password" [class.hidden]="!isActiveViewState(ViewType.SET_NEW_PASS)">
      <form [formGroup]="changePasswordForm" (ngSubmit)="changePasswordSubmit()" class="form">
        <img src="assets/logo-color.png" alt="logo" class="left-logo" />
        <div class="password-policy">
          <ng-container i18n="@@auth.loginPage.passwordMustMeetFollowingRequirements">Your password must meet the following requirements:</ng-container>
          <ul>
            <li i18n="@@auth.loginPage.passwordMustMeetMinimumCharacters">It should be a minimum of 8 characters long.</li>
            <li i18n="@@auth.loginPage.passwordMustMeetMixCharacters">It should include a mix of upper case letters, lower case letters, and numbers.</li>
            <li i18n="@@auth.loginPage.passwordMustMeetUseSpecialCharacters">You may also use the following special characters: @$!%*#?& to further strengthen your password.</li>
          </ul>
          <ng-container i18n="@@auth.loginPage.passwordGuidelinesDescription">By following these guidelines, you can help ensure the safety and security of your account. It is also recommended that you avoid
          using easily guessable passwords, such as common words or personal information.
          </ng-container>

        </div>

        <label class="input-label" for="newPassword" i18n="@@auth.loginPage.newPasswordLabel">New password</label>
        <input type="password" name="newPassword" formControlName="newPassword" class="input-control" autocomplete="new-password" autofocus />
        <div class="errors-container">
          <field-error *ngIf="hasForgotPasswordError('passPolicy')" i18n-text="@@auth.loginPage.InvalidPassword" text="Invalid password" class="row"></field-error>
        </div>

        <label class="input-label" for="verifyPassword" i18n="@@auth.loginPage.verifyNewPasswordLabel">Verify new password</label>
        <input type="password" name="verifyPassword" formControlName="verifyPassword" class="input-control" autocomplete="new-password" />
        <div class="errors-container">
          <field-error *ngIf="hasForgotPasswordError('notSame')" i18n-text="@@auth.loginPage.PasswordsMustBeIdentical" text="Both passwords must be identical" class="row"></field-error>
        </div>

        <button mat-flat-button class="submit-btn" type="submit" [disabled]="!changePasswordForm.valid" i18n="@@general.actionButtons.submit">Submit</button>
        <issue-banner *ngIf="bannerMessage.show && bannerMessage.position === BannerPositions.FORM_BOTTOM" [type]="bannerMessage.type" [caption]="bannerMessage.caption"></issue-banner>
      </form>
    </div>

    <div class="access-denied" [class.hidden]="!isActiveViewState(ViewType.ACCESS_DENIED)">
        <div class="access-denied-content column">
          <div i18n="@@auth.loginPage.accessDeniedDescriptionLicenseInUse">Your {{appName}} license is currently in use under this email address.</div>
          <div i18n="@@auth.loginPage.accessDeniedDescriptionSecurityAndLicensingReasons">For security and licensing reasons, simultaneous logins are not permitted.</div>
          <div i18n="@@auth.loginPage.accessDeniedDescriptionEnsureLoggedOut">Please ensure that any other sessions are logged out before attempting to access the system again.</div>
          <div i18n="@@auth.loginPage.accessDeniedDescriptionForAssistance" [style.margin-top.px]="16">For assistance, contact support.</div>
        </div>
         <button mat-flat-button class="dark-btn" type="button" (click)="contactUs()" i18n="@@auth.loginPage.contactUs">Contact us</button>
    </div>
  </div>

  <a class="row powered-by" *ngIf="poweredByURL" target="_blank" rel="noopener noreferrer" [href]="poweredByURL">
    <ng-container i18n="@@auth.loginPage.poweredBy">Powered by</ng-container>
    <img src="assets/powered-by-logo-color.png" />
  </a>
</div>

<div class="right">
  <img src="assets/logo-color.png" alt="logo" class="logo" />
  <div class="slogan">Build with intelligence</div>
</div>
