import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import { formatName, getAvatar } from '../../utils/formatting';
import { Team, User } from './users-and-teams.model';

export interface UsersState extends EntityState<User> {}

export interface TeamsState extends EntityState<Team> {}

export interface UsersAndTeamsState {}

export function createInitialState(): UsersAndTeamsState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'users-and-teams' })
export class UsersAndTeamsStore extends Store<UsersAndTeamsState> {
  users = new EntityStore<UsersState>({}, { name: 'users' });
  teams = new EntityStore<TeamsState>({}, { name: 'teams' });

  constructor() {
    super(createInitialState());
  }

  addUsers(users: User[]) {
    this.users.set(users.map(this.toLocalUser));
  }

  private toLocalUser(user: User) {
    const name = formatName(user);
    const avatarUrl = getAvatar(name);
    return {
      ...user,
      name,
      avatarUrl
    };
  }

  addTeams(teams: Team[]) {
    this.teams.set(teams.map(team => ({ ...team, users: team.users.map(this.toLocalUser) })));
  }

  addUser(newUser: User) {
    const user = this.toLocalUser(newUser);
    this.users.add(user);

    if (user.teams?.length > 0) {
      const teamIds = user.teams.map(team => team.id);
      this.teams.update(teamIds, team => ({ ...team, users: [...team.users, user] }));
    }
  }

  updateUser(newUser: User) {
    const user = this.toLocalUser(newUser);
    this.users.update(user.id, user);

    // Update teams with new user data
    const teamsToUpdate = user.teams.map(team => team.id);
    this.teams.update(null, team => {
      let users: User[];
      if (teamsToUpdate.includes(team.id)) {
        if (team.users.find(u => u.id === user.id)) {
          // Update user in team
          users = team.users.map(u => (u.id === user.id ? user : u));
        } else {
          // Add user to team
          users = [...team.users, user];
        }
      } else {
        // Remove user from team
        users = team.users.filter(u => u.id !== user.id);
      }

      return {
        ...team,
        users
      };
    });
  }

  deleteUser(userId: string) {
    this.users.remove(userId);

    this.teams.update(null, (team: Team) => ({
      ...team,
      users: team.users?.filter(user => user.id !== userId)
    }));
  }

  addTeam(team: Team) {
    this.teams.add(team);

    if (team.users?.length > 0) {
      const userIds = team.users.map(user => user.id);
      this.users.update(userIds, user => ({ ...user, teams: [...user.teams, team] }));
    }
  }

  updateTeam(team: Team) {
    this.teams.update(team.id, team);

    // Update users with new team data
    const usersToUpdate = team.users.map(user => user.id);
    this.users.update(null, user => {
      let teams: Team[];
      if (usersToUpdate.includes(user.id)) {
        if (user.teams.find(t => t.id === team.id)) {
          // Update team in user
          teams = user.teams.map(t => (t.id === team.id ? team : t));
        } else {
          // Add team to user
          teams = [...user.teams, team];
        }
      } else {
        // Remove team from user
        teams = user.teams.filter(t => t.id !== team.id);
      }

      return {
        ...user,
        teams
      };
    });
  }

  deleteTeam(teamId: string) {
    this.teams.remove(teamId);

    this.users.update(null, (user: User) => ({
      ...user,
      teams: user.teams?.filter(team => team.id !== teamId)
    }));
  }
}
