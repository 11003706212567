/** This is a type file for @mapbox/vector-tile that doesn't have internal type information
 * {@link https://github.com/mapbox/vector-tile-js}
 * */

import { Feature } from '@turf/turf';

export interface VectorTile {
  /** an object containing parsed layers in the form of {<name>: <layer>, ...}, where each layer is a {@link VectorTileLayer} object */
  layers: Record<string, VectorTileLayer>;
}

export interface VectorTileLayer {
  /** Default 1 */
  version: number;
  /** layer name */
  name: string;
  /** tile extent size */
  extent: number; // Default 4096
  /** number of features in the layer */
  length: number;
  feature: (index: number) => VectorTileFeature;
}

export interface VectorTileFeature {
  /** type of the feature, see {@link FeatureType} */
  type: FeatureType;
  /** feature extent size */
  extent: number;
  /** feature identifier, if present */
  id: number;
  /** object literal with feature properties */
  properties: TileFeatureProperties;
  /** parses feature geometry and returns an array of Point arrays (with each point having x and y properties) */
  loadGeometry: () => VTPoint[][];
  /** calculates and returns the bounding box of the feature in the form [x1, y1, x2, y2] */
  bbox: () => [number, number, number, number];
  /** returns a GeoJSON representation of the feature. (x, y, and z refer to the containing tile's index.) */
  toGeoJSON: (x: number, y: number, z: number) => Feature;
}

export enum FeatureType {
  UNKNOWN = 0,
  POINT = 1,
  LINE = 2,
  POLYGON = 3
}

export interface VTPoint {
  x: number;
  y: number;
}

interface TileFeatureProperties {
  OGR_STYLE?: string;
  feature_id?: string;
  Layer?: string;
  style?: string;
}
