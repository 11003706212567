/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DesignErrorModel } from './designErrorModel';

export interface GetDesignResponse {
  categoryId?: string;
  cesium3DReady?: boolean;
  creationTime?: Date;
  errors?: Array<DesignErrorModel>;
  fileName?: string;
  hasBorder?: boolean;
  hasSurface?: boolean;
  id?: string;
  ifcTreeFileUrl?: string;
  jsonReady?: boolean;
  name?: string;
  siteId?: string;
  state?: GetDesignResponse.StateEnum;
  terrainReady?: boolean;
  url?: string;
}
export namespace GetDesignResponse {
  export type StateEnum = 'UPLOADING' | 'PROCESSING' | 'READY' | 'FAILED' | 'VALIDATION_FAILED';
  export const StateEnum = {
    UPLOADING: 'UPLOADING' as StateEnum,
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum,
    VALIDATIONFAILED: 'VALIDATION_FAILED' as StateEnum
  };
}
