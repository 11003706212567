import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginResponse } from '../../../generated/ums/model/loginResponse';

export const jwtHelper = new JwtHelperService();

export const REQUIRED_ACCESS_LEVEL_HEADER = 'X-Required-Access-Level';

export import AccessLevelEnum = LoginResponse.AccessLevelEnum;

export const DEFAULT_LOGIN_ERROR_MESSAGE = $localize`:@@auth.state.errorLoggingIn:Error logging in`;

// Currently USERNOTACTIVE and INVALIDCREDENTIALS are not used in BE
export import LogInStateEnum = LoginResponse.LogInStateEnum;
const TENANT_STATE_ERROR_MESSAGE_TEMPLATES: { [key in LogInStateEnum]?: (tenantName?: string) => string } = {
  [LogInStateEnum.INVALIDIPRANGE]: (tenantName: string) =>
    `Unauthorized IP, access is denied. Please contact your IT administrator for ${tenantName}`,
  [LogInStateEnum.EXPIREDPACKAGE]: (tenantName: string) =>
    `Account subscription has reached its expiry date for ${tenantName}. Please contact our sales department to renew your subscription`
};

export function getTenantStateErrorMessage(logInState: LogInStateEnum, tenantName: string) {
  const loginStateTemplate = TENANT_STATE_ERROR_MESSAGE_TEMPLATES[logInState];
  return loginStateTemplate?.(tenantName) ?? DEFAULT_LOGIN_ERROR_MESSAGE;
}

export const ACCOUNT_USER_ACCESS_LEVELS = [
  AccessLevelEnum.VIEWONLY,
  AccessLevelEnum.BASICUSER,
  AccessLevelEnum.ANALYTICSUSER,
  AccessLevelEnum.TENANTADMIN
];

export const OPERATOR_USER_ACCESS_LEVELS = [AccessLevelEnum.OPERATORUSER];

export enum UserRole {
  OPERATOR = 'operator',
  ACCOUNT_USER = 'accountUser'
}

export const USER_ROLE_NAMES = {
  [UserRole.ACCOUNT_USER]: 'Account User',
  [UserRole.OPERATOR]: 'Operator'
};

export function getAccessLevelLabel(level: AccessLevelEnum) {
  switch (level) {
    case AccessLevelEnum.OPERATORUSER:
      return 'Operator';
    case AccessLevelEnum.VIEWONLY:
      return 'View only';
    case AccessLevelEnum.BASICUSER:
      return 'Basic';
    case AccessLevelEnum.ANALYTICSUSER:
      return 'Analytics';
    case AccessLevelEnum.TENANTADMIN:
      return 'Administrator';
  }
}

export function getUserRoleByAccessLevel(level: AccessLevelEnum): UserRole {
  if (!level) {
    return;
  }
  if (ACCOUNT_USER_ACCESS_LEVELS.includes(level)) {
    return UserRole.ACCOUNT_USER;
  }
  if (OPERATOR_USER_ACCESS_LEVELS.includes(level)) {
    return UserRole.OPERATOR;
  }
}

export function getUserAccessLevel(token: string): AccessLevelEnum {
  if (!token) {
    return null;
  }

  const decodedToken = jwtHelper.decodeToken(token);
  return decodedToken?.userAccess;
}

export function hasAccessLevel(userAccessLevel: AccessLevelEnum, accessLevelToCheck: AccessLevelEnum) {
  // No access level to check - not allowed
  if (!accessLevelToCheck) {
    return false;
  }

  // Top access level - can do anything
  if (userAccessLevel === ACCOUNT_USER_ACCESS_LEVELS[ACCOUNT_USER_ACCESS_LEVELS.length - 1]) {
    return true;
  }

  const userAccessLevelIndex = ACCOUNT_USER_ACCESS_LEVELS.findIndex(level => level === userAccessLevel);
  const accessLevelToCheckIndex = ACCOUNT_USER_ACCESS_LEVELS.findIndex(level => level === accessLevelToCheck);
  return userAccessLevelIndex >= accessLevelToCheckIndex;
}

export function minAccessLevel(...accessLevels: AccessLevelEnum[]) {
  if (accessLevels.length === 0) {
    return ACCOUNT_USER_ACCESS_LEVELS[0];
  }

  const minIndex = Math.min(...accessLevels.map(accessLevel => ACCOUNT_USER_ACCESS_LEVELS.findIndex(level => level === accessLevel)));
  return ACCOUNT_USER_ACCESS_LEVELS[minIndex];
}
