import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, QueryEntity, StoreConfig } from '@datorama/akita';
import { get, set } from 'lodash';
import { Observable, catchError, map, skipWhile, switchMap, take, tap, throwError } from 'rxjs';
import { GetUserSettingsResponse } from '../../../generated/ums/model/getUserSettingsResponse';
import { ZoomLevelEnum } from '../../detailed-site/detailed-site-sidenav/activities/full-project-dialog/gantt-chart/gantt-chart.model';
import { getServiceUrl } from '../utils/backend-services';

export interface GlobalUserSettings {
  allSites: {
    sidenav: {
      sorting: { type: string; isDesc: boolean };
      groupingType: string;
      collapsedGroups: string[];
    };
  };
  siteDetails: {
    siteLastViewTime: { [siteId: string]: string };
    sidenav: {
      isExpanded: boolean;
    };
  };
}

export interface SiteUserSettings {
  sidenav: {
    tabsSorting: { [tabName: string]: { type: string; isDesc: boolean } };
  };
  gantt: {
    showCriticalPath: boolean;
    showOnlyGeometric: boolean;
    shownColumns: string[];
    zoomLevel: ZoomLevelEnum;
  };
}

interface SiteUserSettingsEntity extends Partial<SiteUserSettings> {
  siteId: string;
  isLoaded?: boolean;
}

interface UserSettingsState extends EntityState<SiteUserSettingsEntity> {
  globalSettings: Partial<GlobalUserSettings>;
  isGlobalSettingsLoaded?: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-settings', idKey: 'siteId' })
class UserSettingsStore extends EntityStore<UserSettingsState> {
  constructor() {
    super({ globalSettings: {}, globalSettingsLoading: false });
  }
}

class SettingsNotInitializedError extends Error {
  constructor(type: 'global' | 'site') {
    super(`Using ${type} settings before it was initialized. Use after init or use an observable.`);
  }
}

@Injectable({ providedIn: 'root' })
class UserSettingsQuery extends QueryEntity<UserSettingsState> {
  constructor(protected store: UserSettingsStore) {
    super(store);
  }

  getGlobalUserSettings() {
    const { globalSettings, isGlobalSettingsLoaded } = this.store.getValue();
    if (!isGlobalSettingsLoaded) {
      throw new SettingsNotInitializedError('global');
    }

    return globalSettings ?? {};
  }

  getSiteUserSettings(siteId: string): Partial<SiteUserSettings> {
    const { siteId: _, isLoaded, ...settings } = this.getEntity(siteId) ?? {};
    if (!isLoaded) {
      throw new SettingsNotInitializedError('site');
    }

    return settings ?? {};
  }
}

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  constructor(private http: HttpClient, private store: UserSettingsStore, private query: UserSettingsQuery) {}

  selectGlobalUserSetting$(): Observable<GlobalUserSettings>;
  selectGlobalUserSetting$(settingPath?: string): Observable<any>;
  selectGlobalUserSetting$(settingPath?: string) {
    return this.query.select(['globalSettings', 'isGlobalSettingsLoaded']).pipe(
      skipWhile(({ isGlobalSettingsLoaded }) => !isGlobalSettingsLoaded),
      take(1),
      map(({ globalSettings }) => (settingPath ? get(globalSettings, settingPath) : globalSettings))
    );
  }

  selectSiteUserSetting$(siteId: string): Observable<SiteUserSettings>;
  selectSiteUserSetting$(siteId: string, settingPath?: string): Observable<any>;
  selectSiteUserSetting$(siteId: string, settingPath?: string) {
    return this.query.selectEntity(siteId).pipe(
      skipWhile(settings => !settings?.isLoaded),
      take(1),
      map(settings => {
        if (settingPath) {
          return get(settings, settingPath);
        }

        const { siteId: _, isLoaded, ...siteSettings } = settings ?? {};
        return siteSettings as SiteUserSettings;
      })
    );
  }

  getGlobalUserSetting(settingPath: string) {
    const globalSettings = this.query.getGlobalUserSettings();
    return get(globalSettings, settingPath);
  }

  getSiteUserSetting(siteId: string, settingPath: string) {
    const siteSettings = this.query.getSiteUserSettings(siteId);
    return get(siteSettings, settingPath);
  }

  initGlobalSettings() {
    return this.http.get<GetUserSettingsResponse>(`${getServiceUrl('ums')}/userSettings`).pipe(
      map(result => result?.userSettings as GlobalUserSettings),
      tap(globalSettings => {
        this.store.update({ globalSettings: globalSettings ?? {}, isGlobalSettingsLoaded: true });
      })
    );
  }

  initSiteSettings(siteId: string) {
    return this.http.get<GetUserSettingsResponse>(`${getServiceUrl('ums')}/userSettings`, { params: { siteId } }).pipe(
      map(result => result?.userSettings as SiteUserSettings),
      tap(siteSettings => {
        this.store.add({ siteId, isLoaded: true, ...siteSettings } as SiteUserSettingsEntity);
      })
    );
  }

  updateGlobalUserSetting(settingPath: string, value: any) {
    return this.selectGlobalUserSetting$().pipe(
      switchMap(settings => {
        const globalSettings = set(structuredClone(settings ?? {}), settingPath, value);
        return this.http.post<void>(`${getServiceUrl('ums')}/userSettings`, { userSettings: globalSettings }).pipe(
          tap(() => this.store.update({ globalSettings })),
          catchError(error => {
            console.error(`Error updating "${settingPath}" global user setting with value: "${value}"`, error);
            return throwError(() => error);
          })
        );
      })
    );
  }

  updateSiteUserSetting(siteId: string, settingPath: string, value: any) {
    return this.selectSiteUserSetting$(siteId).pipe(
      switchMap(settings => {
        const siteSettings = set(structuredClone(settings ?? {}), settingPath, value);
        return this.http.post<void>(`${getServiceUrl('ums')}/userSettings`, { userSettings: siteSettings }, { params: { siteId } }).pipe(
          tap(() => this.store.upsert(siteId, siteSettings)),
          catchError(error => {
            console.error(`Error updating "${settingPath}" site user setting with value: "${value}"`, error);
            return throwError(() => error);
          })
        );
      })
    );
  }
}
