/**
 * Activity Management Service
 * Manage Activities and Project Plan (GANTT)
 *
 * OpenAPI spec version: 1.8.8
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GetActivitiesResponse } from './getActivitiesResponse';
import { GetActivityDependencyResponse } from './getActivityDependencyResponse';

export interface GetActivityResponse {
  activityOwner?: string;
  activityRowNumber?: number;
  activityType?: GetActivityResponse.ActivityTypeEnum;
  actualEndDate?: Date;
  actualStartDate?: Date;
  amount?: number;
  childActivities?: GetActivitiesResponse;
  complete?: number;
  createdBy?: GetActivityResponse.CreatedByEnum;
  critical?: boolean;
  dependencies?: Array<GetActivityDependencyResponse>;
  description?: string;
  duration?: string;
  endDate?: Date;
  forecastEndDate?: Date;
  geometric?: boolean;
  id?: string;
  markedAsComplete?: boolean;
  name?: string;
  parentActivityId?: string;
  projectPlanId?: string;
  siteId?: string;
  sourceActivityUniqueId?: string;
  startDate?: Date;
  tenantId?: string;
  tracked?: boolean;
  userActualEndDate?: Date;
  userActualStartDate?: Date;
  version?: string;
}
export namespace GetActivityResponse {
  export type ActivityTypeEnum = 'LENGTH' | 'AREA' | 'VOLUME' | 'WEIGHT' | 'COUNT';
  export const ActivityTypeEnum = {
    LENGTH: 'LENGTH' as ActivityTypeEnum,
    AREA: 'AREA' as ActivityTypeEnum,
    VOLUME: 'VOLUME' as ActivityTypeEnum,
    WEIGHT: 'WEIGHT' as ActivityTypeEnum,
    COUNT: 'COUNT' as ActivityTypeEnum
  };
  export type CreatedByEnum = 'SYSTEM' | 'USER' | 'ORIGINAL_FILE';
  export const CreatedByEnum = {
    SYSTEM: 'SYSTEM' as CreatedByEnum,
    USER: 'USER' as CreatedByEnum,
    ORIGINALFILE: 'ORIGINAL_FILE' as CreatedByEnum
  };
}
