import proj4 from 'proj4';
import { chunk } from 'lodash';
import { GetCoordinateSystemResponse } from '../../../generated/tenant/model/models';
import { Cartographic } from './geo';
import { isLocalCS } from './backend-services';
import { convertFromMeters, convertToMeters } from './unit-conversion';

export function convertCoordinateToSiteCS({ longitude, latitude, height = 0 }: Cartographic, cs: GetCoordinateSystemResponse) {
  if (!cs) {
    return null;
  }

  if (isLocalCS(cs)) {
    const utmProj4 = cs.localCoordinateSystem?.utmProj4;
    const matrixStr = cs.localCoordinateSystem?.utmToCsMatrix;
    if (!matrixStr || !utmProj4) {
      return null;
    }

    const matrix = parseConversionMatrixString(matrixStr);
    if (!matrix) {
      return null;
    }

    // Use proj4 to convert geo to UTM
    const [xUTM, yUTM] = proj4(utmProj4, [longitude, latitude]);

    // Use conversion matrix to transform UTM to local CS
    const x = convertFromMeters(matrix[0][0] * xUTM + matrix[0][1] * yUTM + matrix[0][3], cs.units);
    const y = convertFromMeters(matrix[1][0] * xUTM + matrix[1][1] * yUTM + matrix[1][3], cs.units);
    const z = convertFromMeters(height, cs.units);

    return { x, y, z };
  } else {
    if (!cs.proj4) {
      return null;
    }

    const [x, y] = proj4(cs.proj4, [longitude, latitude]);
    const z = convertFromMeters(height, cs.units);
    return { x, y, z };
  }
}

export function convertCoordinateCSToPosition({ x, y, z = 0 }: { x: number; y: number; z?: number }, cs: GetCoordinateSystemResponse) {
  if (!cs) {
    return null;
  }

  if (isLocalCS(cs)) {
    const utmProj4 = cs.localCoordinateSystem?.utmProj4;
    const matrixStr = cs.localCoordinateSystem?.csToUtmMatrix;
    if (!matrixStr || !utmProj4) {
      return null;
    }

    const matrix = parseConversionMatrixString(matrixStr);
    if (!matrix) {
      return null;
    }

    // First convert to meters
    const meterX = convertToMeters(x, cs.units);
    const meterY = convertToMeters(y, cs.units);
    const meterZ = convertToMeters(z, cs.units);

    // Use conversion matrix to transform local CS to UTM
    const xUTM = matrix[0][0] * meterX + matrix[0][1] * meterY + matrix[0][3];
    const yUTM = matrix[1][0] * meterX + matrix[1][1] * meterY + matrix[1][3];
    const height = meterZ;

    // Use proj4 to convert UTM to geo
    const [longitude, latitude] = proj4(utmProj4).inverse([xUTM, yUTM]);

    return { longitude, latitude, height };
  } else {
    if (!cs.proj4) {
      return null;
    }

    const [longitude, latitude] = proj4(cs.proj4).inverse([x, y]);
    const height = convertToMeters(z, cs.units);
    return { longitude, latitude, height };
  }
}

function parseConversionMatrixString(matrixStr: string) {
  try {
    const arr = matrixStr.split(';').map(num => +num);
    return chunk(arr, 4);
  } catch (error) {
    console.error('CS conversion error', error);
    return null;
  }
}
