import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'version-page',
  template: ` <div class="full-container full-height row justify-center align-center version">{{ appName }} v{{ version }}</div> `,
  styleUrls: ['./version-page.component.scss']
})
export class VersionPageComponent implements OnInit {
  appName = environment.whitelabel.appName;
  version = environment.version;

  constructor() {}

  ngOnInit() {}
}
