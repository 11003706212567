import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import {
  CrossSectionProgressReport,
  CrossSectionVolumeReport,
  ElevationGridHeatmapReport,
  ReportEntity,
  ReportType,
  VolumeGridHeatmapReport
} from './detailed-site-reports.model';

export interface DetailedSiteReportsState {}

export function createInitialState(): DetailedSiteReportsState {
  return {};
}

interface CrossSectionVolumeReportsState extends EntityState<CrossSectionVolumeReport> {}
interface CrossSectionProgressReportsState extends EntityState<CrossSectionProgressReport> {}
interface GridReportsState extends EntityState<VolumeGridHeatmapReport | ElevationGridHeatmapReport> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-activities' })
export class DetailedSiteReportsStore extends Store<DetailedSiteReportsState> {
  crossSectionVolumeReports = new EntityStore<CrossSectionVolumeReportsState>({}, { name: 'cross-section-volume-reports', idKey: 'id' });
  crossSectionProgressReports = new EntityStore<CrossSectionProgressReportsState>(
    {},
    { name: 'cross-section-progress-reports', idKey: 'id' }
  );
  gridReports = new EntityStore<GridReportsState>({}, { name: 'grid-reports', idKey: 'id' });

  constructor() {
    super(createInitialState());
  }

  resetStore() {
    this.reset();
    this.crossSectionVolumeReports.reset();
    this.crossSectionProgressReports.reset();
    this.gridReports.reset();
  }

  upsertCrossSectionVolumeReports(reports: CrossSectionVolumeReport[]) {
    this.crossSectionVolumeReports.upsertMany(reports);
  }

  upsertCrossSectionProgressReports(reports: CrossSectionProgressReport[]) {
    this.crossSectionProgressReports.upsertMany(reports);
  }

  upsertGridReports(reports: (VolumeGridHeatmapReport | ElevationGridHeatmapReport)[]) {
    this.gridReports.upsertMany(reports);
  }

  deleteReport(report: ReportEntity) {
    switch (report.type) {
      case ReportType.CROSS_SECTION_VOLUME:
        this.crossSectionVolumeReports.remove(report.id);
        break;
      case ReportType.CROSS_SECTION_PROGRESS:
        this.crossSectionProgressReports.remove(report.id);
        break;
      case ReportType.VOLUME_GRID_HEATMAP:
      case ReportType.ELEVATION_GRID_HEATMAP:
        this.gridReports.remove(report.id);
        break;
    }
  }
}
