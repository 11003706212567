<dialog-layout (closeDialog)="close()" [header]="fileData.name">
  <div class="file-preview-container" [ngSwitch]="fileData.type">
    <ng-container *ngSwitchCase="PreviewType.IMAGE">
      <single-image-preview
        [image]="fileData.safeUrl"
        [downloadUrl]="fileData.url"
        [name]="fileData.name"
      >
      </single-image-preview>
      <div class="image-footer"></div>
    </ng-container>

    <div class="document-container" *ngSwitchCase="PreviewType.DOC">
      <iframe [src]="fileData.safeUrl"></iframe>
    </div>

    <div class="document-container" *ngSwitchCase="PreviewType.VIDEO">
      <video [src]="fileData.safeUrl" controls disablepictureinpicture></video>
    </div>
  </div>
</dialog-layout>
