import { Input } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Directive, ElementRef } from '@angular/core';
import { AuthQuery } from '../../auth/state/auth.query';
import { AccessLevelEnum, ACCOUNT_USER_ACCESS_LEVELS } from '../../auth/state/auth.utils';

const TITLE_ERROR_MESSAGE = $localize`:@@shared.disableByAccessTitle:You do not have sufficient permissions to use this action`;

@Directive({
  selector: '[disableByAccess]'
})
export class DisableByAccessLevelDirective implements AfterViewInit {
  private isLoading = true;
  private hasValidAccessLevel = true;
  private extraConditionsValue = false;
  private prevDisplayValue: string;

  // Defaults to highest access level
  private currentAccessLevel = ACCOUNT_USER_ACCESS_LEVELS[ACCOUNT_USER_ACCESS_LEVELS.length - 1];
  @Input('disableByAccess') set accessLevel(accessLevel: AccessLevelEnum) {
    this.currentAccessLevel = accessLevel;
    this.hasValidAccessLevel = !accessLevel || this.authQuery.hasAccessLevel(accessLevel);

    this.updateState();
  }
  get accessLevel() {
    return this.currentAccessLevel;
  }

  @Input('disableByAccessBehaviour') behaviour: 'disable' | 'hide' = 'disable';
  @Input('disableByAccessChangeTitle') changeTitle = true;

  @Input('disableByAccessExtraConditions') set extraConditions(disabled: boolean) {
    this.extraConditionsValue = disabled;

    this.updateState();
  }

  @Input() set title(title: string) {
    this.setTitle(title);
  }

  constructor(private el: ElementRef, private authQuery: AuthQuery) {}

  ngAfterViewInit() {
    this.hasValidAccessLevel = !this.accessLevel || this.authQuery.hasAccessLevel(this.accessLevel);

    const nativeElement: HTMLInputElement = this.el.nativeElement;
    this.prevDisplayValue = window.getComputedStyle(nativeElement)?.display;

    this.isLoading = false;
    this.updateState();
  }

  private updateState() {
    if (this.isLoading) {
      return;
    }

    const value = !this.hasValidAccessLevel || this.extraConditionsValue;
    if (this.behaviour === 'hide') {
      this.setHidden(value);
    } else {
      this.setDisabled(value);
      this.setTitle(this.el.nativeElement.title);
    }
  }

  private setHidden(hidden: boolean) {
    const nativeElement: HTMLInputElement = this.el.nativeElement;

    if (hidden) {
      this.prevDisplayValue = window.getComputedStyle(nativeElement)?.display;
      nativeElement.style.display = 'none';
    } else {
      nativeElement.style.display = this.prevDisplayValue ?? 'block';
      this.prevDisplayValue = null;
    }
  }

  private setDisabled(disabled: boolean) {
    const nativeElement: HTMLInputElement = this.el.nativeElement;
    nativeElement.disabled = disabled;

    if (disabled) {
      nativeElement.classList.add('disabled');

      if (nativeElement.classList.contains('mat-button-base')) {
        nativeElement.classList.add('mat-button-disabled');
      }
    } else {
      nativeElement.classList.remove('disabled');

      if (nativeElement.classList.contains('mat-button-base')) {
        nativeElement.classList.remove('mat-button-disabled');
      }
    }
  }

  private setTitle(title: string) {
    if (!this.changeTitle) {
      return;
    }

    let titleMessage = title;
    if (!this.hasValidAccessLevel) {
      if (!title) {
        titleMessage = TITLE_ERROR_MESSAGE;
      } else if (!title.includes(TITLE_ERROR_MESSAGE)) {
        titleMessage = title + '\n\n' + TITLE_ERROR_MESSAGE;
      }
    }

    this.el.nativeElement.title = titleMessage;
  }
}
