import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthQuery } from './state/auth.query';
import { AuthService } from './state/auth.service';

@Injectable({
  providedIn: 'root'
})
class LoginPageGuardService {
  constructor(private router: Router, private authService: AuthService, private authQuery: AuthQuery) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { redirectUrl } = route.queryParams;

    return this.authService.isLoggedIn().pipe(
      map(isLoggedIn => {
        // Allow going to login screen if not logged in
        if (!isLoggedIn) {
          return true;
        }

        // If already logged in redirect to redirectUrl/defaultUrl
        let url = redirectUrl;
        if (!redirectUrl) {
          const userRole = this.authQuery.getUserRole();
          url = this.authQuery.getDefaultUrl(userRole);
        }

        return this.router.parseUrl(url);
      })
    );
  }
}

export const LoginPageGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(LoginPageGuardService).canActivate(next, state);
};
