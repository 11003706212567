import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feature, MultiPolygon, multiPolygon } from '@turf/turf';
import { TileProviderError } from 'cesium';
import { Subject, catchError, lastValueFrom, map, of } from 'rxjs';
import { GetBorderCoordinatesResponse } from '../../../generated/file/model/getBorderCoordinatesResponse';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../auth/state/auth.utils';
import PERMISSIONS from '../../auth/state/permissions';
import { getServiceUrl } from '../../shared/utils/backend-services';
import { DEFAULT_MAX_DETAIL_LEVEL } from '../../shared/utils/cesium-common';
import { getArrayDepth, isDefined } from '../../shared/utils/general';
import { GeoUtils } from '../../shared/utils/geo';
import { DetailedSiteDesignsQuery } from '../state/detailed-site-designs/detailed-site-designs.query';
import { SourceModel } from '../state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from '../state/detailed-site-entities/detailed-site-entities.query';
import { DetailedSiteQuery } from '../state/detailed-site.query';
import { TerrainProviderEdit } from './terrain-provider-edit';
import { TerrainProviderWithEmptyTiles } from './terrain-provider-with-empty-tiles';
import { ViewerResourcesService } from './viewer-resources.service';

export interface Terrain {
  siteId: string;
  taskOrDesignId: string;
  url: string;
  type: TerrainType;
  sourceModel: SourceModel;
  provider: TerrainProviderEdit | TerrainProviderWithEmptyTiles;
  bounds?: Feature<MultiPolygon>;
  minHeight?: number;
  maxHeight?: number;
}

export type TerrainType = 'DESIGN' | 'TASK' | 'FLAT';

const TERRAIN_MAPPING_SEPERATOR = '.';
const FLAT_TERRAIN_KEY = 'FLAT_TERRAIN';

interface TerrainMappingKeys {
  siteId: string;
  taskOrDesignId: string;
  type: TerrainType;
  sourceModel: SourceModel;
}

// @ts-ignore
class FlatTerrainProvider extends Cesium.EllipsoidTerrainProvider {
  private _availability = new Cesium.TileAvailability(
    new Cesium.GeographicTilingScheme({ ellipsoid: Cesium.Ellipsoid.WGS84 }),
    DEFAULT_MAX_DETAIL_LEVEL
  );

  // @ts-ignore
  get availability() {
    return this._availability;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TerrainProviderService {
  // siteId => type => taskId => sourceModel => with/without edits => terrain
  private terrainMapping = {};
  terrainInvalidation$ = new Subject<TerrainMappingKeys>();

  constructor(
    private http: HttpClient,
    private siteQuery: DetailedSiteQuery,
    private siteEntitiesQuery: DetailedSiteEntitiesQuery,
    private designsQuery: DetailedSiteDesignsQuery,
    private resourceService: ViewerResourcesService
  ) {
    this.terrainMapping[FLAT_TERRAIN_KEY] = {
      provider: new FlatTerrainProvider(),
      sourceModel: SourceModel.FLAT,
      type: 'FLAT',
      siteId: null,
      taskOrDesignId: null,
      url: null
    };
  }

  async getCurrentTaskTerrain() {
    const siteId = this.siteQuery.getSiteId();
    const taskId = this.siteQuery.getActiveTaskId();
    const sourceModel = this.siteQuery.getCurrentSourceModel();
    return siteId && taskId && sourceModel
      ? await this.getTerrainProvider(siteId, taskId, sourceModel === SourceModel.FLAT ? 'FLAT' : 'TASK', sourceModel)
      : undefined;
  }

  async getTerrainProvider(
    siteId: string,
    taskOrDesignId: string,
    type: TerrainType = 'TASK',
    sourceModel: SourceModel = SourceModel.DTM,
    useEdits = true
  ) {
    const key = this.getMapKey(siteId, type, taskOrDesignId, sourceModel, useEdits);
    let terrain: Terrain = this.terrainMapping[key];
    if (terrain) {
      return terrain;
    }

    let url: string;
    let bounds: Feature<MultiPolygon>;
    if (type === 'DESIGN') {
      url = this.designsQuery.getDesignUrls(taskOrDesignId).terrain;

      // Design bounds is only supported for designs with terrain that are not surfaces
      const design = this.designsQuery.getDesign(taskOrDesignId);
      if (design && design.terrainReady && design.hasBorder) {
        bounds = await this.getDesignBounds(siteId, taskOrDesignId);
      }
    } else {
      if (sourceModel === SourceModel.DSM) {
        url = this.siteQuery.getViewerUrls(taskOrDesignId).terrainComplete;
      } else {
        url = this.siteQuery.getViewerUrls(taskOrDesignId).terrain;
      }
    }

    const resource = this.resourceService.createResourceFromUrl(url);
    let provider: TerrainProviderEdit | TerrainProviderWithEmptyTiles;

    // Only use modified terrain provider if necessary
    if (useEdits && type === 'TASK' && sourceModel === SourceModel.DTM) {
      const modelEdits = this.siteEntitiesQuery.getTaskModelEdits(taskOrDesignId);
      if (modelEdits?.length > 0) {
        provider = new TerrainProviderEdit({
          url: resource,
          modelEditPolygons: modelEdits.map(modelEdit => {
            const positionsElevation = modelEdit.positionsElevation[taskOrDesignId];
            return modelEdit.positions.map(GeoUtils.cartesian3ToDeg).map((p, i) => [p.longitude, p.latitude, positionsElevation[i]]);
          })
        });
      }
    }

    // Fallback to regular terrain provider
    if (!provider) {
      provider = new TerrainProviderWithEmptyTiles({ url: resource });
    }

    provider.errorEvent.addEventListener((terrainError: TileProviderError) => {
      // Added to surpress console errors
    });

    terrain = {
      url,
      siteId,
      taskOrDesignId,
      type,
      sourceModel,
      provider,
      bounds
    };

    this.terrainMapping[key] = terrain;

    // Update terrain min/max heights
    this.resourceService
      .createResourceFromUrl(url + '/layer.json')
      .fetchJson()
      .then(layersMetadata => {
        const terrain: Terrain = this.terrainMapping[key];
        if (terrain) {
          terrain.minHeight = layersMetadata.min_height;
          terrain.maxHeight = layersMetadata.max_height;
        }
      })
      .catch(error => {
        console.error('Error fetching terrain layer.json', error);
      });

    return terrain;
  }

  private async getDesignBounds(siteId: string, designId: string): Promise<Feature<MultiPolygon>> {
    return await lastValueFrom(
      this.http
        .get<GetBorderCoordinatesResponse>(`${getServiceUrl('file')}/sites/${siteId}/designs/${designId}/borderCoordinates`, {
          headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designs.read }
        })
        .pipe(
          map(response => {
            if (isDefined(response?.coordinates)) {
              let coordinates: number[][][][] = response.coordinates;
              if (getArrayDepth(coordinates) === 3) {
                // If polygon coordinates convert to multipolygon
                coordinates = (response.coordinates as any).map(polygon => [polygon]);
              }
              return multiPolygon(coordinates);
            }
          }),
          catchError(error => {
            console.error('Error fetching design terrain bounds', error);
            return of(null);
          })
        )
    );
  }

  invalidateTerrainProvider(siteId: string, taskOrDesignId: string, type: TerrainType, sourceModel: SourceModel, useEdits = true) {
    delete this.terrainMapping[this.getMapKey(siteId, type, taskOrDesignId, sourceModel, useEdits)];
    this.terrainInvalidation$.next({ siteId, taskOrDesignId, type, sourceModel });
  }

  private getMapKey(siteId: string, type: TerrainType, taskOrDesignId: string, sourceModel: SourceModel, useEdits: boolean) {
    if (sourceModel === SourceModel.FLAT) {
      return FLAT_TERRAIN_KEY;
    }

    return [siteId, type, taskOrDesignId, sourceModel, useEdits ? 'withEdits' : 'withoutEdits'].join(TERRAIN_MAPPING_SEPERATOR);
  }
}
