import * as turf from '@turf/turf';
import {
  area,
  bbox,
  bboxPolygon,
  center,
  centerOfMass,
  concave,
  Feature,
  featureCollection,
  getCoord,
  lineIntersect,
  lineString,
  midpoint,
  Point,
  point,
  Polygon,
  polygon,
  toWgs84,
  transformRotate,
  transformScale
} from '@turf/turf';
import { Cartesian3 } from 'angular-cesium';
import { Rectangle as CesiumRectangle } from 'cesium';
import { maxBy, minBy } from 'lodash';
import { environment } from '../../../environments/environment';
import { isDefined } from './general';

export interface Cartographic {
  longitude: number;
  latitude: number;
  height?: number;
}

export type Rectangle = CesiumRectangle;

export class GeoUtils {
  static boundingBox(points: number[][], paddingDegrees: number = 0.0005): Rectangle {
    if (!points || points.length === 0) {
      return null;
    }

    if (points.length === 1) {
      return Cesium.Cartesian3.fromDegrees(points[0][0], points[0][1], 4000);
    }

    const line = lineString(points);
    const bboxArray = bbox(line);

    // bbox is a point if north equals south and west equals east
    if (bboxArray[0] === bboxArray[2] && bboxArray[1] === bboxArray[3]) {
      return Cesium.Cartesian3.fromDegrees(points[0][0], points[0][1], 4000);
    }

    return this.rectanglePadding(Cesium.Rectangle.fromDegrees(...bboxArray), paddingDegrees);
  }

  static rectanglePadding(rectangle: Rectangle, paddingDegrees: number = 0.0005): Rectangle {
    if (!paddingDegrees) {
      return rectangle;
    }

    const paddingRadians = paddingDegrees * (Math.PI / 180);
    return Cesium.Rectangle.fromRadians(
      rectangle.west - paddingRadians,
      rectangle.south - paddingRadians,
      rectangle.east + paddingRadians,
      rectangle.north + paddingRadians
    );
  }

  static rectangleToPolygon(rectangle: Rectangle) {
    const north = Cesium.Math.toDegrees(rectangle.north);
    const south = Cesium.Math.toDegrees(rectangle.south);
    const east = Cesium.Math.toDegrees(rectangle.east);
    const west = Cesium.Math.toDegrees(rectangle.west);
    return polygon([
      [
        [west, north],
        [east, north],
        [east, south],
        [west, south],
        [west, north]
      ]
    ]);
  }

  static boundingBoxPolygon(points: number[][], scale: number = 1) {
    if (points.length < 2) {
      return null;
    }

    const line = lineString(points);
    let bboxArray = bbox(line);

    // if bbox is a straight line (north equals south or west equals east) then make it a rectangle
    if (bboxArray[0] === bboxArray[2]) {
      bboxArray = [bboxArray[0] - 0.00001, bboxArray[1], bboxArray[2] + 0.00001, bboxArray[3]];
    } else if (bboxArray[1] === bboxArray[3]) {
      bboxArray = [bboxArray[0], bboxArray[1] - 0.00001, bboxArray[2], bboxArray[3] + 0.00001];
    }

    let blockingPolygon = bboxPolygon(bboxArray);

    if (scale !== 1) {
      blockingPolygon = transformScale(blockingPolygon, scale);
    }

    return blockingPolygon;
  }

  static cartographicRadToDegrees = (cartographicRad: Cartographic) => {
    return {
      longitude: Cesium.Math.toDegrees(cartographicRad.longitude),
      latitude: Cesium.Math.toDegrees(cartographicRad.latitude),
      height: cartographicRad.height
    } as Cartographic;
  };

  static cartesian3ToDeg = (cartesian: Cartesian3) => {
    const cart = Cesium.Cartographic.fromCartesian(cartesian);
    return {
      longitude: Cesium.Math.toDegrees(cart.longitude),
      latitude: Cesium.Math.toDegrees(cart.latitude),
      height: cart.height
    } as Cartographic;
  };

  static cartesian3ToDegArray = (cartesian: Cartesian3, withHeight = true) => {
    const cart = Cesium.Cartographic.fromCartesian(cartesian);
    const longitude: number = Cesium.Math.toDegrees(cart.longitude);
    const latitude: number = Cesium.Math.toDegrees(cart.latitude);
    const height: number = cart.height;

    if (withHeight) {
      return [longitude, latitude, height];
    } else {
      return [longitude, latitude];
    }
  };

  static addCartesian3HeightDelta(position: Cartesian3, heightDelta: number) {
    const origMagnitude = Cesium.Cartesian3.magnitude(position);
    const newMagnitude = origMagnitude + heightDelta;
    const scalar = newMagnitude / origMagnitude;

    const newPosition = new Cesium.Cartesian3();
    return Cesium.Cartesian3.multiplyByScalar(position, scalar, newPosition) as Cartesian3;
  }

  static centerOfPolygon(cartesians: Cartesian3[]): Cartesian3 {
    const polygonPoints = cartesians.map(p => GeoUtils.cartesian3ToDeg(p)).map(latlon => [latlon.longitude, latlon.latitude]);

    const polygonFeature = polygon([[...polygonPoints, polygonPoints[0]]]);
    const center = centerOfMass(polygonFeature);

    return Cesium.Cartesian3.fromDegrees(center.geometry.coordinates[0], center.geometry.coordinates[1]);
  }

  static centerOfPositions(positions: number[][]): number[] {
    const points = positions.map(p => point(p));
    return center(featureCollection(points)).geometry.coordinates;
  }

  static mercatorToWg84(xMeters: number, yMeters: number): Cartographic {
    const pt = point([xMeters, yMeters]);
    const converted = toWgs84(pt);

    return Cesium.Cartographic.fromDegrees(converted.geometry.coordinates[0], converted.geometry.coordinates[1]);
  }

  static nearestPositionsIndexList(targetPoint: Cartesian3, positions: Cartesian3[], numberOfResults = 16) {
    const distances = positions.map((position, index) => ({
      index,
      distance: Cesium.Cartesian3.distance(position, targetPoint)
    }));

    return distances
      .sort((d1, d2) => d1.distance - d2.distance)
      .slice(0, numberOfResults)
      .map(d => d.index);
  }

  static positionToPoint(position: Cartographic): Feature<Point> {
    return point([position.longitude, position.latitude]);
  }

  static distance(positionFrom: Cartographic, positionTo: Cartographic, withHeight = true): number {
    return Cesium.Cartesian3.distance(
      Cesium.Cartesian3.fromDegrees(positionFrom.longitude, positionFrom.latitude, (withHeight && positionFrom.height) || 0),
      Cesium.Cartesian3.fromDegrees(positionTo.longitude, positionTo.latitude, (withHeight && positionTo.height) || 0)
    );
  }

  static polylineDistance(positions: Cartesian3[]): number {
    if (!isDefined(positions)) {
      return null;
    }

    return positions.slice(0, -1).reduce((distance, position, i) => {
      return distance + Cesium.Cartesian3.distance(position, positions[i + 1]);
    }, 0);
  }

  static rotate(positions: Cartographic[], degree: number) {
    const collection = this.pointsCollection(positions);
    return transformRotate(collection, degree);
  }

  private static pointsCollection(positions: Cartographic[]) {
    const pointPositions = positions.map(position => this.positionToPoint(position));
    return featureCollection(pointPositions);
  }

  private static calculatePolygonForOverlapChecking(positions: Cartographic[]) {
    if (positions.length > 3) {
      return this.concavePolygonByPositions(positions);
    } else {
      const collection = this.pointsCollection(positions);
      const bboxArray = bbox(collection);
      return bboxPolygon(bboxArray);
    }
  }

  static isOverlapping(positions1: Cartographic[], positions2: Cartographic[]): boolean {
    const polygon1: Feature<Polygon> = this.calculatePolygonForOverlapChecking(positions1);
    const polygon2: Feature<Polygon> = this.calculatePolygonForOverlapChecking(positions2);
    if (!polygon1 || !polygon2) {
      return false;
    }

    return (turf as any).booleanIntersects(polygon1, polygon2);
  }

  static pointCenterByLonLat(positions: Cartographic[]): Feature<Point> {
    if (positions.length > 3) {
      const polygon = this.concavePolygonByPositions(positions);
      if (polygon) {
        return centerOfMass(polygon);
      }
    }

    const collection = this.pointsCollection(positions);
    return center(collection);
  }

  static cartographicCenterOfMassByLonLat(positions: Cartographic[]): Cartographic {
    const center = this.pointCenterByLonLat(positions);
    const centerCoord = getCoord(center);
    return {
      longitude: centerCoord[0],
      latitude: centerCoord[1]
    };
  }

  static midpoint(coord1: number[], coord2: number[]): Cartographic {
    const point1 = point(coord1);
    const point2 = point(coord2);
    const midpointPoint = midpoint(point1, point2);
    return {
      longitude: midpointPoint.geometry.coordinates[0],
      latitude: midpointPoint.geometry.coordinates[1]
    };
  }

  static concavePolygonByPositions(positions: Cartographic[]): Feature<Polygon> {
    const collection = this.pointsCollection(positions);
    return concave(collection) as Feature<Polygon>;
  }

  static rotatePositionAroundPivot(longitude: number, latitude: number, degree: number, pivot: Feature<Point>): Cartographic {
    const rotatedLonLat = transformRotate(this.positionToPoint({ longitude, latitude }), degree, { pivot });

    return { longitude: getCoord(rotatedLonLat)[0], latitude: getCoord(rotatedLonLat)[1] };
  }

  static twoPointsLonLatDelta(position1: Cartographic, position2: Cartographic): Cartographic {
    return {
      longitude: position1.longitude - position2.longitude,
      latitude: position1.latitude - position2.latitude
    };
  }

  static maxByLat(positions: Cartographic[]): Cartographic {
    return maxBy(positions, item => item.latitude);
  }
  static minByLat(positions: Cartographic[]): Cartographic {
    return minBy(positions, item => item.latitude);
  }
  static maxByLon(positions: Cartographic[]): Cartographic {
    return maxBy(positions, item => item.longitude);
  }
  static minByLon(positions: Cartographic[]): Cartographic {
    return minBy(positions, item => item.longitude);
  }

  static tesselatePositions(positions: Cartesian3[]) {
    const polygonGeometry = Cesium.PolygonGeometry.fromPositions({
      positions,
      perPositionHeight: true
    });
    const geometry = Cesium.PolygonGeometry.createGeometry(polygonGeometry);

    const geometryPositions = geometry.attributes.position.values;
    const trianglePositions = [];
    for (let i = 0; i < geometryPositions.length; i += 3) {
      trianglePositions.push(new Cesium.Cartesian3(geometryPositions[i], geometryPositions[i + 1], geometryPositions[i + 2]));
    }

    const triangles = [];
    for (let i = 0; i < geometry.indices.length; i += 3) {
      const a = trianglePositions[geometry.indices[i]];
      const b = trianglePositions[geometry.indices[i + 1]];
      const c = trianglePositions[geometry.indices[i + 2]];
      const aDeg = GeoUtils.cartesian3ToDeg(a);
      const bDeg = GeoUtils.cartesian3ToDeg(b);
      const cDeg = GeoUtils.cartesian3ToDeg(c);
      const aPos = [aDeg.longitude, aDeg.latitude, aDeg.height];
      const bPos = [bDeg.longitude, bDeg.latitude, bDeg.height];
      const cPos = [cDeg.longitude, cDeg.latitude, cDeg.height];
      triangles.push(polygon([[aPos, bPos, cPos, aPos]]));
    }

    return triangles;
  }

  /** Return minimal distance between points in meters to accomodate minPointsDistanceMeters and maxPointsToSample.
   * For Turf.js calculations using cellSide and tolerance */
  static inferCellSide(feature: Feature, { maxPointsToSample, minPointsDistanceMeters } = environment.measurements) {
    const areaVal = area(feature);
    const approxNeededSamplesCount = areaVal / minPointsDistanceMeters ** 2;
    if (approxNeededSamplesCount > maxPointsToSample) {
      return Math.sqrt(areaVal / maxPointsToSample);
    }

    return minPointsDistanceMeters;
  }

  static getRightmostPosition(positions: Cartesian3[]): Cartesian3 {
    const positionsDeg = positions.map(pos => GeoUtils.cartesian3ToDeg(pos));
    const rightmostPositionDeg = GeoUtils.maxByLon(positionsDeg);
    return Cesium.Cartesian3.fromDegrees(rightmostPositionDeg.longitude, rightmostPositionDeg.latitude, rightmostPositionDeg.height);
  }

  static getLinesIntersections(line1: Cartesian3[], line2: Cartesian3[]): Cartographic[] {
    if (!isDefined(line1) || !isDefined(line2) || line1.length < 2 || line2.length < 2) {
      return;
    }
    const line1Str = lineString(
      line1.map(p => {
        const latlon = GeoUtils.cartesian3ToDeg(p);
        return [latlon.longitude, latlon.latitude];
      })
    );
    const line2Str = lineString(
      line2.map(p => {
        const latlon = GeoUtils.cartesian3ToDeg(p);
        return [latlon.longitude, latlon.latitude];
      })
    );
    const res = lineIntersect(line1Str, line2Str).features.map(f => ({
      longitude: f.geometry?.coordinates[0],
      latitude: f.geometry?.coordinates[1]
    }));
    return res;
  }

  static mergeBoundingBoxes(...bboxes: [number, number, number, number][]): [number, number, number, number] {
    const boundingBoxes = bboxes.filter(bbox => isDefined(bbox) && bbox.length === 4 && bbox.every(item => isDefined(item)));

    if (!isDefined(boundingBoxes)) {
      return;
    }

    let [west, south, east, north] = boundingBoxes[0];

    for (const [comperedWest, comperedSouth, comperedEast, comparedNorth] of boundingBoxes) {
      west = Math.min(west, comperedWest);
      south = Math.min(south, comperedSouth);
      east = Math.max(east, comperedEast);
      north = Math.max(north, comparedNorth);
    }

    return [west, south, east, north];
  }
}
