import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullscreenLoaderService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public loadingTitle: string;
  constructor() {}

  showLoading(loadingTitle?) {
    this.loadingTitle = loadingTitle;
    this.loadingSubject.next(true);
  }

  hideLoading() {
    this.loadingTitle = null;
    this.loadingSubject.next(false);
  }
}
