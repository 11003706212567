import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessLevelEnum } from '../auth/state/auth.utils';
import { ACCESS_LEVEL_TO_COUNTER_MAP, CONFIG_UNLIMITED_PLACEHOLDER, FeatureFlagEnum, User } from './tenant.model';
import { TenantState, TenantStore } from './tenant.store';

@Injectable({ providedIn: 'root' })
export class TenantQuery extends Query<TenantState> {
  config$ = this.select(state => state.config);
  unlimitedImageMpxFeatureFlag$ = this.select(state => !!state.featureFlags[FeatureFlagEnum.UNLIMITEDMPXIMG]);
  gradeCheckingFeatureFlag$ = this.select(state => !!state.featureFlags[FeatureFlagEnum.GRADECHECKING]);
  activityDetailsFeatureFlag$ = this.select(state => !!state.featureFlags[FeatureFlagEnum.ACTIVITYDETAILS]);
  canAddSite$ = this.select(
    state =>
      state.config && (state.config.max.sites === CONFIG_UNLIMITED_PLACEHOLDER || state.config.counter.sites < state.config.max.sites)
  );
  canAddUser$ = this.select(
    state =>
      state.config &&
      (state.config.max.analyticUsers === CONFIG_UNLIMITED_PLACEHOLDER ||
        state.config.counter.analyticUsers < state.config.max.analyticUsers ||
        state.config.max.basicUsers === CONFIG_UNLIMITED_PLACEHOLDER ||
        state.config.counter.basicUsers < state.config.max.basicUsers ||
        state.config.max.viewOnlyUsers === CONFIG_UNLIMITED_PLACEHOLDER ||
        state.config.counter.viewOnlyUsers < state.config.max.viewOnlyUsers ||
        state.config.max.operatorUsers === CONFIG_UNLIMITED_PLACEHOLDER ||
        state.config.counter.operatorUsers < state.config.max.operatorUsers)
  );
  canAddTask$ = this.select(
    state =>
      state.config &&
      (state.config.max.totalFlights === CONFIG_UNLIMITED_PLACEHOLDER || state.config.counter.totalFlights < state.config.max.totalFlights)
  );
  canImportModel$ = combineLatest([
    this.canAddTask$,
    this.select(state => state.config && state.config.counter.storageCapacity < state.config.max.storageCapacity)
  ]).pipe(map(([canAddTask, canAddToStorage]) => canAddTask && canAddToStorage));

  private usersQuery = new QueryEntity(this.store.users);
  users$ = this.usersQuery.selectAll();

  private sitesQuery = new QueryEntity(this.store.sites);
  sites$ = this.sitesQuery.selectAll();

  constructor(protected store: TenantStore) {
    super(store);
  }

  getAllSites() {
    return this.sitesQuery.getAll();
  }

  getGradeCheckingFeatureFlag() {
    return !!this.store.getValue().featureFlags[FeatureFlagEnum.GRADECHECKING];
  }

  getElectricPolesReportFeatureFlag() {
    return !!this.store.getValue().featureFlags[FeatureFlagEnum.ELECPOLESAI];
  }

  getMLGcpFeatureFlag() {
    return !!this.store.getValue().featureFlags[FeatureFlagEnum.MLGCP];
  }

  getActivityDetailsFeatureFlag() {
    return !!this.store.getValue().featureFlags[FeatureFlagEnum.ACTIVITYDETAILS];
  }

  getDisableCreateReportNonAdminFeatureFlag() {
    return !!this.store.getValue().featureFlags[FeatureFlagEnum.DISABLECREATEREPORTNONADMIN];
  }

  getCoordinateSystems() {
    return this.store.getValue().coordinateSystems;
  }

  getSiteTypes() {
    return this.getValue().siteTypes;
  }

  getTenantIntegrations() {
    return this.getValue().tenantIntegrations;
  }

  getIntegrationProjects() {
    return this.store.getValue().integrationProjects;
  }

  getSiteGroups() {
    return this.getValue().siteGroups;
  }

  getSite(siteId: string) {
    return this.sitesQuery.getEntity(siteId);
  }

  selectSite(siteId: string) {
    return this.sitesQuery.selectEntity(siteId);
  }

  getMaxImportModelFilesSize() {
    const defaultValue = 100 * 1024 ** 3;
    return this.store.getValue().config?.max.uploadedFilesSizeForTask ?? defaultValue;
  }

  getStorageCapacity() {
    return this.store.getValue().config?.max.storageCapacity;
  }

  getTotalRemainingStorage() {
    const config = this.store.getValue().config;
    if (config) {
      return Math.max(config.max.storageCapacity - config.counter.storageCapacity, 0);
    }
  }

  getMaxImagesPerFlight() {
    return this.store.getValue().config?.max.imagesForTask;
  }

  getTotalImagesPerTenant() {
    return this.store.getValue().config?.max.images;
  }

  getTotalRemainingImages() {
    const config = this.store.getValue().config;
    if (config) {
      return Math.max(config.max.images - config.counter.images, 0);
    }
  }

  getCanAddTask() {
    const config = this.getValue().config;
    return config && (config.max.totalFlights === CONFIG_UNLIMITED_PLACEHOLDER || config.counter.totalFlights < config.max.totalFlights);
  }

  getCanSaveUserByAccessLevelCount(newAccessLevel: AccessLevelEnum, currentAccessLevel?: AccessLevelEnum) {
    const config = this.getValue().config;
    if (!config) {
      return false;
    }

    const configCounter = { ...config.counter };
    const newAccessLevelKey = ACCESS_LEVEL_TO_COUNTER_MAP[newAccessLevel];

    if (currentAccessLevel) {
      const currentAccessLevelKey = ACCESS_LEVEL_TO_COUNTER_MAP[currentAccessLevel];

      // Allow changing to access level with same counter
      if (currentAccessLevelKey === newAccessLevelKey) {
        return true;
      }

      // Reduce current access level from counter
      configCounter[currentAccessLevelKey] -= 1;
    }

    const currentCount = configCounter[newAccessLevelKey];

    const maxCount = config.max[newAccessLevelKey];
    return maxCount === CONFIG_UNLIMITED_PLACEHOLDER || currentCount < maxCount;
  }

  getAllUsers(excludeViewOnly?: boolean) {
    const filterBy = excludeViewOnly ? (user: User) => user.accessLevel !== AccessLevelEnum.VIEWONLY : undefined;
    return this.usersQuery.getAll({ filterBy });
  }

  getAnalyticsUsersAndTenantAdmins() {
    return this.usersQuery.getAll({
      filterBy: user => [AccessLevelEnum.ANALYTICSUSER, AccessLevelEnum.TENANTADMIN].includes(user.accessLevel)
    });
  }

  getUserById(userId: string) {
    return this.usersQuery.getEntity(userId);
  }

  getUsersEmployeeTitles() {
    return this.getValue().employeeTitles;
  }
}
