import { Injectable } from '@angular/core';
import { Feature, multiPoint, MultiPoint, Point, Position } from '@turf/turf';
import { Cartesian3 } from 'angular-cesium';
import { isDefined } from '../../../shared/utils/general';
import { Cartographic, GeoUtils } from '../../../shared/utils/geo';
import { roundTo } from '../../../shared/utils/math';
import { GeoJsonProperties, PolylineCalcService, PrecalcData, TerrainSamplesValues } from '../../services/calc-services';
import { Terrain, TerrainProviderService } from '../../services/terrain-provider.service';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { MapEntity, TaskOrDesignValues } from '../../state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';

@Injectable({
  providedIn: 'root'
})
export class DistanceCalcService extends PolylineCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    private terrainSampling: TerrainSamplingService,
    private terrainProviderService: TerrainProviderService
  ) {
    super(siteQuery);
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    modelsData: { id: string; type: 'TASK' | 'DESIGN' }[],
    precalcData: PrecalcData
  ): Promise<{ calcResult: TaskOrDesignValues[]; samplePoints: Feature<MultiPoint, GeoJsonProperties> }> {
    const { cellSide, pointGridFeatureCollection } = precalcData;

    const geojsonProperties = entity?.geoJson?.features?.[0]?.properties as GeoJsonProperties;
    let terrainSamplesValues: TerrainSamplesValues[] = geojsonProperties?.terrainSamplesValues ?? [];
    let calcResult: TaskOrDesignValues[] = entity?.calcResult ?? [];
    for (let i = 0; i < modelsData.length; i++) {
      const modelData = modelsData[i];
      const modelTerrain = await this.terrainProviderService.getTerrainProvider(siteId, modelData.id, modelData.type, entity.sourceModel);

      const { horizontalDistance, slopeDistance, surfaceDistance, elevationMin, elevationMax, slope, sampleCoordinates } =
        await this.calcElevationDependentFields(entity.positions, pointGridFeatureCollection.features, modelTerrain);

      terrainSamplesValues = [
        ...terrainSamplesValues,
        {
          id: modelsData[i].id,
          type: modelsData[i].type,
          samplesValues: sampleCoordinates.map(c => (isDefined(c.height) ? roundTo(c.height) : c.height))
        }
      ];

      calcResult = [
        ...calcResult,
        {
          id: modelData.id,
          type: modelData.type,
          values: [
            { ...FIELD_MAPPING.surfaceDistance, value: surfaceDistance },
            { ...FIELD_MAPPING.slopeDistance, value: slopeDistance },
            { ...FIELD_MAPPING.horizontalDistance, value: horizontalDistance },
            { ...FIELD_MAPPING.elevationMin, value: elevationMin },
            { ...FIELD_MAPPING.elevationMax, value: elevationMax },
            { ...FIELD_MAPPING.slopePercent, value: slope }
          ]
        }
      ];
    }

    const samplePointsProps: GeoJsonProperties = { cellSide, terrainSamplesValues };
    const samplePositions: Position[] = pointGridFeatureCollection.features.map(f =>
      f.geometry.coordinates.map(value => roundTo(value, 7))
    );

    return {
      calcResult,
      samplePoints: multiPoint(samplePositions, samplePointsProps)
    };
  }

  private async calcElevationDependentFields(positions: Cartesian3[], linePoints: Feature<Point>[], modelTerrain: Terrain) {
    const cartographicPositions = await this.terrainSampling.sampleTerrain(positions, modelTerrain);

    let horizontalDistance = 0;
    let slopeDistance = 0;
    let index = 0;
    while (index < cartographicPositions.length - 1) {
      slopeDistance += GeoUtils.distance(cartographicPositions[index], cartographicPositions[index + 1]);
      horizontalDistance += GeoUtils.distance(cartographicPositions[index], cartographicPositions[index + 1], false);
      index++;
    }

    if (modelTerrain.type === 'FLAT') {
      const sampleCoordinates: Cartographic[] = linePoints.map(point => ({
        longitude: point.geometry.coordinates[0],
        latitude: point.geometry.coordinates[1],
        height: 0
      }));
      return {
        horizontalDistance,
        slopeDistance,
        surfaceDistance: horizontalDistance,
        elevationMin: 0,
        elevationMax: 0,
        slope: 0,
        sampleCoordinates
      };
    }

    // Sample each position to get accurate height
    const coordinates: Cartesian3[] = linePoints.map(point =>
      Cesium.Cartesian3.fromDegrees(point.geometry.coordinates[0], point.geometry.coordinates[1])
    );
    const sampleCoordinates = this.smoothCoordinates(
      modelTerrain.type,
      await this.terrainSampling.sampleTerrain(coordinates, modelTerrain)
    );

    const coordinatesWithHeight = sampleCoordinates.filter(c => isDefined(c.height));
    const { surfaceDistance, elevationMin, elevationMax } = this.calcSurfaceDistanceAndElevationLimits(coordinatesWithHeight);
    const slope = this.calcSlopeBetweenFirstAndLastPositions(cartographicPositions);

    return {
      horizontalDistance,
      slopeDistance,
      surfaceDistance,
      elevationMin,
      elevationMax,
      slope,
      sampleCoordinates
    };
  }

  private calcSlopeBetweenFirstAndLastPositions(cartographicPositions: Cartographic[]) {
    if (cartographicPositions.length < 2) {
      return 0;
    }

    const firstPosition = cartographicPositions[0];
    const lastPosition = cartographicPositions[cartographicPositions.length - 1];

    if (!isDefined(firstPosition.height) || !isDefined(lastPosition.height)) {
      return 0;
    }

    const firstToLastPositionDistance = GeoUtils.distance(lastPosition, firstPosition, false);
    const slope = firstToLastPositionDistance > 0 ? ((lastPosition.height - firstPosition.height) / firstToLastPositionDistance) * 100 : 0;
    return slope;
  }
}
