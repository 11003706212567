import { Component, OnInit } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { NgSelectConfig } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthQuery } from './auth/state/auth.query';
import { AuthService } from './auth/state/auth.service';
import { UserRole } from './auth/state/auth.utils';
import { NotificationsService } from './shared/navbar/notifications-menu/state/notifications.service';
import { AnalyticsService } from './shared/services/analytics.service';
import { SnackBarService } from './shared/services/snackbar.service';
import { SvgIconsService } from './shared/services/svg-icons.service';
import { TitleService } from './shared/services/title.service';
import { UserSettingsService } from './shared/services/user-settings.service';
import { ServerError } from './shared/utils/backend-services';
import { TenantService } from './tenant/tenant.service';

const EXCLUDED_ROUTES = ['version', 'login', 'changePassword', 'setNewUserPassword'];

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    private iconsLoader: SvgIconsService,
    private tenantService: TenantService,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private snackbar: SnackBarService,
    private analyticsService: AnalyticsService,
    private routerQuery: RouterQuery,
    private ngSelectConfig: NgSelectConfig,
    private userSettingsService: UserSettingsService
  ) {
    this.titleService.init();

    this.iconsLoader.loadIcons();

    this.initNgSelectConfig();
  }

  initNgSelectConfig() {
    this.ngSelectConfig.addTagText = $localize`:@@shared.selectFieldDefaultText.addTag:Add item`;
    this.ngSelectConfig.clearAllText = $localize`:@@shared.selectFieldDefaultText.clearAll:Clear all`;
    this.ngSelectConfig.loadingText = $localize`:@@shared.selectFieldDefaultText.loading:Loading...`;
    this.ngSelectConfig.notFoundText = $localize`:@@shared.selectFieldDefaultText.notFound:No items found`;
    this.ngSelectConfig.typeToSearchText = $localize`:@@shared.selectFieldDefaultText.typeToSearch:Type to search`;
  }

  ngOnInit() {
    this.analyticsService.initialize();

    // Only do this in relevant routes
    this.routerQuery
      .select(store => store?.state)
      .pipe(
        untilDestroyed(this),
        first(state => state?.url && !EXCLUDED_ROUTES.some(route => state.url.startsWith('/' + route))),
        switchMap(state => {
          if (state.data?.userRole !== UserRole.ACCOUNT_USER) {
            return of(null);
          }

          const observables: Observable<any>[] = [];

          const isReportPage = 'reportId' in state.params;
          if (!isReportPage) {
            // No need for user settings in report page (for now)
            observables.push(
              this.userSettingsService.initGlobalSettings().pipe(
                catchError(error => {
                  console.error('Error initializing global user settings', error);
                  return of(null);
                })
              )
            );
          }

          observables.push(
            this.routerQuery.selectParams<string>('tenantId').pipe(
              untilDestroyed(this),
              tap(tenantId => this.authService.setActiveTenant(tenantId)),
              switchMap(() => {
                if (isReportPage) {
                  return of(null);
                }

                return forkJoin([
                  this.tenantService.init().pipe(
                    catchError(response => {
                      if (response?.error?.code !== ServerError.TokenNotValid) {
                        this.snackbar.openError('Error fetching tenant config', response);
                      }

                      return throwError(() => response);
                    })
                  ),
                  this.notificationsService.poll().pipe(
                    takeUntil(this.authQuery.logOut$),
                    catchError(response => {
                      if (response?.error?.code !== ServerError.TokenNotValid) {
                        console.error('Error fetching notifications', response);
                      }

                      return throwError(() => response);
                    })
                  )
                ]);
              })
            )
          );

          return forkJoin(observables);
        })
      )
      .subscribe();
  }
}
