<dialog-layout (closeDialog)="close()" header="Share {{resourceType}}"  i18n-header="@@shared.shareResourceDialog.dialogTitle">
  <div class="column full-container container">
    <div class="column section">
      <div class="title" i18n="@@shared.shareResourceDialog.sendToUsersByEmailTitle">Share this {{resourceType}} with {{ appName }} users</div>
      <div class="subtitle">
        <ng-container i18n="@@shared.shareResourceDialog.sendToUsersByEmailSubtitle">Send the {{resourceType}} link via email to {{ appName }} users</ng-container>
        <ng-container *ngIf="data.showCreateUserButton" i18n="@@shared.shareResourceDialog.sendToUsersByEmailSubtitleCreateNewUsers"> or create new users and share it with them</ng-container>
      </div>
      <div class="row full-container field-container share-users-field">
        <ng-select
          class="field-select-control share-users-select"
          [items]="users"
          [(ngModel)]="selectedUsers"
          [multiple]="true"
          [closeOnSelect]="false"
          bindLabel="name"
          i18n-placeholder="@@shared.shareResourceDialog.selectUsersInputPlaceholder"
          i18n-notFoundText="@@shared.shareResourceDialog.selectUsersInputNotFoundText"
          placeholder="Select users to share with"
          notFoundText="No users found"
          dropdownPosition="bottom"
        >
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> {{ item.name }}
          </ng-template>

          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items | slice: 0:maxUsersInField">
              <span class="ng-value-label">{{ item.name }}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
            <div class="ng-value" *ngIf="items.length > maxUsersInField">
              <ng-container class="ng-value-label" i18n="@@shared.shareResourceDialog.countHowMuchMoreUsers">{{ items.length - maxUsersInField }} more...</ng-container>
            </div>
          </ng-template>

          <ng-template ng-footer-tmp *ngIf="data.showCreateUserButton">
            <button class="row full-width justify-start light-btn create-user-button" mat-button (click)="onAddNewClick()" i18n="@@shared.shareResourceDialog.createNewUserToShareWith">
              Create new user to share with
            </button>
          </ng-template>
        </ng-select>

        <button mat-flat-button class="dark-btn share-button" (click)="onShareClick()" [disabled]="disableShareButton || selectedUsers.length === 0" i18n="@@general.actionButtons.share">Share</button>
      </div>
    </div>

    <div class="column section">
      <div class="title" i18n="@@shared.shareResourceDialog.copyLinkTitle">Copy link</div>
      <div class="subtitle" i18n="@@shared.shareResourceDialog.copyLinkSubtitle">
        Copy the current {{resourceType}} link. Note that only {{ appName }} users in this account associated with this site will be able to access it.
      </div>
      <div class="row full-container field-container link-field">
        <input readonly class="field-input-control" [value]="urlToCopy" (focus)="$event.target.select()" (click)="onLinkFieldClick()" />
        <mat-icon>link</mat-icon>
      </div>
    </div>
  </div>
</dialog-layout>
