import { isDefined } from './general';

export function roundTo(n: number, accuracy: number = 2) {
  return floatToInteger(n, accuracy, 'round');
}

export function floatToInteger(n: number, accuracy: number = 2, method: 'round' | 'ceil' | 'floor' = 'round') {
  if (!isDefined(n)) {
    return n;
  }

  const multiplier = 10 ** accuracy;
  return Math[method](n * multiplier) / multiplier;
}

export const inRange = (value: number, min: number = 0, max: number = 100) => {
  if (!isDefined(value) || value <= min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return value;
  }
};

export enum OutliersType {
  OUTLIERS = 'outliers',
  FAROUT = 'farOut'
}

/** outliers or far out values calculation by method based on interquartile range */
export function findOutliersOrFarOutValues(array: number[], type: OutliersType): { lower: number[]; upper: number[] } {
  /** multiplier 1.5 indicates an "outlier", and multiplier 3 indicates data that is "far out. Wikipedia" */
  let multi = 1.5;
  switch (type) {
    case 'outliers':
      multi = 1.5;
      break;
    case 'farOut':
      multi = 3;
      break;
  }

  const resultValues: { lower: number[]; upper: number[] } = { lower: [], upper: [] };
  if (array && array.length > 0) {
    array.sort((a, b) => a - b);

    const n = array.length;

    /** 50% index */
    const middle = Math.floor(n / 2);

    /** 25% index */
    const k1 = Math.floor(middle / 2);

    /** 75% index */
    const k3 = middle + k1;

    /** splits off the lowest 25% of data from the highest 75%. Wikipedia */
    const lowerQuartile = middle % 2 === 0 ? (array[k1 - 1] + array[k1]) / 2 : array[k1 - 1];

    /** splits off the highest 25% of data from the lowest 75%. Wikipedia */
    const upperQuartile = middle % 2 === 0 ? (array[k3 - 1] + array[k3]) / 2 : array[k3 - 1];

    const delta = upperQuartile - lowerQuartile;

    const lowerLimit = lowerQuartile - multi * delta;
    const upperLimit = upperQuartile + multi * delta;

    array.forEach((value, i) => {
      if (i <= k1 && value < lowerLimit) {
        resultValues.lower.push(value);
      }
      if (i >= k3 && value > upperLimit) {
        resultValues.upper.push(value);
      }
    });
  }

  return resultValues;
}

export function avgWithoutOutliers(array: number[]) {
  const outlies = findOutliersOrFarOutValues(array, OutliersType.OUTLIERS);
  const arrayWithoutOutlies = array.filter(value => !(outlies.lower.includes(value) || outlies.upper.includes(value)));
  return arrayWithoutOutlies.reduce((prev, cur) => prev + cur) / arrayWithoutOutlies.length;
}

export function getEuclideanDistance(p1: { x: number; y: number }, p2: { x: number; y: number }) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

export function getAngleDifference(angle1: number, angle2: number) {
  // Normalize angles to the range [0, 360)
  const normalize = (angle: number) => ((angle % 360) + 360) % 360;

  // Normalize both angles
  const normAngle1 = normalize(angle1);
  const normAngle2 = normalize(angle2);

  // Calculate the difference
  let difference = Math.abs(normAngle1 - normAngle2);

  // Ensure the difference is the smallest possible angle
  if (difference > 180) {
    difference = 360 - difference;
  }

  return difference;
}

export function getValueOnLine(points: { x: number; y: number }[], x: number) {
  // Check if x is out of line range
  if (!isDefined(points) || points[0].x > x || points.at(-1).x < x) {
    return null;
  }

  for (let i = 0; i < points.length - 1; i++) {
    if (points[i].x <= x && points[i + 1].x >= x && points[i].x !== points[i + 1].x) {
      const slope = (points[i + 1].y - points[i].y) / (points[i + 1].x - points[i].x);
      return points[i].y + (x - points[i].x) * slope;
    }
  }

  return null;
}

export function findLineIntersections(points1: { x: number; y: number }[], points2: { x: number; y: number }[]) {
  const intersectionPoints: { [x: number]: { x: number; y: number } } = {};
  for (let i = 0; i < points1.length - 1; i++) {
    for (let j = 0; j < points2.length - 1; j++) {
      const intersection = findIntersection(points1[i], points1[i + 1], points2[j], points2[j + 1]);
      if (intersection) {
        intersectionPoints[intersection.x] = intersection;
      }
    }
  }

  return Object.values(intersectionPoints);
}

export function findIntersection(
  start1: { x: number; y: number },
  end1: { x: number; y: number },
  start2: { x: number; y: number },
  end2: { x: number; y: number }
) {
  // Check if none of the lines are of length 0
  if ((start1.x === end1.x && start1.y === end1.y) || (start2.x === end2.x && start2.y === end2.y)) {
    return false;
  }

  const denominator = (end2.y - start2.y) * (end1.x - start1.x) - (end2.x - start2.x) * (end1.y - start1.y);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  let ua = ((end2.x - start2.x) * (start1.y - start2.y) - (end2.y - start2.y) * (start1.x - start2.x)) / denominator;
  let ub = ((end1.x - start1.x) * (start1.y - start2.y) - (end1.y - start1.y) * (start1.x - start2.x)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  let x = start1.x + ua * (end1.x - start1.x);
  let y = start1.y + ua * (end1.y - start1.y);

  return { x, y };
}
