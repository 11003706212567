import { Injectable } from '@angular/core';
import { Feature, MultiPoint, multiPoint, Position } from '@turf/turf';
import { BaseSurface } from '../../../../generated/mms/model/baseSurface';
import { isDefined } from '../../../shared/utils/general';
import { roundTo } from '../../../shared/utils/math';
import {
  BaseSurfaceSamplesHeights,
  GeoJsonProperties,
  PolygonWithSamplesCalcService,
  PrecalcData,
  TerrainSamplesValues
} from '../../services/calc-services';
import { TerrainProviderService } from '../../services/terrain-provider.service';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import {
  BaseSurfaceType,
  CalcStatus,
  MapEntity,
  TaskOrDesignValues
} from '../../state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { DeltaElevationVizOptions, FIELD_MAPPING } from '../../state/detailed-site.utils';

@Injectable({
  providedIn: 'root'
})
export class DeltaElevationCalcService extends PolygonWithSamplesCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    protected terrainProviderService: TerrainProviderService
  ) {
    super(siteQuery, terrainSampling, terrainProviderService);
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    modelsData: { id: string; type: 'TASK' | 'DESIGN' }[],
    precalcData: PrecalcData
  ): Promise<{ calcResult: TaskOrDesignValues[]; samplePoints: Feature<MultiPoint, GeoJsonProperties>; calcStatus: CalcStatus }> {
    const { horizontalArea, pointGridFeatureCollection, cellSide, isBboxSamplePointsInsidePolygon, bboxPointsCountDimentions } =
      precalcData;

    const geojsonProperties = entity?.geoJson?.features?.[0]?.properties as GeoJsonProperties;
    let surfaceSamplesHeights: BaseSurfaceSamplesHeights[] = geojsonProperties?.surfaceSamplesHeights ?? [];
    let terrainSamplesValues: TerrainSamplesValues[] = geojsonProperties?.terrainSamplesValues ?? [];
    let calcResult: TaskOrDesignValues[] = entity.calcResult ?? [];
    if (!calcResult || calcResult.length === 0) {
      terrainSamplesValues = [];
    }

    const { baseSurfaceSamplesHeights, isNewBaseSurfaceSamplesHeights } = await this.getBaseSurfaceSamplesHeights(
      siteId,
      { id: this.siteQuery.getActiveTaskId(), type: 'TASK' },
      entity,
      pointGridFeatureCollection,
      surfaceSamplesHeights
    );
    if (entity.baseSurface.type !== BaseSurfaceType.CUSTOMELEVATION && !isDefined(baseSurfaceSamplesHeights?.heights)) {
      return {
        calcResult: null,
        samplePoints: null,
        calcStatus: CalcStatus.SURFACE_SAMPLING_ERROR
      };
    }
    if (isNewBaseSurfaceSamplesHeights) {
      surfaceSamplesHeights = [...surfaceSamplesHeights, baseSurfaceSamplesHeights];
    }

    const vizOptions: DeltaElevationVizOptions = { deltaHeightsRange: {} };
    for (let i = 0; i < modelsData.length; i++) {
      const [surfaceArea, { modelSamplesHeights, isNewModelSamplesHeights }] = await Promise.all([
        this.calcModelSurfaceArea(entity, siteId, modelsData[i]),
        this.getModelSamplesHeights(siteId, modelsData[i], entity.sourceModel, pointGridFeatureCollection, surfaceSamplesHeights)
      ]);
      if (!isDefined(modelSamplesHeights?.heights)) {
        return {
          calcResult: null,
          samplePoints: null,
          calcStatus: CalcStatus.MODEL_SAMPLING_ERROR
        };
      }
      if (isNewModelSamplesHeights) {
        surfaceSamplesHeights = [...surfaceSamplesHeights, modelSamplesHeights];
      }

      if (baseSurfaceSamplesHeights?.heights.every(h => !isDefined(h))) {
        return {
          calcResult: null,
          samplePoints: null,
          calcStatus: CalcStatus.SURFACE_BOUNDS_ERROR
        };
      }

      const isBaseSurfaceIsCurrentTask =
        entity.baseSurface?.type === modelSamplesHeights.type && baseSurfaceSamplesHeights.id === modelSamplesHeights.id;

      const elevationResults = this.getElevationData(
        modelSamplesHeights.heights,
        baseSurfaceSamplesHeights?.heights,
        isBaseSurfaceIsCurrentTask,
        entity.baseSurface
      );

      terrainSamplesValues = [
        ...terrainSamplesValues,
        {
          id: modelsData[i].id,
          type: modelsData[i].type,
          samplesValues: elevationResults.deltaElevationValues
        }
      ];

      calcResult = [
        ...calcResult,
        {
          id: modelsData[i].id,
          type: modelsData[i].type,
          values: [
            { ...FIELD_MAPPING.horizontalArea, value: horizontalArea },
            { ...FIELD_MAPPING.surfaceArea, value: surfaceArea },
            { ...FIELD_MAPPING.elevationMin, value: elevationResults.elevationMin },
            { ...FIELD_MAPPING.elevationMax, value: elevationResults.elevationMax },
            { ...FIELD_MAPPING.elevationDeltaMin, value: elevationResults.elevationDeltaMin },
            { ...FIELD_MAPPING.elevationDeltaMax, value: elevationResults.elevationDeltaMax }
          ]
        }
      ];

      vizOptions.deltaHeightsRange = {
        ...vizOptions.deltaHeightsRange,
        [modelsData[i].id]: {
          min: elevationResults.elevationDeltaMin,
          max: elevationResults.elevationDeltaMax
        }
      };
    }

    const samplePointsProps: GeoJsonProperties = {
      ...bboxPointsCountDimentions,
      cellSide,
      isBboxSamplePointsInsidePolygon,
      terrainSamplesValues,
      surfaceSamplesHeights,
      vizOptions
    };

    const samplePositions: Position[] = pointGridFeatureCollection.features.map(f => f.geometry.coordinates);
    return {
      calcResult,
      samplePoints: multiPoint(samplePositions, samplePointsProps),
      calcStatus: CalcStatus.SUCCESS
    };
  }

  private async calcModelSurfaceArea(entity: MapEntity, siteId: string, modelData: { id: string; type: 'TASK' | 'DESIGN' }) {
    const modelTerrain = await this.terrainProviderService.getTerrainProvider(siteId, modelData.id, modelData.type, entity.sourceModel);
    return await this.calcSurfaceArea(entity.positions, modelTerrain);
  }

  private getElevationData(
    modelSamplesHeights: number[],
    baseSurfaceSamplesHeights: number[],
    isBaseSurfaceIsCurrentTask: boolean,
    baseSurface: BaseSurface
  ) {
    let elevationMin = Number.MAX_SAFE_INTEGER;
    let elevationMax = Number.MIN_SAFE_INTEGER;
    let elevationDeltaMin = Number.MAX_SAFE_INTEGER;
    let elevationDeltaMax = Number.MIN_SAFE_INTEGER;
    const baseSurfaceHeigth = baseSurface.elevation;
    const pointsCount = modelSamplesHeights.length;
    const deltaElevationValues: number[] = new Array(pointsCount).fill(null);

    const getDeltaElevation = (a: number, b: number) => {
      return roundTo(a - b);
    };

    const updateElevationMin = (modelHeights: number[], index: number, baseSurfaceHeights: number[] | number) => {
      if (isDefined(modelHeights[index]) && modelHeights[index] < elevationMin) {
        elevationMin = modelHeights[index];
      }
    };

    const updateElevationMax = (modelHeights: number[], index: number, baseSurfaceHeights: number[] | number) => {
      if (isDefined(modelHeights[index]) && modelHeights[index] > elevationMax) {
        elevationMax = modelHeights[index];
      }
    };

    const updateElevationDeltaMin = (modelHeights: number[], index: number, baseSurfaceHeights: number[] | number) => {
      if (isDefined(deltaElevationValues[index]) && deltaElevationValues[index] < elevationDeltaMin) {
        elevationDeltaMin = deltaElevationValues[index];
      }
    };

    const updateElevationDeltaMax = (modelHeights: number[], index: number, baseSurfaceHeights: number[] | number) => {
      if (isDefined(deltaElevationValues[index]) && deltaElevationValues[index] > elevationDeltaMax) {
        elevationDeltaMax = deltaElevationValues[index];
      }
    };

    const updateDeltaElevationsWithBSTerrainHeights = (modelHeights: number[], index: number, baseSurfaceHeights: number[]) => {
      if (!isDefined(modelHeights[index]) || !isDefined(baseSurfaceHeights[index])) {
        deltaElevationValues[index] = 0;
        return;
      }

      const heightDiff = getDeltaElevation(modelHeights[index], baseSurfaceHeights[index]);
      deltaElevationValues[index] = heightDiff;
    };

    const updateDeltaElevationsWithBSHeight = (modelHeights: number[], index: number, baseSurfaceHeight: number) => {
      if (!isDefined(modelHeights[index]) || !isDefined(baseSurfaceHeight)) {
        deltaElevationValues[index] = 0;
        return;
      }

      const heightDiff = getDeltaElevation(modelHeights[index], baseSurfaceHeight);
      deltaElevationValues[index] = heightDiff;
    };

    const iterateSamples = (func: Function) => {
      for (let i = 0; i < pointsCount; i++) {
        func(i);
      }
    };

    let actionsList: Function[];
    const updateResData = (i: number) => actionsList.forEach(f => f(modelSamplesHeights, i, baseSurfaceSamplesHeights));
    const updateResDataWithBSHeight = (i: number) => actionsList.forEach(f => f(modelSamplesHeights, i, baseSurfaceHeigth));

    if (isBaseSurfaceIsCurrentTask) {
      // Don't calculate map viz data and delta elevation min / max when base surface is same as terrain
      actionsList = [updateElevationMin, updateElevationMax, updateElevationDeltaMin, updateElevationDeltaMax];
      iterateSamples(updateResData);
    } else {
      switch (baseSurface?.type) {
        case BaseSurfaceType.TASK:
        case BaseSurfaceType.DESIGN: {
          actionsList = [
            updateElevationMin,
            updateElevationMax,
            updateDeltaElevationsWithBSTerrainHeights,
            updateElevationDeltaMin,
            updateElevationDeltaMax
          ];
          iterateSamples(updateResData);
          break;
        }
        case BaseSurfaceType.CUSTOMELEVATION: {
          actionsList = [
            updateElevationMin,
            updateElevationMax,
            updateDeltaElevationsWithBSHeight,
            updateElevationDeltaMin,
            updateElevationDeltaMax
          ];
          iterateSamples(updateResDataWithBSHeight);
          break;
        }
        case BaseSurfaceType.MINELEVATION:
        case BaseSurfaceType.INTERPOLATED:
        default: {
          // Not supported
          break;
        }
      }
    }

    return {
      deltaElevationValues,
      elevationMin: elevationMin === Number.MAX_SAFE_INTEGER ? 0 : elevationMin,
      elevationMax: elevationMax === Number.MIN_SAFE_INTEGER ? 0 : elevationMax,
      elevationDeltaMin: elevationDeltaMin === Number.MAX_SAFE_INTEGER ? 0 : elevationDeltaMin,
      elevationDeltaMax: elevationDeltaMax === Number.MIN_SAFE_INTEGER ? 0 : elevationDeltaMax
    };
  }
}
