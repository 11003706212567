import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UploadWizardDialogData } from '../state/upload-wizard.model';
import { UploadWizardComponent } from '../upload-wizard.component';

@Injectable({
  providedIn: 'root'
})
export class WizardDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: UploadWizardDialogData) {
    return this.dialog.open<UploadWizardComponent, UploadWizardDialogData>(UploadWizardComponent, {
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
      maxHeight: '1000px',
      maxWidth: '1600px',
      width: '80vw',
      height: '95%',
      data
    });
  }
}
