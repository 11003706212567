/**
 * Datumate Resource Links Service
 * API's for Resource Links service
 *
 * OpenAPI spec version: 1.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ResourceLinksModel {
  resourceId?: string;
  resourceType?: ResourceLinksModel.ResourceTypeEnum;
}
export namespace ResourceLinksModel {
  export type ResourceTypeEnum =
    | 'ACTIVITY'
    | 'ANNOTATION'
    | 'TASK'
    | 'REPORT'
    | 'MEASUREMENT'
    | 'ANALYTIC'
    | 'MODEL_EDIT'
    | 'DESIGN'
    | 'ROAD_DESIGN';
  export const ResourceTypeEnum = {
    ACTIVITY: 'ACTIVITY' as ResourceTypeEnum,
    ANNOTATION: 'ANNOTATION' as ResourceTypeEnum,
    TASK: 'TASK' as ResourceTypeEnum,
    REPORT: 'REPORT' as ResourceTypeEnum,
    MEASUREMENT: 'MEASUREMENT' as ResourceTypeEnum,
    ANALYTIC: 'ANALYTIC' as ResourceTypeEnum,
    MODELEDIT: 'MODEL_EDIT' as ResourceTypeEnum,
    DESIGN: 'DESIGN' as ResourceTypeEnum,
    ROADDESIGN: 'ROAD_DESIGN' as ResourceTypeEnum
  };
}
