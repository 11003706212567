import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';
import { UserEmployeeTitleModel } from '../../generated/tenant/model/userEmployeeTitleModel';
import { AccessLevelEnum } from '../auth/state/auth.utils';
import { formatName } from '../shared/utils/formatting';
import {
  ACCESS_LEVEL_TO_COUNTER_MAP,
  CoordinateSystem,
  FeatureFlagEnum,
  IntegrationProject,
  Site,
  SiteGroup,
  SiteType,
  TenantConfig,
  TenantIntegration,
  User
} from './tenant.model';

export interface UsersState extends EntityState<User> {}

export interface SitesState extends EntityState<Site> {}

export interface TenantState {
  loading: boolean;
  featureFlags: { [featureFlag in FeatureFlagEnum]?: boolean };
  config: TenantConfig;
  coordinateSystems: CoordinateSystem[];
  integrationProjects: { [integration: string]: IntegrationProject[] };
  siteGroups: SiteGroup[];
  siteTypes: SiteType[];
  employeeTitles?: UserEmployeeTitleModel[];
  tenantIntegrations: TenantIntegration[];
}

export function createInitialState(): TenantState {
  return {
    loading: false,
    featureFlags: {},
    config: null,
    coordinateSystems: null,
    integrationProjects: {},
    siteGroups: null,
    siteTypes: null,
    employeeTitles: [],
    tenantIntegrations: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tenant' })
export class TenantStore extends Store<TenantState> {
  users = new EntityStore<UsersState>({}, { name: 'tenant-users' });
  sites = new EntityStore<SitesState>({}, { name: 'tenant-sites' });

  constructor() {
    super(createInitialState());
  }

  setFeatureFlags(featureFlags: { [featureFlag in FeatureFlagEnum]?: boolean }) {
    this.update(
      produce((draftState: TenantState) => {
        draftState.featureFlags = featureFlags;
      })
    );
  }

  upsertTenantConfig(config: TenantConfig) {
    this.update(
      produce((draftState: TenantState) => {
        draftState.config = config;
      })
    );
  }

  setSiteTypes(siteTypes: SiteType[]) {
    this.update(
      produce((draftState: TenantState) => {
        draftState.siteTypes = siteTypes;
      })
    );
  }

  setEmployeeTitles(employeeTitles: UserEmployeeTitleModel[]) {
    this.update(
      produce((draftState: TenantState) => {
        draftState.employeeTitles = employeeTitles;
      })
    );
  }

  setTenantIntegrations(integrations: TenantIntegration[]) {
    this.update(
      produce((draftState: TenantState) => {
        draftState.tenantIntegrations = integrations;
      })
    );
  }

  setSites(sites: Site[]) {
    this.sites.set(sites);
  }

  addSite(site: Site) {
    this.sites.add(site);

    this.updateSitesCounter();
  }

  updateSitesCounter() {
    this.update(
      produce((draftState: TenantState) => {
        if (draftState.config) {
          draftState.config.counter.sites += 1;
        }
      })
    );
  }

  updateSite(site: Site) {
    this.sites.update(site.id, site);
  }

  deleteSite(siteId: string) {
    this.sites.remove(siteId);
  }

  updateTasksCounter() {
    this.update(
      produce((draftState: TenantState) => {
        if (draftState.config) {
          draftState.config.counter.totalFlights += 1;
        }
      })
    );
  }

  updateImagesCounter(imagesCount: number) {
    this.update(
      produce((draftState: TenantState) => {
        if (draftState.config) {
          draftState.config.counter.images += imagesCount;
        }
      })
    );
  }

  setCoordinateSystems(coordinateSystems: CoordinateSystem[]) {
    this.update(
      produce((draftState: TenantState) => {
        draftState.coordinateSystems = coordinateSystems;
      })
    );
  }

  setIntegrationProjects(integration: string, projects: IntegrationProject[]) {
    this.update(
      produce((draftState: TenantState) => {
        if (projects) {
          draftState.integrationProjects[integration] = projects;
        }
      })
    );
  }

  setSiteGroups(siteGroups: SiteGroup[]) {
    this.update(
      produce((draftState: TenantState) => {
        if (siteGroups) {
          draftState.siteGroups = siteGroups;
        }
      })
    );
  }

  addSiteGroup(siteGroup: SiteGroup) {
    this.update(
      produce((draftState: TenantState) => {
        draftState.siteGroups = [...draftState.siteGroups, siteGroup];
      })
    );
  }

  setUsers(users: User[]) {
    this.users.set(users.map(this.toLocalUser));
  }

  addUser(user: User) {
    this.users.add(this.toLocalUser(user));

    this.update(
      produce((draftState: TenantState) => {
        if (draftState.config) {
          draftState.config.counter[ACCESS_LEVEL_TO_COUNTER_MAP[user.accessLevel]] += 1;
        }
      })
    );
  }

  updateUser(user: Partial<User>) {
    this.users.update(user.id, oldUser => {
      const updatedUser = this.toLocalUser(user);

      // Change counters if changed access level
      if (oldUser.accessLevel !== updatedUser.accessLevel) {
        this.update(
          produce((draftState: TenantState) => {
            if (draftState.config) {
              draftState.config.counter[ACCESS_LEVEL_TO_COUNTER_MAP[updatedUser.accessLevel]] += 1;
              draftState.config.counter[ACCESS_LEVEL_TO_COUNTER_MAP[oldUser.accessLevel]] -= 1;
            }
          })
        );
      }

      return { ...oldUser, ...updatedUser };
    });
  }

  deleteUser(user: User) {
    this.users.remove(user.id);

    this.update(
      produce((draftState: TenantState) => {
        if (draftState.config) {
          draftState.config.counter[ACCESS_LEVEL_TO_COUNTER_MAP[user.accessLevel]] -= 1;
        }
      })
    );
  }

  private toLocalUser(user: Partial<User>) {
    return { ...user, name: formatName(user) };
  }
}
