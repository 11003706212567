import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { partition } from 'lodash';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ShareResourceRequest } from '../../../generated/tenant/model/shareResourceRequest';
import { ShareResourceUsersListRequest } from '../../../generated/tenant/model/shareResourceUsersListRequest';
import { AuthQuery } from '../../auth/state/auth.query';
import { AccessLevelEnum } from '../../auth/state/auth.utils';
import { Task } from '../../detailed-site/state/detailed-site.model';
import { Site } from '../../tenant/tenant.model';
import { TenantService } from '../../tenant/tenant.service';
import { AnalyticsService } from '../services/analytics.service';
import { DeviceService } from '../services/device.service';
import { LocaleService } from '../services/locale.service';
import { SnackBarService } from '../services/snackbar.service';
import { User } from '../users-and-teams-dialog/state/users-and-teams.model';
import { UsersAndTeamsService } from '../users-and-teams-dialog/state/users-and-teams.service';
import { UserDialogService } from '../users-and-teams-dialog/user-dialog/user-dialog.service';
import { getServiceUrl } from '../utils/backend-services';
import { formatName } from '../utils/formatting';
import { isDefined } from '../utils/general';

export import ResourceType = ShareResourceRequest.TypeEnum;

export const resourceTypeTranslations = {
  [ResourceType.ANALYTIC]: $localize`:@@shared.resourceType.analytic:analytic`,
  [ResourceType.MEASUREMENT]: $localize`:@@shared.resourceType.measurement:measurement`,
  [ResourceType.ANNOTATION]: $localize`:@@shared.resourceType.annotation:annotation`,
  [ResourceType.DESIGN]: $localize`:@@shared.resourceType.design:design`,
  [ResourceType.ROAD_DESIGN]: $localize`:@@shared.resourceType.roadDesign:road design`,
  [ResourceType.MODEL_EDIT]: $localize`:@@shared.resourceType.modelEdit:model edit`,
  [ResourceType.ACTIVITY]: $localize`:@@shared.resourceType.activity:activity`,
  [ResourceType.REPORT]: $localize`:@@shared.resourceType.report:report`,
  [ResourceType.FLIGHT]: $localize`:@@shared.resourceType.flight:flight`,
  [ResourceType.SITE]: $localize`:@@shared.resourceType.site:site`
};

const MAX_USERS_IN_FIELD = 2;

export interface SharedResource {
  id: string;
  type: ResourceType;
  name: string;
  creationDate: string | Date;
  directURL?: string;
}

export interface ShareResourceDialogData extends SharedResource {
  users: User[];
  site: Site;
  task?: Task;
  showCreateUserButton?: boolean;
  createUsersCallback: (users: User[]) => void;
}

@Component({
  selector: 'share-resource-dialog',
  templateUrl: './share-resource-dialog.component.html',
  styleUrls: ['./share-resource-dialog.component.scss']
})
export class ShareResourceDialogComponent implements OnInit {
  maxUsersInField = MAX_USERS_IN_FIELD;
  appName = environment.whitelabel.appName;
  urlToCopy: string;
  resourceType: string;
  users: User[] = [];
  selectedUsers: User[] = [];
  disableShareButton = false;
  resourceURLPath: string;

  constructor(
    private dialogRef: MatDialogRef<ShareResourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShareResourceDialogData,
    private http: HttpClient,
    private authQuery: AuthQuery,
    private tenantService: TenantService,
    private usersService: UsersAndTeamsService,
    private userDialog: UserDialogService,
    private clipboard: Clipboard,
    private snackbar: SnackBarService,
    private device: DeviceService,
    private localeService: LocaleService,
    private analyticsService: AnalyticsService
  ) {
    this.users = data.users;
    this.resourceURLPath = this.generateResourcePath();
    this.urlToCopy = window.location.origin + this.resourceURLPath;
    this.resourceType = resourceTypeTranslations[data.type];
  }

  ngOnInit() {}

  onAddNewClick() {
    const dialogRef = this.userDialog.openDialog({
      initialValues: {
        accessLevel: AccessLevelEnum.VIEWONLY,
        sites: [this.data.site]
      }
    });
    dialogRef.afterClosed().subscribe(newUser => {
      if (newUser) {
        this.users = [...this.users, newUser];
        this.selectedUsers = [...this.selectedUsers, newUser];
      }
    });
  }

  onShareClick() {
    this.disableShareButton = true;

    const site = this.data.site;
    const [existingSelectedUsers, newUsers] = partition(this.selectedUsers, user => !!user.id);

    // Save unsaved users (without id), then send a share resource request for all
    const newUsersWithCreationErrors: User[] = [];
    let createUsersRequests: Observable<User[]> = of([]);
    if (newUsers.length > 0) {
      createUsersRequests = forkJoin(
        newUsers.map(newUser => {
          // Add site to user if not in his sites list
          if (!newUser.sites?.some(s => s.id === site.id)) {
            newUser.sites = [...(newUser.sites || []), { id: site.id, name: site.id }];
          }

          return this.usersService.createUser(newUser, false).pipe(
            catchError(error => {
              newUsersWithCreationErrors.push(newUser);
              console.error('Error creating user ' + newUser.name, error);
              return of(newUser);
            })
          );
        })
      );
    }

    // Update site with users not in it that were shared with this resource
    const existingUsersWithUpdateErrors: User[] = [];
    let updateSiteUsersRequest: Observable<Site> = of(null);
    if (existingSelectedUsers.length > 0) {
      const siteUsersIds = site.users.map(user => user.id);
      const usersNotInSite = existingSelectedUsers.filter(user => !siteUsersIds.includes(user.id));
      if (usersNotInSite.length > 0) {
        updateSiteUsersRequest = this.tenantService.updateSite(site.id, { ...site, users: [...site.users, ...usersNotInSite] }).pipe(
          tap(() => this.data.createUsersCallback?.(usersNotInSite)),
          catchError(error => {
            existingUsersWithUpdateErrors.push(...usersNotInSite);
            console.error(
              'Error inserting the following users to associated site users: ' + usersNotInSite.map(user => user.name).join(', '),
              error
            );
            return of(null);
          })
        );
      }
    }

    const extractUserFields = (user: User) =>
      ({
        id: user.id,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email
      } as ShareResourceUsersListRequest);

    const currentUser = this.authQuery.getActiveUserResponse();

    forkJoin([createUsersRequests, updateSiteUsersRequest])
      .pipe(
        switchMap(([newSavedUsers]) => {
          const newUsers = newSavedUsers
            .filter(user => !!user.id && !newUsersWithCreationErrors.some(userWithError => user.email === userWithError.email))
            .map(extractUserFields);
          const existingUsers = existingSelectedUsers
            .filter(user => !existingUsersWithUpdateErrors.some(userWithError => user.id === userWithError.id))
            .map(extractUserFields);

          let missionFlightDate = null;
          if (this.data.type === ResourceType.MEASUREMENT) {
            missionFlightDate = this.data.task.flightDateLabel;
          } else if (this.data.type === ResourceType.FLIGHT) {
            missionFlightDate = this.data.name;
          }

          if (isDefined(this.data.creationDate)) {
            this.data.creationDate = this.localeService.formatDateNumeral({ date: this.data.creationDate });
          }
          const request: ShareResourceRequest = {
            newUsers,
            existingUsers,
            link: encodeURIComponent(this.resourceURLPath),
            missionFlightDate,
            senderFirstName: currentUser.firstName || '',
            senderLastName: currentUser.lastName || '',
            siteName: site.name,
            creationDate: this.data.creationDate as string,
            entityName: this.data.name,
            type: ShareResourceRequest.TypeEnum[this.data.type.toUpperCase()]
          };

          // Don't call API if no users to share with
          if (existingUsers.length === 0 && newUsers.length === 0) {
            return of(request);
          }

          return this.http.put(`${getServiceUrl('tenant')}/sites/sendResourceLink`, request).pipe(map(() => request));
        })
      )
      .subscribe({
        next: (request: ShareResourceRequest) => {
          // Construct text for errors, if any
          let errorsText = '';
          if (newUsersWithCreationErrors.length > 0) {
            errorsText += `- Error creating following user${newUsersWithCreationErrors.length > 1 ? 's' : ''}: ${newUsersWithCreationErrors
              .map(user => formatName(user))
              .join(', ')}.`;
          }
          if (existingUsersWithUpdateErrors.length > 0) {
            if (errorsText) {
              errorsText += '\n';
            }
            errorsText += `- Error adding following user${
              existingUsersWithUpdateErrors.length > 1 ? 's' : ''
            } to site: ${existingUsersWithUpdateErrors.map(user => formatName(user)).join(', ')}.`;
          }

          // Show appropriate message for the user and close dialog
          let snackbarText: string;
          if (request.existingUsers.length > 0 || request.newUsers.length > 0) {
            snackbarText = $localize`:@@shared.shareResourceDialog.resourceLinkShared:${this.resourceType} link shared`;
            if (errorsText) {
              snackbarText += ' with some errors:\n' + errorsText;
            }

            this.analyticsService.shareResourceLink({
              link: request.link,
              siteName: request.siteName,
              flightDateLabel: request.missionFlightDate,
              existingUsersEmails: request.existingUsers.map(user => user.email),
              newUsersEmails: request.newUsers.map(user => user.email),
              type: ShareResourceRequest.TypeEnum[this.data.type.toUpperCase()]
            });
          } else {
            snackbarText = $localize`:@@shared.shareResourceDialog.errorSharingResourceLink:Error sharing ${this.resourceType} link`;
            if (errorsText) {
              snackbarText += '\n' + errorsText;
            }
          }

          this.snackbar.open(snackbarText, { panelClass: ['multiline-snack-bar', 'capitalized-snack-bar'] });
          this.close();
        },
        error: error => {
          this.snackbar.openError(
            $localize`:@@shared.shareResourceDialog.errorSharingResourceLink:Error sharing ${this.resourceType} link`,
            `Error sharing ${this.resourceType} link`,
            error
          );
        }
      });
  }

  onLinkFieldClick() {
    this.clipboard.copy(this.urlToCopy);
    this.snackbar.open($localize`:@@shared.shareResourceDialog.resourceLinkCopied:${this.resourceType} link copied`, {
      panelClass: 'capitalized-snack-bar'
    });
  }

  close() {
    this.dialogRef.close();
  }

  get isDesktop() {
    return this.device.isDesktop();
  }

  private generateResourcePath() {
    if (isDefined(this.data.directURL)) {
      return this.data.directURL;
    }

    const { id, type } = this.data;
    return `${window.location.pathname}?resourceId=${id}&resourceType=${type.toLowerCase()}`;
  }
}
