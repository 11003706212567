import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Cartesian3 } from 'angular-cesium';
import { countBy, flatten, groupBy } from 'lodash';
import moment from 'moment';
import { from, interval, of, throwError } from 'rxjs';
import { catchError, finalize, map, retry, switchMap, tap } from 'rxjs/operators';
import { SelectedImagesResponse } from '../../../generated/file/model/selectedImagesResponse';
import { UpdateImagesWarningsRequest } from '../../../generated/file/model/updateImagesWarningsRequest';
import { CreateTaskRequest } from '../../../generated/fms/model/createTaskRequest';
import { GetArtifactResponse } from '../../../generated/fms/model/getArtifactResponse';
import { GetS3PathAndCredentialsResponse } from '../../../generated/fms/model/getS3PathAndCredentialsResponse';
import { TaskIdResponse } from '../../../generated/fms/model/taskIdResponse';
import { UpdateTaskRequest } from '../../../generated/fms/model/updateTaskRequest';
import { UpdateTaskResponse } from '../../../generated/fms/model/updateTaskResponse';
import { UpdateUploadedTaskRequest } from '../../../generated/fms/model/updateUploadedTaskRequest';
import { AuthQuery } from '../../auth/state/auth.query';
import { AuthService } from '../../auth/state/auth.service';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../auth/state/auth.utils';
import PERMISSIONS from '../../auth/state/permissions';
import { PROGRESS_STATES, ShutterTypeEnum, Task, TaskStateEnum } from '../../detailed-site/state/detailed-site.model';
import { DroppedFile } from '../../shared/drag-and-drop-container/drag-and-drop-container.component';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { ExifService } from '../../shared/services/exif.service';
import { OfflineBackup } from '../../shared/services/offline-backup';
import { S3FilesUploadService, UploadState } from '../../shared/services/s3-files-upload.service';
import { LocaleService } from '../../shared/services/locale.service';
import { SnackBarService } from '../../shared/services/snackbar.service';
import { getServiceUrl } from '../../shared/utils/backend-services';
import { isDefined } from '../../shared/utils/general';
import { Cartographic, GeoUtils } from '../../shared/utils/geo';
import { Site } from '../../tenant/tenant.model';
import { TenantService } from '../../tenant/tenant.service';
import { GcpService } from '../gcp-marking/state/gcp.service';
import { LoadingModalTypeEnum } from '../gcp-marking/state/gcp.store';
import { Step } from '../wizard-stepper/steps';
import { FileValidationUtils, ImageValidationUtils, getFileName } from './image-validation';
import {
  AUTOMODEL_GEOREF_METHODS,
  CAMERA_MODEL_SHUTTER_TYPES,
  DEFAULT_IMAGE_MAX_RESOLUTION_PX,
  FileToUpload,
  GeorefMethodEnum,
  IMAGES_BACKUP_NAME,
  IMAGE_RESOLUTION_BASELINE_MPX,
  ImagePoint,
  ImageToApprove,
  PerImagesValidationWarning,
  RTKStatus,
  TotalValidationWarning,
  UploadWizardCallbacks,
  UploadWizardMode,
  ValidationError,
  ValidationLimits
} from './upload-wizard.model';
import { UploadWizardQuery, sortAllFilesToUpload, sortAllImagesToApprove, splitFileName } from './upload-wizard.query';
import { UploadWizardStore } from './upload-wizard.store';

export const VALID_FILE_SUFFIXES = ['.jpg', '.jpeg'];
const REFRESH_TOKEN_INTERVAL = 15 * 60 * 1000; // 15 minutes

const AVG_DISTANCE_METERS_DEFAULT = 20;
const IMAGES_NUMBER_FOR_AVERAGE_CALC = 5;
const UPPER_DISTANCE_LIMIT_MULTIPLIER = 3;
const LOWER_DISTANCE_LIMIT_MULTIPLIER = 0.1;

@Injectable({ providedIn: 'root' })
export class UploadWizardService {
  private wizardCallbacks: UploadWizardCallbacks = {};

  constructor(
    private uploadStore: UploadWizardStore,
    private gcpService: GcpService,
    private http: HttpClient,
    private router: Router,
    private exifService: ExifService,
    private uploadS3Service: S3FilesUploadService,
    private uploadQuery: UploadWizardQuery,
    private localeService: LocaleService,
    private authService: AuthService,
    private authQuery: AuthQuery,
    private tenantService: TenantService,
    private analyticsService: AnalyticsService,
    private snackbar: SnackBarService
  ) {}

  async addFilesToUpload(newFiles: DroppedFile[]) {
    const newFilesToUpload = await Promise.all(
      newFiles.map(async ({ file, path }) => {
        const originalName = getFileName(path);
        return {
          name: originalName,
          file,
          exif: await this.exifService.parse(file),
          rtkStatus: RTKStatus.NONE,
          error: [],
          warnings: [],
          originalName
        } as FileToUpload;
      })
    );

    const newFilesToUploadUpdNames = this.checkDuplicatesAndUpdateNames(newFilesToUpload.sort(sortAllFilesToUpload));

    this.uploadStore.upsertFilesToUpload(newFilesToUploadUpdNames);
    const filesToUpload = this.uploadQuery.getAllFilesToUpload().sort(sortAllFilesToUpload);
    await this.validateFiles(filesToUpload);
  }

  private checkDuplicatesAndUpdateNames(newFiles: FileToUpload[]) {
    const skippedFiles: FileToUpload[] = [];
    const checkedFiles: FileToUpload[] = [];

    let groupedByNameFiles = groupBy(this.uploadQuery.getAllFilesToUpload(), 'originalName');

    newFiles.forEach(checkFile => {
      // Check if file name exists in one of the file name groups, if so - change it's name to be the group name
      // This is done to take care of a case where an image with name "X_01" is added after two images with name "X" are added
      for (const [groupFileName, groupFiles] of Object.entries(groupedByNameFiles)) {
        if (groupFiles.some(f => f.name === checkFile.originalName)) {
          checkFile.originalName = groupFileName;
          break;
        }
      }

      const originalName = checkFile.originalName;

      // the name of new file already exists or uploaded
      if (groupedByNameFiles[originalName] && groupedByNameFiles[originalName].length > 0) {
        const imagesWithSameOriginalName = groupedByNameFiles[originalName];

        // the new file's data (lon, lat, date) already exist or uploaded
        const isSameDataExist = imagesWithSameOriginalName.some(
          f =>
            checkFile.exif.date === f.exif?.date &&
            checkFile.exif.location?.latitude === f.exif?.location?.latitude &&
            checkFile.exif.location?.longitude === f.exif?.location?.longitude
        );
        if (isSameDataExist) {
          skippedFiles.push(checkFile);
        } else {
          const allNumSuffixes = imagesWithSameOriginalName
            .map(f => {
              if (f.name !== originalName) {
                const lastUnderscoreIndex = f.name.lastIndexOf('_');
                if (lastUnderscoreIndex > 0) {
                  return parseInt(f.name.slice(lastUnderscoreIndex + 1));
                }
              }

              return 0;
            })
            .sort();

          // Find the first available suffix
          let availableSuffix = 0;
          for (let i = 0; i < allNumSuffixes.length; i++) {
            // Found gap in suffix numbers and there's no image with original name with that suffix
            if (allNumSuffixes[i] !== availableSuffix && !(this.generateImageName(originalName, availableSuffix) in groupedByNameFiles)) {
              break;
            }

            availableSuffix++;
          }

          let name = this.generateImageName(originalName, availableSuffix);

          // Make sure the generated name doesn't already exist as a different image name
          // Can only happen in the case that checkFile's name is added a suffix greater than the rest, because we already check this in the previous loop
          if (name in groupedByNameFiles) {
            name = this.generateImageName(originalName, availableSuffix + 1);
          }

          const fileWithUpdatedName = new File([checkFile.file], name, { type: checkFile.file.type });
          const updatedFile = {
            ...checkFile,
            name,
            file: fileWithUpdatedName
          };
          groupedByNameFiles[originalName].push(updatedFile);
          checkedFiles.push(updatedFile);
        }
      } else {
        // no duplicates, save without changes
        groupedByNameFiles[originalName] = [checkFile];
        checkedFiles.push(checkFile);
      }
    });

    const skippedFilesCount = skippedFiles.length;
    if (skippedFilesCount > 0) {
      this.snackbar.open(`${skippedFilesCount} duplicate file${skippedFilesCount > 1 ? 's were' : ' was'} skipped`);
    }

    return checkedFiles;
  }

  private generateImageName(originalName: string, suffixNum: number) {
    const suffix = suffixNum === 0 ? '' : '_' + String(suffixNum).padStart(2, '0');
    const { clearName, extension } = splitFileName(originalName);
    return `${clearName}${suffix}.${extension}`;
  }

  deleteItemAndRevalidatePrevNeighbour(name: string) {
    const mode = this.uploadQuery.getMode();
    if (mode === UploadWizardMode.CREATE) {
      const currentFiles = this.uploadQuery.getAllFilesToUpload().sort(sortAllFilesToUpload);

      const fileForDelete = this.uploadQuery.getFileToUploadByName(name);
      const indexForDelete = currentFiles.indexOf(fileForDelete);

      const prevFile = currentFiles[indexForDelete - 1] ?? null;
      const nextFile = currentFiles[indexForDelete + 1] ?? null;

      this.deleteItemByName(name);

      if (nextFile) {
        this.revalidateFile(nextFile, prevFile);
      }

      this.checkImagesRTKStatus(this.uploadQuery.getAllFilesToUpload());
      this.updateTotalIssues();
    } else {
      const currentImages = this.uploadQuery.getAllImagesToApprove().sort(sortAllImagesToApprove);

      const imageForDelete = this.uploadQuery.getImageToApproveByName(name);
      const indexForDelete = currentImages.indexOf(imageForDelete);

      const prevImage = currentImages[indexForDelete - 1] ?? null;
      const nextImage = currentImages[indexForDelete + 1] ?? null;

      this.deleteItemByName(name);

      if (nextImage) {
        this.revalidateImage(nextImage, prevImage);
      }

      this.checkImagesRTKStatus(this.uploadQuery.getAllImagesToApprove());
      this.updateTotalIssues();
    }
  }

  private checkImagesRTKStatus(images: { rtkStatus?: RTKStatus }[]) {
    let hasAllValidRTKImages = true;
    let hasRTKImages = false;
    for (let image of images) {
      if (image.rtkStatus !== RTKStatus.GOOD) {
        hasAllValidRTKImages = false;
      }
      if (image.rtkStatus !== RTKStatus.NONE) {
        hasRTKImages = true;
      }

      // We got results for all the checks, don't need to check the rest of the images
      if (!hasAllValidRTKImages && hasRTKImages) {
        break;
      }
    }

    this.uploadStore.updateHasAllValidRTKImages(hasAllValidRTKImages);

    if (!hasRTKImages) {
      // Only one status type and it's none - no RTK images
      this.uploadStore.removeAllFileWarningsOfType(PerImagesValidationWarning.INVALIDRTKDATA);
    }
  }

  private deleteItemByName(name: string) {
    const mode = this.uploadQuery.getMode();
    if (mode === UploadWizardMode.CREATE) {
      this.uploadStore.deleteFileByName(name);
    } else {
      this.uploadStore.deleteImageByName(name);
    }
    if (name === this.uploadQuery.getActiveName()) {
      this.updateActiveHoveredNames();
    }
  }

  updateActiveImageName(name = '') {
    this.uploadStore.updateActiveImageName(name);
  }

  updateMapHoveredNames({ image = '', issue = '', noncoverageWarning = '' } = {}) {
    this.uploadStore.updateMapHoveredNames({ image, issue, noncoverageWarning });
  }

  updateActiveHoveredNames({ activeImage = '', image = '', issue = '', noncoverageWarning = '' } = {}) {
    this.uploadStore.updateActiveHoveredNames({ activeImage, image, issue, noncoverageWarning });
  }

  resetFiles() {
    this.uploadStore.resetFiles();
    this.updateActiveHoveredNames();
  }

  updateSite(updatedSite: Partial<Site>) {
    return this.tenantService.updateSite(this.uploadQuery.getSiteId(), updatedSite).pipe(
      tap(site => {
        this.uploadStore.setSite(site);
        this.wizardCallbacks.updateSite?.(site);
      })
    );
  }

  fetchTaskImages(taskId: string, isLinked: boolean) {
    return this.wizardCallbacks
      .fetchTaskImages(taskId, isLinked)
      .pipe(tap(resp => this.uploadStore.upsertTaskImagesToApprove(resp.images)));
  }

  updateSiteLocation() {
    const filesToUpload = this.uploadQuery.getFilteredFilesToUpload();
    if (!filesToUpload || filesToUpload.length === 0) {
      return;
    }

    const locations = filesToUpload.map(f => f.exif.location).filter(loc => !!loc);
    if (locations.length === 0) {
      return of(null);
    }

    const center = GeoUtils.centerOfPositions(locations.map(l => [l.longitude, l.latitude]));
    return this.updateSite({ longitude: center[0], latitude: center[1] });
  }

  updateTask(updatedTask: UpdateTaskRequest, resetGeorefMethod?: boolean) {
    const task = this.uploadQuery.getTask();
    const taskData: UpdateTaskRequest = { ...task, ...updatedTask };
    return this.http
      .put<UpdateTaskResponse>(
        `${getServiceUrl('fms')}/tasks/${task.id}`,
        { ...taskData, resetGeorefMethod },
        {
          headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flights.update }
        }
      )
      .pipe(
        tap(response => {
          let updatedTask: Task = { ...taskData, state: response.taskState, flightDateLabel: task.flightDateLabel };
          if (response.taskState in PROGRESS_STATES) {
            updatedTask = { ...updatedTask, progress: 0 };
          }
          this.setTask(updatedTask, resetGeorefMethod);
          this.wizardCallbacks.updateTask?.(updatedTask);
        })
      );
  }

  updateTaskProgressOrStateLocally(updatedTask: Partial<Task>) {
    const task = this.uploadQuery.getTask();
    this.setTask({ ...task, ...updatedTask });
    this.wizardCallbacks.updateTask?.({ ...task, ...updatedTask });
  }

  approveTask(taskData: UpdateUploadedTaskRequest) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.approveOperatorImages } };
    const taskId = this.uploadQuery.getTaskId();

    const imagesToApprove = this.uploadQuery.getFilteredImagesToApprove();
    const selectedImages: SelectedImagesResponse = { imagesName: imagesToApprove.map(image => image.name) };

    return this.http.put(`${getServiceUrl('file')}/images/${taskId}/updateSelectedImages`, selectedImages, options).pipe(
      switchMap(() => {
        const imagesWarnings: UpdateImagesWarningsRequest = {
          updateImageWarningsRequests: imagesToApprove
            .map(image => ({
              imageId: image.id,
              warnings: image.warnings
            }))
            .filter(data => data.warnings && data.warnings.length > 0)
        };
        return this.http.put(`${getServiceUrl('file')}/images/${taskId}/updateImagesWarnings`, imagesWarnings, options).pipe(
          switchMap(() =>
            this.http
              .put(
                `${getServiceUrl('fms')}/tasks/${taskId}/approveTask`,
                { ...taskData, selectedImagesCount: imagesToApprove.length },
                options
              )
              .pipe(
                tap(() => {
                  const task = this.uploadQuery.getTask();
                  this.wizardCallbacks.updateTask({ id: taskId, state: TaskStateEnum.LINKING, progress: 0 });
                  this.analyticsService.approveTask({ ...task, ...taskData });
                })
              )
          )
        );
      })
    );
  }

  createTask(task: CreateTaskRequest) {
    const filesToUpload = this.uploadQuery.getFilteredFilesToUpload();
    const files = filesToUpload.map(f => f.file);

    return this.http
      .get(`${getServiceUrl('fms')}/artifacts`, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flights.create } })
      .pipe(
        switchMap((artifactsResponse: GetArtifactResponse) => {
          const artifactsIds = artifactsResponse.artifacts.map(artifact => ({ id: artifact.id }));

          const { avgFlightSpeed, shutterType } = this.uploadQuery.getAvgFlightSpeedAndShutterType();
          const taskToCreate: CreateTaskRequest = {
            ...task,
            avgFlightSpeed,
            shutterType,
            imagesSize: files.reduce((previousValue, currentValue) => previousValue + currentValue.size, 0), // size in bytes
            imagesCount: files.length,
            artifacts: artifactsIds,
            warnings: this.uploadQuery.getTotalValidationWarnings()
          };

          const url = `${getServiceUrl('fms')}/tasks`;
          return this.http.post(url, taskToCreate, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flights.create } }).pipe(
            map((taskResponse: TaskIdResponse) => taskResponse.id),
            tap((taskId: string) => {
              const newTask: Task = {
                ...taskToCreate,
                id: taskId,
                creationTime: new Date(),
                flightDateLabel: this.localeService.formatDateName({ date: task.missionFlightDate }),
                state: TaskStateEnum.LINKING,
                progress: 0
              };
              this.setTask(newTask);
              this.setTaskId(taskId);
            })
          );
        })
      );
  }

  uploadTaskImages(taskId: string, fromBackup = false) {
    this.setUploadingLoading(true);
    this.setUploadingError(false);

    // Refresh the token at an interval to make sure token isn't invalid in middle of upload
    const refreshAccessTokenSub = interval(REFRESH_TOKEN_INTERVAL)
      .pipe(
        switchMap(() => this.authService.refreshAllAccessTokens()),
        retry(3)
      )
      .subscribe();

    const url = `${getServiceUrl('fms')}/tasks/${taskId}/getS3PathAndCredentials`;
    return this.http.get(url, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.images.create } }).pipe(
      switchMap((s3CredentialsResponse: GetS3PathAndCredentialsResponse) => {
        if (fromBackup) {
          return from(OfflineBackup.getBackup(IMAGES_BACKUP_NAME)).pipe(
            switchMap(backup => backup?.getMetadata()),
            switchMap(metadata => {
              if (!metadata) {
                return of(null);
              }

              const currentUserId = this.authQuery.getUserId();
              if (metadata.userId !== currentUserId) {
                return of(null);
              }

              return this.uploadS3Service.resumeUploadFromBackup({
                backupName: IMAGES_BACKUP_NAME,
                credentials: s3CredentialsResponse,
                filesWarningsMap: metadata.filesWarningsMap
              });
            })
          );
        } else {
          const siteId = this.uploadQuery.getSiteId();

          const filesToUpload = this.uploadQuery.getFilteredFilesToUpload();
          const files = filesToUpload.map(f => {
            const file = f.file;
            // Trim file name to remove spaces
            return new File([file], file.name.trim(), { type: file.type });
          });

          const filesWarningsMap: Record<string, string[]> = filesToUpload.reduce((map, f) => {
            if (f.warnings && f.warnings.length > 0) {
              map[f.name] = f.warnings;
            }
            return map;
          }, {});

          return this.uploadS3Service.uploadFiles({
            credentials: s3CredentialsResponse,
            files,
            filesWarningsMap,
            backupName: IMAGES_BACKUP_NAME,
            backupMetadata: { siteId, taskId }
          });
        }
      }),
      tap(result => this.setUploadResult(result)),
      catchError(error => {
        this.setUploadingLoading(false);
        refreshAccessTokenSub.unsubscribe();

        // Don't output error if upload was aborted by user
        if (error.code === 'RequestAbortedError') {
          this.abortUpload(true);
          return of(null);
        }

        this.setUploadingError(true);
        this.abortUpload(false);
        const msg = 'Error while uploading files';
        console.error(msg, error);
        this.addUploadTaskImagesErrorToAnalytics(msg);
        return throwError(() => error);
      }),
      finalize(() => {
        refreshAccessTokenSub.unsubscribe();

        // If error or aborted by user - don't do anything
        if (this.uploadQuery.getIsUploadingError() || !this.uploadQuery.getTask()) {
          return;
        }

        this.uploadComplete();
        this.addUploadImagesEventToAnalytics(fromBackup);
        const imagesCount = this.uploadQuery.getFilteredFilesToUpload().length;
        this.tenantService.updateImagesCounter(imagesCount);
        this.tenantService.updateTasksCounter();
        this.setUploadingLoading(false);
        this.preprocessModel();
        if (this.uploadQuery.getIsAutomodel()) {
          this.addGenerateTaskEventToAnalytics();
        }
      })
    );
  }

  makeTaskActive() {
    const tenantId = this.authQuery.getActiveTenantId();
    const siteId = this.uploadQuery.getSiteId();
    const taskId = this.uploadQuery.getTaskId();
    this.router.navigate([tenantId, 'sites', siteId, 'tasks', taskId], { replaceUrl: true });
  }

  abortUpload(byUser: boolean) {
    this.uploadS3Service.abortUpload(byUser);
    this.uploadStore.resetUpload();
    if (byUser) {
      this.uploadStore.setTask(null);
    }
  }

  setStep(step: Step) {
    this.uploadStore.setStep(step);
  }

  setMode(mode: UploadWizardMode) {
    this.uploadStore.setMode(mode);
  }

  nextStep() {
    this.uploadStore.nextStep();
  }

  setAutomodel(georefMethod: GeorefMethodEnum) {
    const isAutomodel = AUTOMODEL_GEOREF_METHODS.includes(georefMethod);

    this.uploadStore.setAutomodel(isAutomodel);
  }

  setFirstFlight(isFirstFlight: boolean) {
    this.uploadStore.setFirstFlight(isFirstFlight);
  }

  resetStore() {
    this.uploadStore.reset();
    this.gcpService.resetStore();
  }

  setBoundingPolygon(positions: Cartesian3[]) {
    const polygon = positions.map(cartesian => GeoUtils.cartesian3ToDeg(cartesian));
    this.uploadStore.setBoundingPolygon(polygon);
  }

  setUploadingError(hasError: boolean) {
    this.uploadStore.setUploadingError(hasError);
  }

  setUploadingLoading(loading: boolean) {
    this.uploadStore.setUploadingLoading(loading);
  }

  setUploadResult(uploadResult: UploadState) {
    this.uploadStore.setUploadResult(uploadResult);
  }

  uploadComplete() {
    // Notify server that upload is finished
    const task = this.uploadQuery.getTask();
    this.http
      .put(`${getServiceUrl('fms')}/tasks/${task.id}/completeUploadImages`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flights.create }
      })
      .subscribe({
        next: () => this.wizardCallbacks.addTask?.(task),
        error: error => {
          const msg = 'Error finishing flight images upload';
          console.error(msg, error);
        }
      });
  }

  setSite(site: Site) {
    this.uploadStore.setSite(site);
  }

  setTaskId(taskId: string) {
    this.uploadStore.setTaskId(taskId);
  }

  setTask(task: Task, force = false) {
    const currentTask = this.uploadQuery.getTask();
    if (!force && task && currentTask) {
      // Only allow updating task states & progress, to not interfer with wizard fields while user is editing
      this.uploadStore.setTask({
        ...currentTask,
        state: task.state,
        progress: task.progress,
        mlgcpState: task.mlgcpState
      });
    } else {
      this.uploadStore.setTask(task);
      if (task) {
        this.setAutomodel(task.georefMethod);

        // When auto georef fails we allow the user to mark GCPs, and the flow stopps being automodel
        if (task.state === TaskStateEnum.FAILEDAUTOGEOREF) {
          this.uploadStore.setAutomodel(false);
        }
      }
    }
  }

  setCallbacks(services: UploadWizardCallbacks) {
    this.wizardCallbacks = services || {};
  }

  preprocessModel() {
    this.updateTaskProgressOrStateLocally({ state: TaskStateEnum.LINKING, progress: 0 });
  }

  calculateAccuracy() {
    this.updateTaskProgressOrStateLocally({ state: TaskStateEnum.SBAINPROGRESS, progress: 0 });
  }

  generateModel() {
    const task = this.uploadQuery.getTask();
    return this.http
      .post(`${getServiceUrl('fms')}/tasks/${task.id}/generateModels`, null, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.generateModel }
      })
      .pipe(
        tap(() => {
          this.updateTaskProgressOrStateLocally({ state: TaskStateEnum.GENERATING3DMODELS, progress: 0 });
          this.addGenerateTaskEventToAnalytics();
        })
      );
  }

  pollMLGcpMarking() {
    this.gcpService.setLoadingModalType(LoadingModalTypeEnum.AI_MARKING);
    return this.gcpService.pollMLGcpMarking().pipe(
      finalize(() => {
        this.gcpService.setLoadingModalType(null);
      })
    );
  }

  private addGenerateTaskEventToAnalytics() {
    const task = this.uploadQuery.getTask();
    this.analyticsService.generateTask(task);
  }

  private addUploadImagesEventToAnalytics(fromBackup: boolean) {
    const task = this.uploadQuery.getTask();
    this.analyticsService.uploadTaskImages(task, fromBackup);
  }

  addUploadTaskImagesErrorToAnalytics(error: string) {
    const task = this.uploadQuery.getTask();
    this.analyticsService.uploadTaskImagesError(task, error);
  }

  async validateFiles(files: FileToUpload[]) {
    const filesWithExif = files.filter(file => file.exif);
    this.updateFilesLimits(filesWithExif);

    const limits = this.uploadQuery.getValidationLimits();

    let hasAllValidRTKImages = true;
    let hasRTKImages = false;
    const allIssuesPerFiles = await Promise.all(
      files.map(async (file, i) => {
        const rtkStatus = FileValidationUtils.getRTKStatus(file);
        if (rtkStatus !== RTKStatus.GOOD) {
          hasAllValidRTKImages = false;
        }
        if (rtkStatus !== RTKStatus.NONE) {
          hasRTKImages = true;
        }

        const prevFile = files[i - 1] || null;
        const issues = await FileValidationUtils.calculateIssues(file, prevFile, limits);
        return { ...issues, rtkStatus };
      })
    );

    allIssuesPerFiles.forEach((issues, i) => {
      const nearestNoncoverageAreaCenter = this.calculateNoncoverageAreaCenter(
        issues.warnings,
        this.uploadQuery.getImagePointByName(files[i]?.name),
        this.uploadQuery.getImagePointByName(files[i - 1]?.name)
      );

      if (hasRTKImages && issues.rtkStatus !== RTKStatus.GOOD) {
        issues.warnings.push(PerImagesValidationWarning.INVALIDRTKDATA);
      }

      files[i] = {
        ...files[i],
        rtkStatus: issues.rtkStatus,
        errors: issues.errors,
        warnings: issues.warnings,
        nearestNoncoverageAreaCenter
      };
    });

    this.uploadStore.updateHasAllValidRTKImages(hasAllValidRTKImages);

    this.uploadStore.upsertFilesToUpload(files);
    this.updateTotalIssues();
  }

  calcAvgFlightSpeedAndShutterType(files: FileToUpload[]) {
    if (!isDefined(files) || files.length < 2) {
      this.uploadStore.updateAvgFlightSpeedAndShutterType(null, null);
      return;
    }

    let distance = 0;
    let time = 0;

    files.slice(0, -1).forEach((file, i) => {
      const nextFile = files[i + 1];
      distance += GeoUtils.distance(file.exif.location, nextFile.exif.location, false);
      time += moment(nextFile.exif.date).diff(file.exif.date, 'seconds', true);
    });

    const avgFlightSpeed = distance / time;

    const exif = files[0].exif;
    const exifShutterType = exif.shutterType?.toUpperCase();
    const shutterType = exifShutterType in ShutterTypeEnum ? ShutterTypeEnum[exifShutterType] : CAMERA_MODEL_SHUTTER_TYPES[exif.camModel];

    this.uploadStore.updateAvgFlightSpeedAndShutterType(avgFlightSpeed, shutterType);
  }

  async updateImagesValidationData(images: ImageToApprove[]) {
    this.updateImagesLimits(images);

    images.forEach((image, i) => {
      const nearestNoncoverageAreaCenter = this.calculateNoncoverageAreaCenter(
        image.warnings,
        this.uploadQuery.getImagePointByName(images[i]?.name),
        this.uploadQuery.getImagePointByName(images[i - 1]?.name)
      );
      if (nearestNoncoverageAreaCenter) {
        this.uploadStore.setImageNearestNoncoverageAreaCenterByName(image.name, nearestNoncoverageAreaCenter);
      }
    });
  }

  private revalidateFile(checkedFile: FileToUpload, prevFile: FileToUpload) {
    const limits = this.uploadQuery.getValidationLimits();

    FileValidationUtils.calculateIssues(checkedFile, prevFile, limits).then(updCheckedFileIssues => {
      const nearestNoncoverageAreaCenter = this.calculateNoncoverageAreaCenter(
        updCheckedFileIssues.warnings,
        this.uploadQuery.getImagePointByName(checkedFile?.name),
        this.uploadQuery.getImagePointByName(prevFile?.name)
      );

      this.setWarningsByName(checkedFile.name, updCheckedFileIssues.warnings, nearestNoncoverageAreaCenter);
      this.setErrorsByName(checkedFile.name, updCheckedFileIssues.errors);
    });
  }

  private revalidateImage(checkedImage: ImageToApprove, prevImage: ImageToApprove) {
    const limits = this.uploadQuery.getValidationLimits();
    ImageValidationUtils.calculateWarnings(checkedImage, prevImage, limits).then(updCheckedImageWarnings => {
      const nearestNoncoverageAreaCenter = this.calculateNoncoverageAreaCenter(
        updCheckedImageWarnings,
        this.uploadQuery.getImagePointByName(checkedImage?.name),
        this.uploadQuery.getImagePointByName(prevImage?.name)
      );

      this.setWarningsByName(checkedImage.name, updCheckedImageWarnings, nearestNoncoverageAreaCenter);
    });
  }

  private setErrorsByName(name: string, errors: ValidationError[]) {
    const mode = this.uploadQuery.getMode();
    if (mode === UploadWizardMode.CREATE) {
      this.uploadStore.setFileErrorsByName(name, errors);
    }
  }

  private setWarningsByName(
    name: string,
    warnings: PerImagesValidationWarning[],
    nearestNoncoverageAreaCenter: { longitude: number; latitude: number }
  ) {
    const mode = this.uploadQuery.getMode();
    if (mode === UploadWizardMode.CREATE) {
      this.uploadStore.setFileWarningsByName(name, warnings, nearestNoncoverageAreaCenter);
    } else {
      this.uploadStore.setImageWarningsByName(name, warnings, nearestNoncoverageAreaCenter);
    }
  }

  private updateTotalIssues() {
    const mode = this.uploadQuery.getMode();
    if (mode === UploadWizardMode.CREATE) {
      const filesToUpload = this.uploadQuery.getFilteredFilesToUpload();
      this.calculateAndUpdateTotalErrors(filesToUpload.map(file => file.errors));
      this.calculateAndUpdateTotalWarnings(filesToUpload.map(file => file.warnings));
    } else {
      const imagesToApprove = this.uploadQuery.getAllImagesToApprove();
      this.calculateAndUpdateTotalWarnings(imagesToApprove.map(image => image.warnings));
    }
  }

  private calculateAndUpdateTotalErrors(imagesErrors: ValidationError[][]) {
    const countedErrors = this.countIssues(imagesErrors);

    if (!this.isTotalGpxValid()) {
      countedErrors[ValidationError.EXCEEDEDMAXGPX] = 1;
    }

    this.uploadStore.setCountedTotalErrors(countedErrors);
  }

  private isTotalGpxValid() {
    const maxTotalResolutionGpx = this.uploadQuery.getValidationLimits().maxTotalResolutionGpx;
    const filesWithExif = this.uploadQuery.getFilesWithExifToUpload();
    if (filesWithExif && filesWithExif.length > 0) {
      const imagesCount = filesWithExif.length;
      const imagesResolution = filesWithExif[0].exif.resolution.width * filesWithExif[0].exif.resolution.height;
      const totalResolutionGpx = (imagesCount * imagesResolution) / 10 ** 9;
      return totalResolutionGpx < maxTotalResolutionGpx;
    } else {
      return true;
    }
  }

  calculateAndUpdateTotalWarnings(imagesWarnings: PerImagesValidationWarning[][]) {
    const countedTotalWarnings = this.countIssues(imagesWarnings);

    const mode = this.uploadQuery.getMode();
    if (mode === UploadWizardMode.CREATE && !this.isTotalTimeValid()) {
      countedTotalWarnings[TotalValidationWarning.TOTALTIMEDIFFERENCE] = 1;
    }

    this.uploadStore.setCountedTotalWarnings(countedTotalWarnings);
  }

  private isTotalTimeValid() {
    const totalTimeLimitHour = this.uploadQuery.getValidationLimits().totalTimeHour;
    const filesWithExif = this.uploadQuery.getFilesWithExifToUpload();
    if (filesWithExif && filesWithExif.length > 1) {
      const firstFile = filesWithExif[0];
      const lastFile = filesWithExif[filesWithExif.length - 1];
      const totalTime = FileValidationUtils.twoFilesTimeDelta(lastFile, firstFile);
      return totalTime < totalTimeLimitHour;
    } else {
      return true;
    }
  }

  private calculateNoncoverageAreaCenter(imgWarnings: PerImagesValidationWarning[], checkedPoint: ImagePoint, comparedPoint: ImagePoint) {
    let noncoverageAreaCenter: Cartographic = null;

    if (comparedPoint && imgWarnings.includes(PerImagesValidationWarning.MISSINGCOVERAGE)) {
      const checkedLonLat = [checkedPoint.longitude, checkedPoint.latitude];
      const comparedLonLat = [comparedPoint.longitude, comparedPoint.latitude];
      noncoverageAreaCenter = GeoUtils.midpoint(checkedLonLat, comparedLonLat);
    }

    return noncoverageAreaCenter;
  }

  updateFeatureFlagLimits({ isUnlimitedImageMpx }: { isUnlimitedImageMpx: boolean }) {
    this.uploadStore.updateValidationLimits({
      maxResolutionPx: isUnlimitedImageMpx ? Number.MAX_SAFE_INTEGER : DEFAULT_IMAGE_MAX_RESOLUTION_PX
    });
  }

  updatePackageLimits({ maxTotalImagesForTask }: { maxTotalImagesForTask: number }) {
    if (isDefined(maxTotalImagesForTask)) {
      const maxTotalResolutionGpx = Math.ceil((maxTotalImagesForTask * IMAGE_RESOLUTION_BASELINE_MPX) / 1000);
      this.uploadStore.updateValidationLimits({
        maxTotalResolutionGpx
      });
    }
  }

  private updateFilesLimits(files: FileToUpload[]) {
    let updatedLimits: Partial<ValidationLimits> = {};

    const avgDistanceWithoutOutliers =
      files.length > IMAGES_NUMBER_FOR_AVERAGE_CALC
        ? FileValidationUtils.avgSequenceDistanceWithoutOutliers(files)
        : AVG_DISTANCE_METERS_DEFAULT;

    if (avgDistanceWithoutOutliers) {
      updatedLimits = {
        ...updatedLimits,
        lowerDistanceMeter: avgDistanceWithoutOutliers * LOWER_DISTANCE_LIMIT_MULTIPLIER,
        upperDistanceMeter: avgDistanceWithoutOutliers * UPPER_DISTANCE_LIMIT_MULTIPLIER
      };
    }

    this.uploadStore.updateValidationLimits(updatedLimits);
  }

  private updateImagesLimits(images: ImageToApprove[]) {
    let updatedLimits: Partial<ValidationLimits> = {};

    const avgDistanceWithoutOutliers =
      images.length > IMAGES_NUMBER_FOR_AVERAGE_CALC
        ? ImageValidationUtils.avgSequenceDistanceWithoutOutliers(images)
        : AVG_DISTANCE_METERS_DEFAULT;

    if (avgDistanceWithoutOutliers) {
      updatedLimits = {
        ...updatedLimits,
        lowerDistanceMeter: avgDistanceWithoutOutliers * LOWER_DISTANCE_LIMIT_MULTIPLIER,
        upperDistanceMeter: avgDistanceWithoutOutliers * UPPER_DISTANCE_LIMIT_MULTIPLIER
      };
    }

    this.uploadStore.updateValidationLimits(updatedLimits);
  }

  private countIssues(issuesForCount: (ValidationError | PerImagesValidationWarning)[][]) {
    const nonEmptyIssues = issuesForCount?.filter(issues => issues && issues.length > 0);
    const issuesArray = flatten(nonEmptyIssues);
    return this.excludeZeroValues(countBy(issuesArray));
  }

  private excludeZeroValues(originalObject) {
    return Object.entries(originalObject).reduce((obj, [warning, count]) => {
      if (count !== 0) {
        obj[warning] = count;
      }
      return obj;
    }, {});
  }
}
