import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { DeviceService } from '../../services/device.service';

interface ListItem {
  id: string;
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'name-list',
  templateUrl: './name-list.component.html',
  styleUrls: ['./name-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NameListComponent),
      multi: true
    }
  ]
})
export class NameListComponent<T extends ListItem> implements OnInit, ControlValueAccessor {
  @ViewChild('selectFieldElement') selectFieldElement: NgSelectComponent;

  @Output() addNewClick = new EventEmitter();

  @Input() title: string;
  @Input() subtitle: string;
  @Input() placeholder: string;
  @Input() associateButtonCaption!: string;
  @Input() addNewButtonCaption?: string;

  private selectedItems: T[] = [];
  @Input() set value(value: T[]) {
    this.writeValue(value);
    this.onChange?.(value);
    this.onTouched?.();
  }
  get value() {
    return this.selectedItems;
  }

  filteredSelectedItems$ = new BehaviorSubject<T[]>([]);

  private optionItems: T[] = [];
  @Input() set items(items: T[]) {
    this.optionItems = items;
  }
  get items() {
    return this.optionItems;
  }

  disabled: boolean;
  addItemOpened: boolean;
  searchField = new FormControl<string>('');
  selectField = new FormControl<T>(null);
  itemsCompareFn = (i1: T, i2: T) => i1.id === i2.id;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private device: DeviceService) {}

  ngOnInit() {
    this.searchField.valueChanges.pipe(untilDestroyed(this)).subscribe(this.onSearch);
  }

  writeValue(items: T[]) {
    this.selectedItems = items || [];
    this.filteredSelectedItems$.next(items);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onSearch = (searchText: string) => {
    const searchTerm = searchText.trim().toLowerCase();
    if (searchTerm) {
      const filteredItems = this.selectedItems?.filter(item => item.name?.toLowerCase().includes(searchTerm));
      this.filteredSelectedItems$.next(filteredItems);
    } else {
      this.filteredSelectedItems$.next(this.selectedItems);
    }
  };

  onSelectChange = () => {
    // Clear select value on change to not show selected in select label
    this.selectField.patchValue(null);
  };

  onSelectAllClick = () => {
    this.value = this.isAllSelected ? [] : [...this.items];
  };

  onOptionClick = (item: T) => {
    if (this.isSelected(item)) {
      this.unselectItem(item);
    } else {
      this.selectItem(item);
    }
  };

  private selectItem = (item: T) => {
    this.value = [...this.value, item];
  };

  private unselectItem = (item: T) => {
    this.value = this.value.filter(i => i.id !== item.id);
  };

  searchFieldKeyEvent = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      // Don't close modal
      event.stopPropagation();

      // Blur search input
      (event.target as HTMLElement).blur();

      // Clear search input
      this.searchField.patchValue('');
    }
  };

  selectFieldKeyEvent = (event: KeyboardEvent) => {
    // Surpress keyboard handlers on esc to not close modal
    if (event.key === 'Escape') {
      this.closeAddItem(event);
      return false;
    }

    return true;
  };

  clickAddItem() {
    this.addItemOpened = true;
    this.clearSearch();

    // Focus on the select box in next tick and open it
    setTimeout(() => {
      this.selectFieldElement.focus();
      this.selectFieldElement.open();
    }, 0);
  }

  closeAddItem(event: Event) {
    event.stopImmediatePropagation();

    this.selectFieldElement.close();
    this.selectFieldElement.blur();
  }

  clickRemoveSelectedItem(item: T) {
    this.unselectItem(item);

    this.filteredSelectedItems$.next(this.filteredSelectedItems$.value.filter(i => i.id !== item.id));
  }

  clearSearch() {
    this.searchField.patchValue('');
  }

  clickAddNew(event: MouseEvent) {
    this.closeAddItem(event);
    this.addNewClick.emit();
  }

  isSelected(item: T) {
    return this.value?.some(i => item.id === i.id);
  }

  get isAllSelected() {
    return this.value && this.items && this.value.length === this.items.length;
  }

  get isDesktop() {
    return this.device.isDesktop();
  }
}
