import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isDefined } from '../utils/general';

export interface ConfirmationDialogData {
  title: string;
  /** Can be an HTML string. <b> will be marked red and <i> as warning. */
  content?: string;
  noCaption?: string;
  yesCaption?: string;
  alternativeButtonCaption?: string;
  disableClose?: boolean;
  beforeClosedValidation?: (result: ConfirmationDialogResultEnum) => void;
}

export enum ConfirmationDialogResultEnum {
  NO,
  YES,
  ALTERNATIVE
}

@Component({
  selector: 'confirmation-dialog',
  template: `
    <button
      mat-icon-button
      (click)="close(ConfirmationDialogResultEnum.NO)"
      class="close-button"
      *ngIf="!data.disableClose"
      [disabled]="loading"
    >
      <mat-icon>close</mat-icon>
    </button>
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content [innerHTML]="data.content"></div>
    <div mat-dialog-actions>
      <button
        *ngIf="data.alternativeButtonCaption"
        mat-stroked-button
        class="light-btn alternative-button"
        (click)="close(ConfirmationDialogResultEnum.ALTERNATIVE)"
      >
        {{ data.alternativeButtonCaption }}
      </button>
      <button
        *ngIf="data.noCaption"
        mat-stroked-button
        class="light-btn"
        (click)="close(ConfirmationDialogResultEnum.NO)"
        [disabled]="loading"
      >
        {{ data.noCaption }}
      </button>
      <button
        *ngIf="data.yesCaption"
        mat-flat-button
        class="dark-btn"
        (click)="close(ConfirmationDialogResultEnum.YES)"
        [class.disable-click]="loading"
      >
        <mat-spinner *ngIf="loading; else buttonCaption" diameter="18"></mat-spinner>
        <ng-template #buttonCaption>{{ data.yesCaption }}</ng-template>
      </button>
    </div>
  `,
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  ConfirmationDialogResultEnum = ConfirmationDialogResultEnum;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {
    if (!this.data.disableClose) {
      this.dialogRef.backdropClick().subscribe(() => this.close());
      this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          this.close();
        }
      });
    }
  }

  loading = false;

  ngOnInit() {}

  close(result: ConfirmationDialogResultEnum = ConfirmationDialogResultEnum.NO) {
    if (isDefined(this.data.beforeClosedValidation)) {
      if (result === ConfirmationDialogResultEnum.YES) {
        this.loading = true;
      }
      this.data.beforeClosedValidation(result);
    } else {
      this.dialogRef.close(result);
    }
  }
}
