import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import moment from 'moment';
import { isDefined } from '../utils/general';
import { roundTo } from '../utils/math';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    moment.locale(this.locale);

    // Fix moment issue with some locales
    const momentLocaleConfig = moment.localeData();
    momentLocaleConfig.longDateFormat('lll');
    momentLocaleConfig.longDateFormat('ll');
    momentLocaleConfig.longDateFormat('l');
  }

  formatNumber(n: number, options?: Intl.NumberFormatOptions) {
    if (!isDefined(n)) {
      return '';
    }

    return n.toLocaleString(this.locale, options);
  }

  formatAndRoundNumber(n: number, accuracy = 2, showFullFraction = true) {
    if (!isDefined(n)) {
      return '';
    }

    return this.formatNumber(roundTo(n, accuracy), { minimumFractionDigits: showFullFraction ? accuracy : undefined });
  }

  getDateFormat() {
    return moment.localeData().longDateFormat('L');
  }

  formatDateName({ date, withTime }: { date: Date | string; withTime?: boolean }) {
    return moment(date).format(withTime ? 'lll' : 'll');
  }

  formatDateNumeral({ date, shortYear, withTime }: { date: Date | string; shortYear?: boolean; withTime?: boolean }) {
    if (!isDefined(date)) {
      return null;
    }
    const d = moment(date);
    const dateFormat = shortYear ? this.getDateFormat().replace('YYYY', 'YY') : 'L';
    const result = d.format(dateFormat);
    const time = moment.utc(date).local().format('LT');
    return withTime ? `${result} ${time}` : result;
  }

  formatShortDate({ date }: { date: Date | string }) {
    const now = moment();
    const d = moment(date);
    if (d.isAfter(now.subtract(2, 'days'))) {
      return d.calendar();
    } else if (d.isAfter(now.startOf('year'))) {
      const dateFormat = this.getDateFormat().replace('YYYY', '');
      let result = d.format(dateFormat);
      // after replace 'YYYY' to '' dateFormat contains one unnecessary delimeter symbol in the begin or in the end
      // should be deleted
      const firstSymbolToNum = parseInt(result[0]);
      const isFirstSymbolNum = !!firstSymbolToNum || firstSymbolToNum === 0;
      result = !isFirstSymbolNum ? result.substr(1, 5) : result.substr(0, 5);
      return `${result} ${d.format('LT')}`;
    } else {
      return `${d.format('L')} ${d.format('LT')}`;
    }
  }
}
