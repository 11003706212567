import { GetImageResponse } from '../../../generated/file/model/getImageResponse';
import { TaskResponse } from '../../../generated/fms/model/taskResponse';
import { GetMeasuredPointModelResponse } from '../../../generated/mms/model/getMeasuredPointModelResponse';
import { GetMeasuredPointsResponse } from '../../../generated/mms/model/getMeasuredPointsResponse';
import { Design, RoadDesign } from './detailed-site-designs/detailed-site-designs.model';

export enum MapTextureType {
  FLAT = 'FLAT',
  TERRAIN = 'TERRAIN',
  SURFACE = 'SURFACE',
  TILES_3D = 'TILES_3D',
  POINT_CLOUD = 'POINT_CLOUD'
}

export type TaskOrDesign = Task | Design | RoadDesign;

export type Image = GetImageResponse;

export enum GradeCheckingInstanceTypeEnum {
  MEASURED_POINTS = 'MEASURED_POINTS',
  FLIGHT = 'FLIGHT'
}

export type MeasuredPoint = GetMeasuredPointModelResponse;
export type MeasuredPoints = GetMeasuredPointsResponse;

export interface MeasuredCrossSectionPoint {
  x: number;
  y: number;
}

export interface MeasuredCrossSection {
  startPoint: MeasuredCrossSectionPoint;
  endPoint: MeasuredCrossSectionPoint;
  station: string;
  run: string;
}

export interface Task extends TaskResponse {
  images?: Image[];
  flightDateLabel?: string;
  progress?: number;
}

export import GeorefMethodEnum = TaskResponse.GeorefMethodEnum;
export const GEOREF_METHODS_WITH_GCP = {
  [GeorefMethodEnum.MANUAL]: GeorefMethodEnum.MANUAL,
  [GeorefMethodEnum.AUTOGEOREF]: GeorefMethodEnum.AUTOGEOREF,
  [GeorefMethodEnum.GCP]: GeorefMethodEnum.GCP,
  [GeorefMethodEnum.RTKGCP]: GeorefMethodEnum.RTKGCP,
  [GeorefMethodEnum.MLGCP]: GeorefMethodEnum.MLGCP,
  [GeorefMethodEnum.MLRTKGCP]: GeorefMethodEnum.MLRTKGCP
};

export import TextureStateEnum = TaskResponse.TextureStateEnum;
export import MlGcpStateEnum = TaskResponse.MlgcpStateEnum;
export import FlightSourceEnum = TaskResponse.FlightSourceEnum;

export import TaskStateEnum = TaskResponse.StateEnum;
export import ShutterTypeEnum = TaskResponse.ShutterTypeEnum;

export const FAIL_STATES = {
  [TaskStateEnum.FAILEDLINK]: TaskStateEnum.FAILEDLINK,
  [TaskStateEnum.FAILEDAUTOGEOREF]: TaskStateEnum.FAILEDAUTOGEOREF,
  [TaskStateEnum.FAILEDDPC]: TaskStateEnum.FAILEDDPC,
  [TaskStateEnum.FAILEDSBA]: TaskStateEnum.FAILEDSBA
};
export const DONE_STATES = {
  [TaskStateEnum.SUCCESS]: TaskStateEnum.SUCCESS,
  [TaskStateEnum.WAITINGFORGCP]: TaskStateEnum.WAITINGFORGCP,
  [TaskStateEnum.UPLOADEDBYOPERATOR]: TaskStateEnum.UPLOADEDBYOPERATOR,
  ...FAIL_STATES
};
export const EDITABLE_STATES = {
  [TaskStateEnum.SUCCESS]: TaskStateEnum.SUCCESS,
  [TaskStateEnum.WAITINGFORGCP]: TaskStateEnum.WAITINGFORGCP,
  [TaskStateEnum.UPLOADEDBYOPERATOR]: TaskStateEnum.UPLOADEDBYOPERATOR,
  [TaskStateEnum.FAILEDAUTOGEOREF]: TaskStateEnum.FAILEDAUTOGEOREF,
  [TaskStateEnum.FAILEDDPC]: TaskStateEnum.FAILEDDPC,
  [TaskStateEnum.FAILEDSBA]: TaskStateEnum.FAILEDSBA
};
export const OPERATOR_STATES = {
  [TaskStateEnum.UPLOADINGBYOPERATOR]: TaskStateEnum.UPLOADINGBYOPERATOR,
  [TaskStateEnum.UPLOADEDBYOPERATOR]: TaskStateEnum.UPLOADEDBYOPERATOR
};
export const PROGRESS_STATES = {
  [TaskStateEnum.LINKING]: TaskStateEnum.LINKING,
  [TaskStateEnum.AUTOGCPINPROGRESS]: TaskStateEnum.AUTOGCPINPROGRESS,
  [TaskStateEnum.SBAINPROGRESS]: TaskStateEnum.SBAINPROGRESS,
  [TaskStateEnum.GENERATING3DMODELS]: TaskStateEnum.GENERATING3DMODELS,
  [TaskStateEnum.MLGCPINPROGRESS]: TaskStateEnum.MLGCPINPROGRESS
};

export enum OverlaysEnum {
  CONTOUR = 'CONTOUR',
  ELEVATION = 'ELEVATION',
  GCPS = 'GCPS'
}

export enum SurfaceReferenceType {
  BASE = 'Base Surface',
  TARGET = 'Target Surface'
}

export enum TabEnum {
  OVERVIEW = 'Overview',
  ANALYTICS = 'Analytics',
  MEASUREMENTS = 'Measurements',
  ANNOTATIONS = 'Annotations',
  DESIGNS = 'Designs',
  MODEL_EDITS = 'Model edits',
  REPORTS = 'Reports',
  ACTIVITIES = 'Tracked activities'
}
