// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageInfo from '../../package.json';
import { Environment } from './environment.type';

/* eslint-disable max-len */
export const environment: Environment = {
  production: false,
  version: packageInfo.version,
  serverUrl: 'https://devbimapi.datumate.com',
  lambdaGatewayUrl: 'https://devlambda.datumate.com',
  webSocketUrl: 'wss://devws.datumate.com',
  ssoLoginUrl: 'https://belibudjrj.execute-api.us-east-1.amazonaws.com/Dev/authorize',
  mapbox: {
    key: 'pk.eyJ1Ijoicm9ueWRhdHVtYXRlIiwiYSI6ImNqeG4zZGFpZDA5eTYzbW12eHNmamExYjQifQ.8VW5pLcFDwDXYZfy5Shpng',
    username: 'ronydatumate',
    streetMapStyleId: 'ck0gfyzv7071c1cs7gnqp86g1',
    satelliteMapStyleId: 'ck3sic8g1038c1cpb7bj531bg'
  },
  mapTiler: {
    key: 'MqTqvMPFzNCcTLLlDKa6',
    streetMapStyleId: 'streets-v2',
    satelliteMapStyleId: 'hybrid'
  },
  measurements: {
    maxPointsToSample: 100_000,
    minPointsDistanceMeters: 0.02
  },
  whitelabel: {
    label: 'Datumate',
    appName: 'DatuBIM - DEV',
    supportEmail: 'support@datumate.com',
    primaryColor: '#FF8000',
    loaderType: 'folding-cube',
    poweredByURLLoginPage: 'https://www.datumate.com/',
    navbarLogo: 'assets/navbar-logo-white.png',
    termsOfServiceUrl: 'assets/static/terms_of_service.html',
    tutorialVideosUrl: 'https://www.datumate.com/support/',
    supportUrl: 'mailto:support@datumate.com',
    knowledgeBaseUrl: 'https://support.datumate.com/portal/kb',
    aboutUrl: 'https://www.datumate.com/products/datubim/'
  },
  mixpanelToken: '8b1543715116a87ae7ea36b3a7302d0b' // DatuBIM (DEV)
  // mixpanelToken: 'f0a5b66e5fb8e7581077e2b3d1d30417' // Olga's tests
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
