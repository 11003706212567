import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'dialog-tab',
  template: `
    <div class="tab-content">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./dialog-tab.component.scss']
})
export class DialogTabComponent implements OnInit {
  @Input() caption: string;
  @Input() disabled = false;
  @Input() error = false;

  @Input() set active(isActive: boolean) {
    this.hideContent = !isActive;
  }
  get active() {
    return !this.hideContent;
  }
  @HostBinding('class.hidden') hideContent = true;

  constructor() {}

  ngOnInit() {}
}
