import { isDefined } from '../../../shared/utils/general';
import { convertToMeters, DistanceUnitsEnum } from '../../../shared/utils/unit-conversion';

type FeatureType = 'LABEL' | 'SYMBOL' | 'BRUSH' | 'PEN' | 'BRUSH;PEN' | 'PEN;BRUSH';

export interface FeatureStyle {
  type: FeatureType;
  outlineColor?: string;
  fillColor?: string;
  backgroundColor?: string;
  width?: number;
  pattern?: number[];
  text?: string;
  textAnchor?: { horizontal: 'left' | 'right' | 'center'; vertical: 'alphabetic' | 'middle' | 'top' | 'bottom' };
  textStretch?: number;
  textOffset?: { x: number; y: number };
  font?: string;
  fontSize?: number;
  angle?: number;
}

export function parseOGRStyle(ogrStyle: string, meterToPixelRatio: number = null, units = DistanceUnitsEnum.METER): FeatureStyle {
  // https://gdal.org/user/ogr_feature_style.html
  let ogrSplitStyle;
  if (ogrStyle.startsWith('LABEL')) {
    // label can have ";" as text
    ogrSplitStyle = [ogrStyle];
  } else {
    // handle cases like: "BRUSH(fc:#0000FF);PEN(c:#000000)"
    ogrSplitStyle = ogrStyle.split(';');
  }

  const parsedStyles = ogrSplitStyle
    .map(singleStyle => {
      const splintIndex = singleStyle.indexOf('(');
      const styleStr = singleStyle.substring(splintIndex + 1, singleStyle.length - 1);
      const type = singleStyle.substring(0, splintIndex) as FeatureType;
      return [type, styleStr];
    })
    .map(([type, styleStr]) => {
      if (!['LABEL', 'SYMBOL', 'BRUSH', 'PEN', 'BRUSH;PEN'].includes(type)) {
        console.warn('GeoJSON parse error: Unknown ogr style type ' + type);
        return [type, {}];
      }

      const regex = /(\w+):(?:"((?:\\.|[^"\\])*)"|([^,]+))/g;
      const style = {};

      let match: RegExpExecArray;
      while ((match = regex.exec(styleStr)) !== null) {
        const key = match[1].trim();
        const value = (isDefined(match[2]) ? match[2] : match[3]) || '';

        style[key] = value.trim();
      }

      return [type, style];
    });

  const mergedStyle = parsedStyles
    .map(([, style]) => style)
    .reduce((previousValue, currentValue) => {
      return {
        ...previousValue,
        ...currentValue
      };
    }, {});

  const type = parsedStyles.map(([type]) => type).join(';') as FeatureType;
  return {
    type,
    outlineColor: type === 'LABEL' ? mergedStyle.o : mergedStyle.c,
    fillColor: type === 'LABEL' ? mergedStyle.c : mergedStyle.fc,
    backgroundColor: type === 'LABEL' ? mergedStyle.b : mergedStyle.fc,
    width: type !== 'LABEL' ? parseSize(mergedStyle.w, meterToPixelRatio, units) : undefined,
    textStretch: type === 'LABEL' ? (mergedStyle.w ? +mergedStyle.w / 100 : 1) : undefined,
    pattern: type !== 'LABEL' ? parsePattern(mergedStyle.p?.replace(/"/g, ''), meterToPixelRatio, units) : undefined,
    textAnchor: type === 'LABEL' ? parseAnchor(mergedStyle.p) : undefined,
    textOffset: type === 'LABEL' ? parseOffset(mergedStyle.dx, mergedStyle.dy, meterToPixelRatio, units) : undefined,
    text: removeErrorLetters(mergedStyle.t?.replace(/"/g, '')),
    font: mergedStyle.f ? mergedStyle.f.replace(/"/g, '') + ', sans-serif' : undefined,
    fontSize: parseSize(mergedStyle.s, meterToPixelRatio, units),
    angle: +mergedStyle.a || 0
  };
}

function removeErrorLetters(s: string) {
  if (!s) {
    return s;
  }

  return s
    .split('')
    .filter(c => c.charCodeAt(0) !== 173)
    .join('');
}

function parseSize(sizeStr: string, meterToPixelRatio: number, units: DistanceUnitsEnum) {
  if (!sizeStr) {
    return null;
  }

  // If it's a pure number - return it as is
  if (!isNaN(+sizeStr)) {
    return +sizeStr;
  }

  let result: number;
  if (sizeStr.endsWith('px')) {
    result = +sizeStr.slice(0, sizeStr.length - 2);
  } else if (meterToPixelRatio !== null) {
    // Support units other than px only if meterToPixelRatio is supplied
    if (sizeStr.endsWith('g')) {
      // g: Map Ground Units (whatever the map coordinate units are)
      result = convertToMeters(+sizeStr.slice(0, sizeStr.length - 1), units);
      result *= meterToPixelRatio;
    } else if (sizeStr.endsWith('cm')) {
      // cm: Centimeters
      result = +sizeStr.slice(0, sizeStr.length - 2);
      result *= meterToPixelRatio / 100;
    } else if (sizeStr.endsWith('mm')) {
      // mm: Millimeters
      result = +sizeStr.slice(0, sizeStr.length - 2);
      result *= meterToPixelRatio / 1000;
    } else if (sizeStr.endsWith('in')) {
      // in: Inches
      result = +sizeStr.slice(0, sizeStr.length - 2);
      result *= meterToPixelRatio / 39.3701;
    } else if (sizeStr.endsWith('pt')) {
      // pt: Points (1/72 inch)
      result = +sizeStr.slice(0, sizeStr.length - 2);
      result *= meterToPixelRatio / (39.3701 / 72);
    } else {
      result = 0;
    }
  }

  return isNaN(result) ? null : result;
}

function parsePattern(patternStr: string, meterToPixelRatio: number, units: DistanceUnitsEnum) {
  if (!patternStr) {
    return null;
  }

  return patternStr.split(' ').map(segment => parseSize(segment, meterToPixelRatio, units));
}

function parseAnchor(anchorId: number) {
  if (anchorId === undefined || anchorId === null) {
    return undefined;
  }

  let horizontal: 'left' | 'right' | 'center';
  if (anchorId % 3 === 1) {
    horizontal = 'left';
  } else if (anchorId % 3 === 2) {
    horizontal = 'center';
  } else {
    horizontal = 'right';
  }

  let vertical: 'alphabetic' | 'middle' | 'top' | 'bottom';
  if (anchorId <= 3) {
    vertical = 'alphabetic';
  } else if (anchorId <= 6) {
    vertical = 'middle';
  } else if (anchorId <= 9) {
    vertical = 'top';
  } else {
    vertical = 'bottom';
  }

  return { horizontal, vertical };
}

function parseOffset(dx: string, dy: string, meterToPixelRatio: number, units: DistanceUnitsEnum) {
  if (!dx && !dy) {
    return null;
  }

  return {
    x: dx ? parseSize(dx, meterToPixelRatio, units) : 0,
    y: dy ? parseSize(dy, meterToPixelRatio, units) : 0
  };
}
