import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccessLevelEnum, REQUIRED_ACCESS_LEVEL_HEADER } from '../../auth/state/auth.utils';

const DEFAULT_POLLING_FREQUENCY_MS = 30 * 1000;

@Injectable({
  providedIn: 'root'
})
export class ApiPollingService {
  constructor(private http: HttpClient) {}

  poll<T>(url: string, readPermission: AccessLevelEnum, frequency: number = DEFAULT_POLLING_FREQUENCY_MS, immediate?: boolean) {
    return timer(immediate ? 0 : frequency, frequency).pipe(
      switchMap(() => this.http.get<T>(url, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: readPermission } }))
    );
  }
}
