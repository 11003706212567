import { Injectable } from '@angular/core';
import { Observable, concat, merge, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import S3 from 'aws-sdk/clients/s3';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import { sumBy, chunk } from 'lodash';
import { GetS3PathAndCredentialsResponse } from '../../../generated/fms/model/getS3PathAndCredentialsResponse';
import { AuthQuery } from '../../auth/state/auth.query';
import { OfflineBackup } from './offline-backup';

const UPLOAD_CHUNK_SIZE = 5;

export interface UploadState {
  totalFiles: number;
  totalSize: number;
  uploadedFiles: number;
  uploadedSize: number;
}

export interface FileUploadState {
  name: string;
  uploadedSum: number;
  done: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class S3FilesUploadService {
  private uploadManagers: { [fileName: string]: ManagedUpload };
  private currentBackup: OfflineBackup;

  constructor(private authQuery: AuthQuery) {}

  uploadFiles({
    credentials,
    files,
    filesWarningsMap,
    backupName,
    backupMetadata = {}
  }: {
    credentials: GetS3PathAndCredentialsResponse;
    files: File[];
    filesWarningsMap?: Record<string, string[]>;
    backupName?: string;
    backupMetadata?: any;
  }) {
    this.uploadManagers = {};
    const bucket = new S3({
      accessKeyId: credentials.credentials.accessKeyId,
      secretAccessKey: credentials.credentials.secretAccessKey,
      region: credentials.region,
      sessionToken: credentials.credentials.sessionToken,
      httpOptions: {
        timeout: 300000
      }
    });

    const filesUploadState: { [fileName: string]: FileUploadState } = files.reduce((pre, file) => {
      pre[file.name] = {
        name: file.name,
        done: false,
        uploadedSum: 0
      };
      return pre;
    }, {});

    if (backupName) {
      this.initFilesBackup({ backupName, backupMetadata, files, filesUploadState, filesWarningsMap }).then();
    }

    const totalFiles = files.length;
    const totalSize = sumBy(files, 'size');

    const fileChunks = chunk(files, UPLOAD_CHUNK_SIZE);
    return concat(
      ...fileChunks.map(fileChunk => merge(...fileChunk.map(f => this.uploadFile(credentials, bucket, f, filesWarningsMap?.[f.name]))))
    ).pipe(
      map(({ name, done, uploadedSum }) => {
        filesUploadState[name] = { name, done, uploadedSum };

        const filesStatus = Object.values(filesUploadState);
        const uploadedSize = sumBy(filesStatus, 'uploadedSum');
        const uploadedFiles = filesStatus.filter(fileStatus => fileStatus.done).length;

        // Upload is finished, no need for backup
        if (uploadedFiles === totalFiles) {
          this.removeCurrentBackup();
        }

        return {
          totalFiles,
          totalSize,
          uploadedFiles,
          uploadedSize
        } as UploadState;
      })
    );
  }

  private async initFilesBackup({
    backupName,
    backupMetadata,
    files,
    filesUploadState,
    filesWarningsMap
  }: {
    backupName: string;
    backupMetadata: any;
    files: File[];
    filesUploadState: { [fileName: string]: FileUploadState };
    filesWarningsMap?: Record<string, string[]>;
  }) {
    const userId = this.authQuery.getUserId();
    const metadata = { ...(backupMetadata || {}), userId, filesWarningsMap };

    this.currentBackup = await OfflineBackup.createBackup(backupName);
    if (!this.currentBackup) {
      return;
    }

    await this.currentBackup.setMetadata(metadata);
    await this.currentBackup.setItems(files.map(f => [f.name, f]));

    // Remove files already uploaded before added to backup, if any
    const alreadyUploadedFiles = Object.values(filesUploadState).filter(fileStatus => fileStatus.done);
    if (alreadyUploadedFiles.length > 0) {
      await this.currentBackup.removeItems(alreadyUploadedFiles.map(f => f.name));
    }
  }

  private uploadFile(s3Credentials: GetS3PathAndCredentialsResponse, bucket: S3, file: File, warnings?: string[]) {
    const userId = this.authQuery.getUserId();
    const params: S3.Types.PutObjectRequest = {
      Bucket: s3Credentials.bucketName,
      Body: file,
      ContentType: file.type,
      Key: s3Credentials.path + `/${file.name}`,
      Metadata: {
        userId,
        warnings: warnings?.join(',') || ''
      }
    };

    return new Observable<FileUploadState>(subscriber => {
      const upload = bucket.upload(params, error => {
        if (error) {
          subscriber.error(error);
        } else {
          // Upload complete
          this.currentBackup?.removeItem(file.name);
          delete this.uploadManagers[file.name];
          subscriber.complete();
        }
      });
      this.uploadManagers[file.name] = upload;

      upload.on('httpUploadProgress', (progress: ManagedUpload.Progress) => {
        subscriber.next({
          name: file.name,
          done: progress.loaded === progress.total,
          uploadedSum: progress.loaded
        });
      });
    });
  }

  resumeUploadFromBackup({
    credentials,
    filesWarningsMap,
    backupName
  }: {
    credentials: GetS3PathAndCredentialsResponse;
    filesWarningsMap?: Record<string, string[]>;
    backupName: string;
  }) {
    return from(OfflineBackup.getBackup(backupName)).pipe(
      tap(backup => (this.currentBackup = backup)),
      switchMap(() => this.currentBackup?.getAllValues()),
      switchMap((files: File[]) => {
        if (files && files.length > 0) {
          return this.uploadFiles({ credentials, files, filesWarningsMap });
        }
      })
    );
  }

  private removeCurrentBackup() {
    this.currentBackup?.dropInstance();
    this.currentBackup = null;
  }

  abortUpload(removeBackup = false) {
    if (this.uploadManagers) {
      Object.values(this.uploadManagers).forEach(upload => upload.abort());
    }
    this.uploadManagers = {};

    if (removeBackup) {
      this.removeCurrentBackup();
    }
  }
}
