export enum Step {
  IMAGES,
  FLIGHT_INFO,
  PREPROCESSING,
  GCP,
  MODEL_GENERATION
}

export const STEPS = [Step.IMAGES, Step.FLIGHT_INFO, Step.PREPROCESSING, Step.GCP, Step.MODEL_GENERATION];

export interface StepInfo {
  title: string;
  icon: string;
  showInAutomodel: boolean;
}

export const STEPS_INFO = Object.freeze({
  [Step.IMAGES]: {
    title: $localize`:@@uploadWizard.stepNameImages:Images`,
    icon: 'images',
    showInAutomodel: true
  },
  [Step.FLIGHT_INFO]: {
    title: $localize`:@@uploadWizard.stepNameFlightInfo:Flight Info & Preferences`,
    icon: 'list',
    showInAutomodel: true
  },
  [Step.PREPROCESSING]: {
    title: $localize`:@@uploadWizard.stepNamePreprocessing:Model Pre-Processing`,
    icon: 'link',
    showInAutomodel: false
  },
  [Step.GCP]: {
    title: $localize`:@@uploadWizard.stepNameGCP:GCP Marking`,
    icon: 'target',
    showInAutomodel: false
  },
  [Step.MODEL_GENERATION]: {
    title: $localize`:@@uploadWizard.stepNameModelGeneration:Model Generation`,
    icon: 'chart',
    showInAutomodel: true
  }
});
