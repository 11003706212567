/**
 * Activity Management Service
 * Manage Activities and Project Plan (GANTT)
 *
 * OpenAPI spec version: 1.8.8
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GetActivityResponse } from './getActivityResponse';

export interface CreateMergeActivityResponse {
  changes?: Array<CreateMergeActivityResponse.ChangesEnum>;
  currentVersionActivity?: GetActivityResponse;
  mergeFromVersionActivity?: GetActivityResponse;
  sourceActivityUniqueId?: string;
}
export namespace CreateMergeActivityResponse {
  export type ChangesEnum = 'END_DATE' | 'START_DATE' | 'NAME' | 'UNIT' | 'AMOUNT';
  export const ChangesEnum = {
    ENDDATE: 'END_DATE' as ChangesEnum,
    STARTDATE: 'START_DATE' as ChangesEnum,
    NAME: 'NAME' as ChangesEnum,
    UNIT: 'UNIT' as ChangesEnum,
    AMOUNT: 'AMOUNT' as ChangesEnum
  };
}
