import { HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cartesian3 } from 'angular-cesium';
import { Cesium3DTileFeature, TileProviderError } from 'cesium';
import { saveAs } from 'file-saver';
import { differenceBy, partition, uniqBy } from 'lodash';
import { Observable, combineLatest, forkJoin, merge, of, throwError } from 'rxjs';
import { catchError, delay, filter, finalize, first, map, retry, switchMap, tap, timeout } from 'rxjs/operators';
import { CreateCustomFieldRequest } from '../../../../generated/file/model/createCustomFieldRequest';
import { CreateCustomFieldsRequest } from '../../../../generated/file/model/createCustomFieldsRequest';
import { CreateFeatureDataRequest } from '../../../../generated/file/model/createFeatureDataRequest';
import { CreateGradeCheckReportsRequest } from '../../../../generated/file/model/createGradeCheckReportsRequest';
import { CreateGradeCheckReportsResponse } from '../../../../generated/file/model/createGradeCheckReportsResponse';
import { GetAllDesignsResponse } from '../../../../generated/file/model/getAllDesignsResponse';
import { GetAllRoadDesignsResponse } from '../../../../generated/file/model/getAllRoadDesignsResponse';
import { GetCustomFieldResponses } from '../../../../generated/file/model/getCustomFieldResponses';
import { GetFeatureDataResponses } from '../../../../generated/file/model/getFeatureDataResponses';
import { UpdateDesignRequest } from '../../../../generated/file/model/updateDesignRequest';
import { UpdateFeatureDataRequest } from '../../../../generated/file/model/updateFeatureDataRequest';
import { UpdateFeatureDataResponses } from '../../../../generated/file/model/updateFeatureDataResponses';
import { UpdateFeaturesDataRequest } from '../../../../generated/file/model/updateFeaturesDataRequest';
import { UpdateRoadDesignRequest } from '../../../../generated/file/model/updateRoadDesignRequest';
import { UploadDesignRequest } from '../../../../generated/file/model/uploadDesignRequest';
import { UploadDesignResponse } from '../../../../generated/file/model/uploadDesignResponse';
import { UploadRoadDesignRequest } from '../../../../generated/file/model/uploadRoadDesignRequest';
import { UploadRoadDesignResponse } from '../../../../generated/file/model/uploadRoadDesignResponse';
import { CloudFrontPreSignedPolicy } from '../../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import { GetAllCategoriesResponse } from '../../../../generated/tenant/model/getAllCategoriesResponse';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { ResourceLinkType } from '../../../shared/resource-links/resource-links.model';
import { ResourceLinksService } from '../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ApiPollingService } from '../../../shared/services/api-polling.service';
import { SnackBarService } from '../../../shared/services/snackbar.service';
import { getServiceUrl } from '../../../shared/utils/backend-services';
import { isDefined } from '../../../shared/utils/general';
import { Site } from '../../../tenant/tenant.model';
import { TenantQuery } from '../../../tenant/tenant.query';
import { SiteMapService } from '../../services/site-map.service';
import { DesignGeojsonLoadError, DesignsMapManagerService } from './designs-map-manager.service';
import {
  CustomPropertyInteractionType,
  Design,
  DesignCategory,
  DesignLayerProperty,
  DesignLayerPropertyType,
  DesignState,
  DesignType,
  DesignsUploadingState,
  RoadDesign,
  RoadDesignType,
  RoadDesignValidationError,
  StationNamingFormat,
  customFieldToLayerProperty
} from './detailed-site-designs.model';
import { DetailedSiteDesignsQuery } from './detailed-site-designs.query';
import { DetailedSiteDesignsStore } from './detailed-site-designs.store';

@Injectable({ providedIn: 'root' })
export class DetailedSiteDesignsService {
  constructor(
    private siteDesignsStore: DetailedSiteDesignsStore,
    private http: HttpClient,
    private designsQuery: DetailedSiteDesignsQuery,
    private designsManager: DesignsMapManagerService,
    private tenantQuery: TenantQuery,
    private apiPoller: ApiPollingService,
    private analyticsService: AnalyticsService,
    private snackbar: SnackBarService,
    private siteMapService: SiteMapService,
    private resourceLinksService: ResourceLinksService
  ) {}

  init(site: Site, viewerCredentials: CloudFrontPreSignedPolicy) {
    this.siteDesignsStore.initStore(site.id, viewerCredentials);

    // Save site units in design manager for unit conversions
    this.designsManager.setSiteUnits(site.units);
  }

  getDesignCategories(siteId: string) {
    return this.http
      .get(`${getServiceUrl('file')}/sites/${siteId}/designs/categories`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designCategories.read }
      })
      .pipe(
        tap((response: GetAllCategoriesResponse) => {
          if (response && response.categories) {
            this.siteDesignsStore.setDesignCategories(response.categories);
          }
        })
      );
  }

  getSiteDesigns(siteId: string) {
    return merge(
      this.http.get(`${getServiceUrl('file')}/sites/${siteId}/designs`).pipe(
        tap((response: GetAllDesignsResponse) => {
          this.siteDesignsStore.upsertDesigns(response.designs);
        })
      ),
      this.tenantQuery.gradeCheckingFeatureFlag$.pipe(
        first(),
        switchMap(gradeCheckingEnabled =>
          !gradeCheckingEnabled
            ? of(null)
            : this.http
                .get(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns`, {
                  headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.roadDesigns.read }
                })
                .pipe(
                  tap((response: GetAllRoadDesignsResponse) => {
                    this.siteDesignsStore.upsertRoadDesigns(response.roadDesigns);
                  })
                )
        )
      )
    );
  }

  async loadDesign(design: Design | RoadDesign) {
    if ((design.jsonReady || design.cesium3DReady) && !design.loading && !isDefined(design.layers)) {
      this.updateDesign(design.id, { ...design, loading: true });
      let parsedDesign: Design | RoadDesign;

      try {
        const { geojson, tiles, tiles2DMetadata, tiles3DMetadata } = this.designsQuery.getDesignUrls(design.id);

        const { layers, bbox } = await this.designsManager.loadDesign(
          design,
          geojson,
          tiles,
          tiles2DMetadata,
          tiles3DMetadata,
          this.designLoadError(design)
        );
        parsedDesign = {
          ...design,
          layers,
          bbox,
          loading: false
        };
      } catch (error) {
        console.error('Error loading design ' + design.id, error);
        parsedDesign = { ...design, loadingError: true };
      }
      this.updateDesign(design.id, parsedDesign);
    }
  }

  private designLoadError = (design: Design | RoadDesign) => (error: TileProviderError | DesignGeojsonLoadError) => {
    let message = 'Error loading design';
    if (error instanceof DesignGeojsonLoadError) {
      message = error.message;
      this.updateDesign(design.id, { ...design, loading: false, allIsShown: false });
    } else {
      this.updateDesign(design.id, { ...design, loadingError: true, loading: false, allIsShown: false, layers: null });
      this.designsManager.removeDesign(design.id);
    }
    this.snackbar.openError(message, { design, error });
  };

  uploadDesigns(files: { file: File; name: string }[], categoryId?: string, hasSurface?: boolean) {
    const totalSize = files.reduce((size, f) => size + f.file.size, 0);
    this.setDesignsUploadingState({ totalFiles: files.length, totalSize });

    const uploadedSizeMapping = files.reduce((mapping, f) => {
      mapping[f.name] = { size: 0, done: false };
      return mapping;
    }, {} as { [name: string]: { size: number; done?: boolean } });

    const updateUploadingState = (data: { file: File; loaded?: number; done?: boolean }) => {
      const { file, loaded, done } = data;
      uploadedSizeMapping[file.name] = { size: done ? file.size : loaded, done };

      this.setDesignsUploadingState({
        uploadedSize: Object.values(uploadedSizeMapping).reduce((sum, data) => sum + data.size, 0),
        uploadedFiles: Object.values(uploadedSizeMapping).filter(data => data.done).length
      });
    };

    return forkJoin(
      files.map(f =>
        this.uploadDesign(f.file, f.name, categoryId, hasSurface, files.length > 1, updateUploadingState).pipe(
          catchError(error => {
            return throwError(() => ({ ...error, fileName: f.name }));
          })
        )
      )
    ).pipe(finalize(() => this.setDesignsUploadingState(null)));
  }

  private uploadDesign(
    file: File,
    name: string,
    categoryId: string,
    hasSurface: boolean,
    isMultiFileUpload: boolean,
    updateUploadingState: (data: { file: File; loaded?: number; done?: boolean }) => void
  ) {
    const siteId = this.designsQuery.getSiteId();
    const designParams: UploadDesignRequest = { name, categoryId, fileName: file.name, hasSurface };

    return this.http
      .post(`${getServiceUrl('file')}/sites/${siteId}/designs`, designParams, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designs.create }
      })
      .pipe(
        switchMap((designResponse: UploadDesignResponse) => {
          const designId = designResponse.id;
          const uploadUrl = designResponse.url;

          return this.http
            .put(uploadUrl, file, {
              reportProgress: true,
              observe: 'events',
              headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designs.create }
            })
            .pipe(
              tap(resp => {
                if (resp.type === HttpEventType.UploadProgress) {
                  updateUploadingState({ file, loaded: resp.loaded });
                } else if (resp.type === HttpEventType.Response) {
                  updateUploadingState({ file, done: true });
                }
              }),
              filter(resp => resp.type === HttpEventType.Response),
              switchMap(() => {
                return this.http.put(`${getServiceUrl('file')}/sites/${siteId}/designs/${designId}/completeUpload`, true, {
                  headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designs.create }
                });
              }),
              tap(() => {
                const design: Design = {
                  type: DesignType.DESIGN,
                  name: designParams.name,
                  categoryId,
                  id: designId,
                  siteId,
                  state: DesignState.PROCESSING,
                  fileName: file.name
                };
                this.siteDesignsStore.upsertDesigns([design]);
                this.analyticsService.importDesign(design, isMultiFileUpload);
              })
            );
        })
      );
  }

  uploadRoadDesign(
    name: string,
    categoryId: string,
    roadDesignType: RoadDesignType,
    files: { file1: File; file2: File; file3: File },
    surfaceDesignFields?: { startStation: string; stationInterval: number; stationNamingFormat: StationNamingFormat }
  ) {
    const file1Name = files.file1.name;
    const file2Name = files.file2.name;
    const file3Name = files.file3.name;
    const requestParams: UploadRoadDesignRequest = {
      name,
      categoryId,
      roadDesignType,
      file1Name,
      file2Name,
      file3Name,
      startStation: surfaceDesignFields?.startStation,
      stationInterval: surfaceDesignFields?.stationInterval,
      stationNamingFormatType: surfaceDesignFields?.stationNamingFormat,
      roadName: 'TODO' // change to actual name when field is in form
    };

    this.setDesignsUploadingState({
      totalFiles: 3,
      totalSize: files.file1.size + files.file2.size + files.file3.size
    });

    const siteId = this.designsQuery.getSiteId();
    const permission = PERMISSIONS.roadDesigns.create;
    const headers = { [REQUIRED_ACCESS_LEVEL_HEADER]: permission };
    return this.http.post(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns`, requestParams, { headers }).pipe(
      switchMap((response: UploadRoadDesignResponse) => {
        const roadDesignId = response.id;
        const uploadedSizeMapping = Object.keys(files).reduce((mapping, fileType) => {
          mapping[fileType] = { size: 0, done: false };
          return mapping;
        }, {} as { [fileType: string]: { size: number; done: boolean } });

        return forkJoin(
          Object.keys(files).map(fileType =>
            this.http
              .put(response.inputFiles[fileType + 'Url'], files[fileType], { reportProgress: true, observe: 'events', headers })
              .pipe(
                tap(resp => {
                  if (resp.type === HttpEventType.UploadProgress) {
                    uploadedSizeMapping[fileType].size = resp.loaded;
                  } else if (resp.type === HttpEventType.Response) {
                    uploadedSizeMapping[fileType].size = files[fileType].size;
                    uploadedSizeMapping[fileType].done = true;
                  }
                  this.setDesignsUploadingState({
                    uploadedSize: Object.values(uploadedSizeMapping).reduce((sum, data) => sum + data.size, 0),
                    uploadedFiles: Object.values(uploadedSizeMapping).filter(data => data.done).length
                  });
                })
              )
          )
        ).pipe(
          switchMap(() => {
            if (roadDesignType === RoadDesignType.SURFACE) {
              return this.http
                .put(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}/completeSurfaceUpload`, true, { headers })
                .pipe(
                  tap(() => this.setDesignsUploadingState(null)),
                  switchMap(() => this.http.get(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}`, { headers })),
                  tap((roadDesign: RoadDesign) => {
                    this.siteDesignsStore.upsertRoadDesigns([roadDesign]);
                    this.analyticsService.importRoadDesign(roadDesign);
                  })
                );
            } else {
              // Poll for road design validation runs after calling complete upload
              return this.http
                .put(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}/completeUpload`, true, { headers })
                .pipe(
                  tap(() => this.setDesignsUploadingState({ validating: true })),
                  switchMap(() =>
                    this.apiPoller.poll(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}`, permission, 5000, true)
                  ),
                  filter((roadDesign: RoadDesign) => roadDesign.state !== DesignState.PROCESSING),
                  tap((roadDesign: RoadDesign) => {
                    if (roadDesign.state === DesignState.VALIDATIONFAILED) {
                      throw new RoadDesignValidationError(roadDesign);
                    } else {
                      this.siteDesignsStore.upsertRoadDesigns([roadDesign]);
                      this.analyticsService.importRoadDesign(roadDesign);
                    }
                  }),
                  catchError(error => {
                    // Delete road design validation error occured
                    if (error instanceof RoadDesignValidationError) {
                      this.deleteRoadDesign(error.roadDesign).subscribe({
                        error: error => {
                          this.snackbar.openError('Error deleting road design', error);
                        }
                      });
                    }
                    return throwError(() => error);
                  })
                );
            }
          })
        );
      }),
      finalize(() => this.setDesignsUploadingState(null))
    );
  }

  setDesignsUploadingState(designsUploading: Partial<DesignsUploadingState>) {
    this.siteDesignsStore.setDesignsUploadingState(designsUploading);
  }

  startDesignsPolling(pollingFreq?: number) {
    const siteId = this.designsQuery.getSiteId();

    const requests: Observable<any>[] = [
      this.apiPoller.poll(`${getServiceUrl('file')}/sites/${siteId}/designs`, PERMISSIONS.designs.read, pollingFreq, true).pipe(
        map((response: GetAllDesignsResponse) => response?.designs),
        tap((designs: Design[]) => {
          const currentSiteId = this.designsQuery.getSiteId();
          if (currentSiteId === siteId && isDefined(designs)) {
            // Find removed designs and update resource links
            const prevDesigns = this.designsQuery.getAllDesigns();
            const removedDesigns = differenceBy(prevDesigns, designs, 'id');
            removedDesigns.forEach(design => this.resourceLinksService.removeResource(design.id, ResourceLinkType.DESIGN));

            this.siteDesignsStore.upsertDesigns(designs);
          }
        })
      )
    ];

    if (this.tenantQuery.getGradeCheckingFeatureFlag()) {
      requests.push(
        this.apiPoller.poll(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns`, PERMISSIONS.roadDesigns.read, pollingFreq, true).pipe(
          map((response: GetAllRoadDesignsResponse) => response?.roadDesigns),
          tap((roadDesigns: RoadDesign[]) => {
            const currentSiteId = this.designsQuery.getSiteId();
            if (currentSiteId === siteId && isDefined(roadDesigns)) {
              // Find removed road designs and update resource links
              const prevRoadDesigns = this.designsQuery.getAllRoadDesigns();
              const removedRoadDesigns = differenceBy(prevRoadDesigns, roadDesigns, 'id');
              removedRoadDesigns.forEach(roadDesign =>
                this.resourceLinksService.removeResource(roadDesign.id, ResourceLinkType.ROADDESIGN)
              );

              this.siteDesignsStore.upsertRoadDesigns(roadDesigns);
            }
          })
        )
      );
    }

    return merge(...requests);
  }

  createDesignCategory(name: string) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .post(
        `${getServiceUrl('file')}/sites/${siteId}/designs/categories`,
        { name, siteId },
        { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designCategories.create } }
      )
      .pipe(
        map((result: { id: string }) => {
          if (result) {
            return { name, siteId, id: result.id } as DesignCategory;
          }
        }),
        tap((category: DesignCategory) => category && this.siteDesignsStore.addDesignCategory(category))
      );
  }

  editDesignCategory(categoryId: string, name: string) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .put(
        `${getServiceUrl('file')}/sites/${siteId}/designs/categories/${categoryId}`,
        { name },
        { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designCategories.update } }
      )
      .pipe(
        map(() => ({ name, siteId, id: categoryId } as DesignCategory)),
        tap((category: DesignCategory) => this.siteDesignsStore.updateDesignCategory(category))
      );
  }

  deleteDesignCategory(categoryId: string) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/designs/categories/${categoryId}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designCategories.delete }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.designCategories.remove(categoryId);

          // Update all designs in category to have no category. No need to update backend since it is done automatically
          this.siteDesignsStore.designs.update((design: Design) => design.categoryId === categoryId, {
            categoryId: null
          });
          this.siteDesignsStore.roadDesigns.update((roadDesign: RoadDesign) => roadDesign.categoryId === categoryId, {
            categoryId: null
          });
        })
      );
  }

  async hideAllDesigns() {
    const visibleDesigns = this.designsManager.getVisibleDesigns();
    await Promise.all(visibleDesigns.map(design => this.setDesignShowAll(design.id, design.type, false)));
  }

  async setDesignLayersShow(designId: string, type: DesignType, selectedLayersIds: Set<string>) {
    this.updateDesign(designId, { type, loading: true });
    await this.designsManager.showLayers(designId, selectedLayersIds);
    this.siteDesignsStore.setDesignLayers(designId, type, null, selectedLayersIds);
    this.updateDesign(designId, { type, loading: false });
  }

  setDesignLayersExpanded(designId: string, type: DesignType, layers: Set<string>, expanded: boolean) {
    const designLayers = this.designsQuery.getDesignByType(type, designId)?.layers;

    if (!isDefined(designLayers)) {
      return;
    }

    const updatedLayers = designLayers.map(layer => {
      if (layers.has(layer.id)) {
        return { ...layer, expanded };
      } else {
        return { ...layer, expanded: false };
      }
    });

    this.updateDesign(designId, { type, layers: updatedLayers });
  }

  async setDesignShowAll(designId: string, type: DesignType, show: boolean) {
    const design = this.designsQuery.getDesignByType(type, designId);

    // Load design if not already loaded
    if (show && !this.designsManager.isDesignLoaded(designId)) {
      await this.loadDesign(design);
    }

    this.updateDesign(designId, { type, loading: true });
    await this.designsManager.showAllLayers(designId, show);
    this.siteDesignsStore.setDesignLayers(designId, type, show);
    this.updateDesign(designId, { type, loading: false });

    if (show) {
      this.analyticsService.displayDesign(design);
    }
  }

  deleteDesign(design: Design) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/designs/${design.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designs.delete }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.deleteDesign(design.id);
          this.designsManager.removeDesign(design.id);
          this.resourceLinksService.removeResource(design.id, ResourceLinkType.DESIGN);
          this.analyticsService.deleteDesign(design);
        })
      );
  }

  editDesign(design: Design, name: string, categoryId: string) {
    const siteId = this.designsQuery.getSiteId();
    const body: UpdateDesignRequest = { name, categoryId };
    return this.http
      .put(`${getServiceUrl('file')}/sites/${siteId}/designs/${design.id}`, body, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designs.update }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.updateDesign(design.id, { id: design.id, ...body });
          this.analyticsService.updateDesign(design);
        })
      );
  }

  editRoadDesign(roadDesign: RoadDesign, name: string, categoryId: string) {
    const siteId = this.designsQuery.getSiteId();
    const body: UpdateRoadDesignRequest = { name, categoryId };
    return this.http
      .put(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesign.id}`, body, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.roadDesigns.update }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.updateRoadDesign(roadDesign.id, { id: roadDesign.id, ...body });
          this.analyticsService.updateRoadDesign(roadDesign);
        })
      );
  }

  deleteRoadDesign(roadDesign: RoadDesign) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesign.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.roadDesigns.delete }
      })
      .pipe(
        tap(() => {
          this.siteDesignsStore.deleteRoadDesign(roadDesign.id);
          this.designsManager.removeDesign(roadDesign.id);
          this.resourceLinksService.removeResource(roadDesign.id, ResourceLinkType.ROADDESIGN);
          this.analyticsService.deleteRoadDesign(roadDesign);
        })
      );
  }

  updateDesign(id: string, design: Partial<Design | RoadDesign> & { type: DesignType }) {
    if (design.type === DesignType.DESIGN) {
      this.siteDesignsStore.updateDesign(id, design);
    } else {
      this.siteDesignsStore.updateRoadDesign(id, design);
    }
  }

  async toggleDesignProjectionView(designId: string, type: DesignType, isGeoJsonEntitiesMode: boolean) {
    this.updateDesign(designId, { type, isGeoJsonEntitiesMode: !isGeoJsonEntitiesMode, loading: true });
    await this.designsManager.setDesignProjectionView(designId, !isGeoJsonEntitiesMode);
    this.updateDesign(designId, { type, loading: false });
  }

  generateElementProgressReport(taskId: string, designId: string) {
    if (!this.tenantQuery.getElectricPolesReportFeatureFlag()) {
      return;
    }

    const siteId = this.designsQuery.getSiteId();
    return this.http.put(`${getServiceUrl('fms')}/tasks/${siteId}/designs/${designId}/tasks/${taskId}/startElectricAIBatch`, true);
  }

  generateGradeCheckingReport(request: {
    designId: string;
    measuredPointsId: string;
    layerDescription: string;
    layerOffset: number;
    reportName: string;
    selectedPointIds: string[];
  }) {
    const siteId = this.designsQuery.getSiteId();
    const body: CreateGradeCheckReportsRequest = {
      name: request.reportName,
      baseLayerDistanceFromTop: request.layerOffset,
      baseLayerName: request.layerDescription,
      measuredPointsId: request.measuredPointsId,
      pointsIds: request.selectedPointIds,
      roadDesignId: request.designId
    };
    return this.http
      .post(`${getServiceUrl('file')}/sites/${siteId}/gradeCheckReports`, body, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.generateGradeCheckingReports }
      })
      .pipe(
        switchMap((response: CreateGradeCheckReportsResponse) => {
          if (response?.url) {
            return this.http.get(response.url, { responseType: 'blob' }).pipe(
              // Added retries here because we can't tell when the report finishes otherwise
              retry({
                delay: (err: HttpErrorResponse) => {
                  if (err.status === 404) {
                    // Return valid response after a delay
                    return of(true).pipe(delay(7 * 1000));
                  } else {
                    throw err;
                  }
                }
              }),
              timeout(3 * 60 * 1000), // 3 minutes
              tap((reportBlob: Blob) => {
                if (!reportBlob) {
                  throw new Error('Error downloading report');
                }

                saveAs(reportBlob, `${request.reportName}.pdf`);
              })
            );
          } else {
            return of(null);
          }
        })
      );
  }

  setActiveDesignLayerProperties(properties: DesignLayerProperty[]) {
    this.siteDesignsStore.setActiveDesignLayerProperties(properties);
  }

  setSiteOffset(offset: number) {
    this.designsManager.setSiteOffset(offset);
  }

  addDownloadedDesignToAnalytics(design: Design | RoadDesign) {
    this.analyticsService.downloadDesignFile(design);
  }

  resetStore() {
    this.designsManager.clear();
    this.siteDesignsStore.reset();
  }

  setActiveDesign(designId: string, designType: DesignType) {
    const activeDesigns = this.designsQuery.getActiveDesigns(designType);
    if (!isDefined(activeDesigns)) {
      this.siteDesignsStore.setActiveDesigns([designId], designType);
      return;
    }
    this.siteDesignsStore.toggleActiveDesign(designId, designType);
  }

  async zoomIntoDesign(design: Design | RoadDesign) {
    if (!design) {
      return;
    }

    if (!design.bbox) {
      await this.loadDesign(design);
      if (design.type === DesignType.DESIGN) {
        design = this.designsQuery.getDesign(design.id);
      } else if (design.type === DesignType.ROAD_DESIGN) {
        design = this.designsQuery.getRoadDesign(design.id);
      }
    }

    if (!design.bbox || design.bbox.some(n => !isFinite(n))) {
      return;
    }

    const [minX, minY, maxX, maxY] = design.bbox;
    let positions: Cartesian3[];
    if (minX === maxX && minY === maxY) {
      positions = [Cesium.Cartesian3.fromDegrees(minX, minY)];
    } else {
      positions = [
        Cesium.Cartesian3.fromDegrees(minX, minY),
        Cesium.Cartesian3.fromDegrees(maxX, minY),
        Cesium.Cartesian3.fromDegrees(maxX, maxY),
        Cesium.Cartesian3.fromDegrees(minX, maxY)
      ];
    }
    await this.siteMapService.zoomInto(positions);
  }

  setLayerFeaturesSelection(features: Cesium3DTileFeature[]) {
    this.designsManager.setLayerFeaturesSelection(features);
  }

  clearSelectedLayerFeatures() {
    this.designsManager.clearSelectedLayerFeatures();
  }

  setDesignActiveLayer(designId: string, layerId?: string, childLayers?: string[], layerName?: string) {
    this.clearSelectedLayerFeatures();
    this.siteDesignsStore.setDesignActiveLayer(designId, layerId, childLayers, layerName);
    if (!isDefined(designId)) {
      this.siteDesignsStore.setActiveDesignLayerProperties(null);
    }
  }

  getLayerProperties(designId: string, layerId: string, isLeaf: boolean) {
    const designMetaDateProperties = this.designsManager.getDesignLayerMetaDataProperties(designId);
    const siteCustomProperties = this.fetchSiteCustomProperties();
    const layerProperties = this.fetchLayerProperties(designId, layerId);
    const design = this.designsQuery.getDesign(designId);

    this.analyticsService.showDesignFeatureProperties(design);

    return combineLatest([layerProperties, siteCustomProperties]).pipe(
      map(([propertiesResult, siteCustomPropertiesResult]) => {
        const [layerCustomProperties, LayerMetaDataProperties] = partition(
          propertiesResult,
          prop => prop.fieldType === DesignLayerPropertyType.CUSTOM
        );
        const mergedCustomProperties = uniqBy([...layerCustomProperties, ...siteCustomPropertiesResult], 'fieldId');
        const layerProperties = isLeaf
          ? [...mergedCustomProperties, ...uniqBy([...LayerMetaDataProperties, ...designMetaDateProperties], 'name')]
          : [...mergedCustomProperties, ...LayerMetaDataProperties];

        return layerProperties;
      })
    );
  }

  private fetchLayerProperties(designId: string, layerId: string) {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .get(`${getServiceUrl('file')}/featureData/sites/${siteId}/designs/${designId}/featureData`, {
        params: { featureId: layerId },
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designLayerProperties.read }
      })
      .pipe(map((result: GetFeatureDataResponses) => result?.featureDataResponses));
  }

  private fetchSiteCustomProperties() {
    const siteId = this.designsQuery.getSiteId();
    return this.http
      .get(`${getServiceUrl('file')}/featureData/sites/${siteId}/customFields`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designLayerProperties.read }
      })
      .pipe(map((result: GetCustomFieldResponses) => customFieldToLayerProperty(result?.customFieldResponses)));
  }

  selectLayerFeatures(designId: string) {
    const features = this.designsManager.getLayerFeatures(designId);

    if (isDefined(features)) {
      this.setLayerFeaturesSelection(features);
    } else {
      this.clearSelectedLayerFeatures();
    }
  }

  createNewSiteCustomField(customProperty: DesignLayerProperty) {
    const siteId = this.designsQuery.getSiteId();
    const site = this.tenantQuery.getSite(siteId);
    const property: CreateCustomFieldRequest[] = [{ name: customProperty.name, valueType: customProperty.valueType }];
    const request: CreateCustomFieldsRequest = { requestList: property };

    this.analyticsService.siteCustomPropertiesInteraction(site, property, CustomPropertyInteractionType.CREATE);

    return this.http
      .post(`${getServiceUrl('file')}/featureData/sites/${siteId}/customFields`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.siteCustomProperties.create }
      })
      .pipe(map((result: GetCustomFieldResponses) => result?.customFieldResponses));
  }

  createNewLayerProperty(customProperty: DesignLayerProperty) {
    const siteId = this.designsQuery.getSiteId();
    const designId = this.designsQuery.getActiveLayerDesignId();
    const design = this.designsQuery.getDesign(designId);
    const layerId = this.designsQuery.getActiveLayerId();
    const property: CreateFeatureDataRequest = {
      featureId: layerId,
      fieldId: customProperty.fieldId,
      name: customProperty.name,
      value: customProperty.value,
      valueType: customProperty.valueType
    };

    this.analyticsService.designCustomPropertyInteraction(design, property, CustomPropertyInteractionType.CREATE);

    return this.http.post(`${getServiceUrl('file')}/featureData/sites/${siteId}/designs/${designId}/featureData`, property, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designLayerProperties.create }
    });
  }

  updateCustomProperties(properties: DesignLayerProperty[]) {
    const siteId = this.designsQuery.getSiteId();
    const designId = this.designsQuery.getActiveLayerDesignId();
    const updatedProperty: UpdateFeatureDataRequest[] = properties.map(({ id, value }) => ({ id, value }));
    const request: UpdateFeaturesDataRequest = { requestList: updatedProperty };

    if (properties.length === 1 && isDefined(properties[0].value)) {
      const design = this.designsQuery.getDesign(designId);
      this.analyticsService.designCustomPropertyInteraction(design, properties[0], CustomPropertyInteractionType.UPDATE);
    }

    return this.http
      .put(`${getServiceUrl('file')}/featureData/sites/${siteId}/designs/${designId}/featureData`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.designLayerProperties.update }
      })
      .pipe(map((result: UpdateFeatureDataResponses) => result?.responseList));
  }
}
