/**
 * Measurements Management Service
 * APIs document (version: 2.0.5)
 *
 * OpenAPI spec version: 2.0.5
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AnalyticData } from './analyticData';
import { BaseSurface } from './baseSurface';
import { CoordinateBlock } from './coordinateBlock';
import { CoordinateModel } from './coordinateModel';

export interface GetAnalyticResponse {
  baseSurface?: BaseSurface;
  coordinates?: Array<CoordinateModel>;
  createdBy?: string;
  creationTime?: Date;
  data?: Array<AnalyticData>;
  dataUrl?: string;
  dataVersion?: number;
  groupId?: string;
  id?: string;
  lastModifiedTime?: Date;
  layerId?: string;
  name?: string;
  positions?: Array<CoordinateBlock>;
  sourceModel?: GetAnalyticResponse.SourceModelEnum;
  type?: GetAnalyticResponse.TypeEnum;
}
export namespace GetAnalyticResponse {
  export type SourceModelEnum = 'DSM' | 'DTM' | 'FLAT';
  export const SourceModelEnum = {
    DSM: 'DSM' as SourceModelEnum,
    DTM: 'DTM' as SourceModelEnum,
    FLAT: 'FLAT' as SourceModelEnum
  };
  export type TypeEnum = 'CROSS_SECTION_PROGRESS' | 'POINT_DELTA_ELEVATION' | 'POLYGON_DELTA_ELEVATION' | 'POLYGON_DELTA_VOLUME';
  export const TypeEnum = {
    CROSS_SECTION_PROGRESS: 'CROSS_SECTION_PROGRESS' as TypeEnum, // DON'T REMOVE THE UNDER SCORE (CROSS_SECTION_PROGRESS => CROSSSECTIONPROGRESS)
    POINT_DELTA_ELEVATION: 'POINT_DELTA_ELEVATION' as TypeEnum, // DON'T REMOVE THE UNDER SCORE (POINT_DELTA_ELEVATION => POINTDELTAELEVATION)
    POLYGON_DELTA_ELEVATION: 'POLYGON_DELTA_ELEVATION' as TypeEnum, // DON'T REMOVE THE UNDER SCORE (POLYGON_DELTA_ELEVATION => POLYGONDELTAELEVATION)
    POLYGON_DELTA_VOLUME: 'POLYGON_DELTA_VOLUME' as TypeEnum // DON'T REMOVE THE UNDER SCORE (POLYGON_DELTA_VOLUME => POLYGONDELTAVOLUME)
  };
}
