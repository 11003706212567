/**
 * Datumate Tenant Service
 * APIs
 *
 * OpenAPI spec version: 3.1.9
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetTenantFeatureFlagNamesResponse {
  featureFlags?: Array<GetTenantFeatureFlagNamesResponse.FeatureFlagsEnum>;
}
export namespace GetTenantFeatureFlagNamesResponse {
  export type FeatureFlagsEnum =
    | 'GRADE_CHECKING'
    | 'COST_CODES'
    | 'ELEC_POLES_AI'
    | 'HIDE_MAP'
    | 'ML_GCP'
    | 'ACTIVITY_MANAGEMENT'
    | 'DISABLE_CREATE_REPORT_NON_ADMIN'
    | 'ACTIVITY_DETAILS'
    | 'UNLIMITED_MPX_IMG';
  export const FeatureFlagsEnum = {
    GRADECHECKING: 'GRADE_CHECKING' as FeatureFlagsEnum,
    COSTCODES: 'COST_CODES' as FeatureFlagsEnum,
    ELECPOLESAI: 'ELEC_POLES_AI' as FeatureFlagsEnum,
    HIDEMAP: 'HIDE_MAP' as FeatureFlagsEnum,
    MLGCP: 'ML_GCP' as FeatureFlagsEnum,
    ACTIVITYMANAGEMENT: 'ACTIVITY_MANAGEMENT' as FeatureFlagsEnum,
    DISABLECREATEREPORTNONADMIN: 'DISABLE_CREATE_REPORT_NON_ADMIN' as FeatureFlagsEnum,
    ACTIVITYDETAILS: 'ACTIVITY_DETAILS' as FeatureFlagsEnum,
    UNLIMITEDMPXIMG: 'UNLIMITED_MPX_IMG' as FeatureFlagsEnum
  };
}
