import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { resetStores, toBoolean } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ForgotPasswordRequest } from '../../../generated/ums/model/forgotPasswordRequest';
import { GetDomainProvidersListResponse } from '../../../generated/ums/model/getDomainProvidersListResponse';
import { GetDomainProvidersResponse } from '../../../generated/ums/model/getDomainProvidersResponse';
import { LoginListResponse } from '../../../generated/ums/model/loginListResponse';
import { RefreshTokenRequests } from '../../../generated/ums/model/refreshTokenRequests';
import { RefreshTokenResponses } from '../../../generated/ums/model/refreshTokenResponses';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { DeviceService } from '../../shared/services/device.service';
import { SnackBarService } from '../../shared/services/snackbar.service';
import { getServiceUrl } from '../../shared/utils/backend-services';
import { isDefined } from '../../shared/utils/general';
import { AuthQuery } from './auth.query';
import { AuthStore, UserSession, getUserSessionFromLocalStorage } from './auth.store';
import { LogInStateEnum, UserRole, getTenantStateErrorMessage, getUserAccessLevel } from './auth.utils';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private authStore: AuthStore,
    private http: HttpClient,
    private authQuery: AuthQuery,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private router: Router,
    private routerQuery: RouterQuery,
    private snackbar: SnackBarService,
    private device: DeviceService
  ) {}

  getUserSSOProviders(userEmail: string): Observable<GetDomainProvidersResponse[]> {
    const url = `${getServiceUrl('ums')}/domainProviders/${encodeURIComponent(userEmail)}`;
    return this.http.get(url).pipe(map((res: GetDomainProvidersListResponse) => res.domainProviders));
  }

  login(cred: { email: string; password?: string; accessToken?: string }, loginBySSO = false) {
    const url = loginBySSO ? environment.ssoLoginUrl : `${getServiceUrl('ums')}/users/loginCredentialsFE`;
    const headers = loginBySSO ? { Authorization: `Bearer ${cred.accessToken}` } : {};
    const reqBody = loginBySSO ? { email: cred.email } : cred;

    return this.http.post(url, reqBody, { headers }).pipe(
      map((authData: LoginListResponse) => ({
        userSession: authData.loginListResponses,
        isSameUser: this.isSameUserInLocalStorage(authData.loginListResponses)
      })),
      tap(({ userSession, isSameUser }) => {
        if (!isSameUser) {
          this.clearStorage();
        }
        this.authStore.initStateFromUserSession(userSession);
        const userData = {
          isNew: this.routerQuery.getData()?.newUser,
          activeSession: this.authQuery.getActiveUserResponse(),
          email: cred.email,
          deviceType: this.device.getDeviceType(),
          role: this.authQuery.getUserRole(),
          loginBySSO,
          providerName: loginBySSO ? JSON.parse(localStorage.getItem('LATEST_SSO_SELECTED'))?.name : null
        };
        this.analyticsService.login(userData);
      })
    );
  }

  logout(byInactivity = false) {
    const refreshTokens = this.authQuery.getAllRefreshTokens();
    const logout$ =
      !byInactivity && isDefined(refreshTokens)
        ? this.http.post(`${getServiceUrl('ums')}/users/current/logout`, { refreshTokens })
        : of(null);
    logout$.subscribe(() => {
      this.dialog.closeAll();
      resetStores();
      this.analyticsService.logout(byInactivity);

      if (!byInactivity) {
        this.clearStorage();
      }

      const redirectUrl = location.pathname;
      const isBaseURL = !redirectUrl?.replace('/', '');
      const queryParams = location.search;
      const url = byInactivity && !isBaseURL ? `/login?redirectUrl=${encodeURIComponent(location.pathname + queryParams)}` : '/login';
      window.open(url, '_self');
    });
  }

  refreshAllAccessTokens() {
    const refreshTokens = this.authQuery
      .getLoginResponses()
      .map(loginResponse => loginResponse.refreshToken)
      .filter(token => !!token);
    if (!isDefined(refreshTokens)) {
      return throwError(() => new Error('No valid refresh tokens'));
    }

    const url = `${getServiceUrl('ums')}/users/current/refreshToken`;
    const body: RefreshTokenRequests = { refreshTokens };
    return this.http.post<RefreshTokenResponses>(url, body).pipe(
      tap((response: RefreshTokenResponses) => {
        if (isDefined(response?.refreshTokenResponses)) {
          // Add relevant field (like logInState) to response
          const userSession = response.refreshTokenResponses.map(response => ({
            ...this.authQuery.getLoginResponseByTenantId(response.tenantId),
            ...response
          }));
          this.authStore.updateStateFromUserSession(userSession);
        }
      })
    );
  }

  forgotPasswordRequest(cred: ForgotPasswordRequest) {
    const url = `${getServiceUrl('ums')}/users/forgotPassword`;
    return this.http.post(url, cred);
  }

  changePasswordRequest(userId: string, forgotPasswordToken: string, password: string) {
    const url = `${getServiceUrl('ums')}/users/changePassword/${userId}`;
    return this.http.put(url, { forgotPasswordToken, password });
  }

  setActiveTenant(id: string) {
    if (!id) {
      this.authStore.setActiveTenant(null);
      return;
    }

    const userRole = this.authQuery.getUserRole();
    let newActiveTenant = this.authQuery.getLoginResponseByTenantId(id);
    if (!newActiveTenant || newActiveTenant.logInState !== LogInStateEnum.SUCCESS) {
      const prevTenant = newActiveTenant;
      newActiveTenant = this.authQuery.getFirstValidLoginResponse();
      if (!newActiveTenant) {
        console.error('No valid tenant');
        this.authStore.setActiveTenant(null);
        this.logout();
        return;
      }

      // Show error message to user
      const msg = prevTenant
        ? getTenantStateErrorMessage(prevTenant.logInState, prevTenant.tenantName)
        : `Account does not exist. Signed into ${newActiveTenant.tenantName} instead`;
      this.snackbar.openError(msg, { id, prevTenant }, { duration: 0 });

      // Redirect to valid tenant
      const defaultRedirectUrl = this.authQuery.getDefaultUrl(userRole);
      this.router.navigateByUrl(defaultRedirectUrl, { replaceUrl: true });
    }

    this.authStore.setActiveTenant(newActiveTenant.tenantId);

    if (userRole === UserRole.ACCOUNT_USER) {
      this.authStore.update({ userAccessLevel: getUserAccessLevel(newActiveTenant.accessToken) });
    }
  }

  private isSameUserInLocalStorage(userSession: UserSession) {
    const currentUserSession = getUserSessionFromLocalStorage();
    if (!currentUserSession) {
      return false;
    }
    const currentUserIds = currentUserSession.map(s => s.userId).sort();
    const userIds = userSession.map(s => s.userId).sort();
    return userIds.some(id => currentUserIds.includes(id));
  }

  private clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  isLoggedIn() {
    if (this.authQuery.areAccessTokensValid()) {
      return of(true);
    } else if (this.authQuery.isSessionResponsesExist()) {
      return this.refreshAllAccessTokens().pipe(
        map(toBoolean),
        catchError(err => {
          console.error('Error in re-login attempt during login check.', err);
          return of(false);
        })
      );
    } else {
      return of(false);
    }
  }
}
