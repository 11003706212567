/**
 * Datumate Integration Service
 * API's for integration service
 *
 * OpenAPI spec version: 1.2.6
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetRemoteSiteResponse {
  id?: string;
  integration?: GetRemoteSiteResponse.IntegrationEnum;
  name?: string;
}
export namespace GetRemoteSiteResponse {
  export type IntegrationEnum = 'PROCORE';
  export const IntegrationEnum = {
    PROCORE: 'PROCORE' as IntegrationEnum
  };
}
