<div class="row top-row" *ngIf="title">
  <label class="title">{{ title }}</label>
  <label class="title" *ngIf="value | isDefined"> ({{ value.length }})</label>
  <label class="subtitle">{{ subtitle }}</label>
</div>

<div class="row action-bar">
  <div class="row full-container space-between search-bar" *ngIf="!addItemOpened">
    <div class="row full-container search-field">
      <input
        class="field-input-control"
        [formControl]="searchField"
        (keydown)="searchFieldKeyEvent($event)"
      />
      <button mat-icon-button type="button" class="clear-button" *ngIf="searchField.value | isDefined" (click)="$event.stopPropagation(); clearSearch()" title="Clear search" i18n-title="@@nameList.clearSearch">
        <mat-icon>close</mat-icon>
      </button>
      <mat-icon class="search-icon" svgIcon="search"></mat-icon>
    </div>

    <button mat-button type="button" class="light-btn" (click)="clickAddItem()" [disabled]="disabled">+ {{ associateButtonCaption }}</button>
  </div>

  <div class="row full-container" [class.hidden]="!addItemOpened">
    <ng-select
      #selectFieldElement
      class="custom field-select-control name-list-select"
      [items]="items"
      [closeOnSelect]="false"
      [selectOnTab]="true"
      bindLabel="name"
      [compareWith]="itemsCompareFn"
      [markFirst]="false"
      [placeholder]="placeholder"
      notFoundText="No results found"
      i18n-notFoundText="@@nameList.noResultsFound"
      [formControl]="selectField"
      [keyDownFn]="selectFieldKeyEvent"
      (change)="onSelectChange()"
      (close)="addItemOpened = false"
      [appendTo]="isDesktop ? 'body' : ''"
      dropdownPosition="bottom"
    >
      <ng-template ng-option-tmp let-item="item">
        <div class="row item-content" (click)="$event.stopPropagation(); onOptionClick(item)">
          <mat-checkbox color="accent" [checked]="isSelected(item)" (click)="$event.stopPropagation(); onOptionClick(item)"></mat-checkbox> {{ item.name }}
        </div>
      </ng-template>
      <ng-template ng-footer-tmp>
        <div class="row full-container footer-content">
          <div class="row select-all" (click)="$event.stopPropagation(); onSelectAllClick()">
            <mat-checkbox color="accent" [checked]="isAllSelected" [indeterminate]="!isAllSelected && (value | isDefined)" (click)="$event.stopPropagation(); onSelectAllClick()"></mat-checkbox>
            <ng-container i18n="@@general.actionButtons.selectAll">Select all</ng-container>
          </div>
          <button mat-button type="button" *ngIf="addNewButtonCaption" class="light-btn add-button" (click)="clickAddNew($event)">
            {{ addNewButtonCaption }}
          </button>
        </div>
      </ng-template>
    </ng-select>
    <button mat-icon-button type="button" class="close-add-item-button" (click)="closeAddItem($event)" title="Cancel" i18n-title="@@general.actionButtons.cancel">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div class="items-list" [class.desktop]="isDesktop">
  <div class="row space-between item" *ngFor="let item of filteredSelectedItems$ | async">
    {{ item.name }}

    <button mat-icon-button type="button" *ngIf="!disabled" class="delete-button" (click)="clickRemoveSelectedItem(item)" title="Remove" i18n-title="@@general.actionButtons.remove">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
