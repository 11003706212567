/**
 * Activity Management Service
 * Manage Activities and Project Plan (GANTT)
 *
 * OpenAPI spec version: 1.8.8
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateMergeActivityRequest {
  mergeType?: CreateMergeActivityRequest.MergeTypeEnum;
  sourceActivityUniqueId?: string;
}
export namespace CreateMergeActivityRequest {
  export type MergeTypeEnum = 'CURRENT_VERSION' | 'MERGE_FROM_VERSION';
  export const MergeTypeEnum = {
    CURRENTVERSION: 'CURRENT_VERSION' as MergeTypeEnum,
    MERGEFROMVERSION: 'MERGE_FROM_VERSION' as MergeTypeEnum
  };
}
