import { Component } from '@angular/core';
import { FullscreenLoaderService } from './fullscreen-loader.service';

@Component({
  selector: 'fullscreen-loader',
  template: `<div class="fullscreen-loader-wrap" *ngIf="loadingService.loading$ | async">
    <img src="assets/drone-loader.svg" class="loader" />
    <h2 *ngIf="loadingService.loadingTitle">{{ loadingService.loadingTitle }}</h2>
  </div>`,
  styleUrls: ['./fullscreen-loader.component.scss']
})
export class FullscreenLoaderComponent {
  constructor(public loadingService: FullscreenLoaderService) {}
}
