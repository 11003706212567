import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthQuery } from '../../auth/state/auth.query';
import { AccessLevelEnum } from '../../auth/state/auth.utils';
import PERMISSIONS from '../../auth/state/permissions';
import { Task } from '../../detailed-site/state/detailed-site.model';
import { Site } from '../../tenant/tenant.model';
import { TenantQuery } from '../../tenant/tenant.query';
import { User } from '../users-and-teams-dialog/state/users-and-teams.model';
import { ShareResourceDialogComponent, ShareResourceDialogData, SharedResource } from './share-resource-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ShareResourceDialogService {
  constructor(private dialog: MatDialog, private authQuery: AuthQuery, private tenantQuery: TenantQuery) {}

  openDialog(resource: SharedResource, site: Site, task?: Task, createUsersCallback?: (users: User[]) => void) {
    const { users, canCreateUsers } = this.getUserListToShare(site);

    this.dialog.open<ShareResourceDialogComponent, ShareResourceDialogData>(ShareResourceDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      width: '480px',
      maxHeight: '420px',
      data: { users, site, task, showCreateUserButton: canCreateUsers, createUsersCallback, ...resource }
    });
  }

  private getUserListToShare(site: Site) {
    const currentUserId = this.authQuery.getUserId();

    // Show all users that are not operators and not current user
    let users = this.tenantQuery
      .getAllUsers()
      .filter(user => user.id !== currentUserId && user.accessLevel !== AccessLevelEnum.OPERATORUSER);

    const canCreateUsers = this.authQuery.hasAccessLevel(PERMISSIONS.users.create);
    const canUpdateSiteUsers = this.authQuery.hasAccessLevel(PERMISSIONS.sites.update);

    // Remove users not in site if current user can't update the site's users list
    if (!canUpdateSiteUsers) {
      const siteUsers = site.users ?? [];
      const siteUsersIds = siteUsers.map(user => user.id);
      users = users.filter(user => user.accessLevel === AccessLevelEnum.TENANTADMIN || siteUsersIds.includes(user.id));
    }

    return { users, canCreateUsers };
  }
}
