import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceBy } from 'lodash';
import { Observable, catchError, forkJoin, merge, of, switchMap, tap, throwError } from 'rxjs';
import { CreateCrossSectionProgressReportDataRequest } from '../../../../generated/file/model/createCrossSectionProgressReportDataRequest';
import { CreateCrossSectionProgressReportResponse } from '../../../../generated/file/model/createCrossSectionProgressReportResponse';
import { CreateCrossSectionReportDataRequest } from '../../../../generated/file/model/createCrossSectionReportDataRequest';
import { CreateCrossSectionVolumeReportResponse } from '../../../../generated/file/model/createCrossSectionVolumeReportResponse';
import { CreateVolumeGridReportRequest } from '../../../../generated/file/model/createVolumeGridReportRequest';
import { CreateVolumeGridReportResponse } from '../../../../generated/file/model/createVolumeGridReportResponse';
import { GetCrossSectionProgressReportResponse } from '../../../../generated/file/model/getCrossSectionProgressReportResponse';
import { GetCrossSectionProgressReportsDataResponse } from '../../../../generated/file/model/getCrossSectionProgressReportsDataResponse';
import { GetCrossSectionReportDataResponse } from '../../../../generated/file/model/getCrossSectionReportDataResponse';
import { GetCrossSectionReportsDataResponse } from '../../../../generated/file/model/getCrossSectionReportsDataResponse';
import { GetVolumeGridReportsResponse } from '../../../../generated/file/model/getVolumeGridReportsResponse';
import { UpdateCrossSectionReportDataRequest } from '../../../../generated/file/model/updateCrossSectionReportDataRequest';
import { UpdateCrossSectionReportDataResponse } from '../../../../generated/file/model/updateCrossSectionReportDataResponse';
import { UpdateVolumeGridReportDataRequest } from '../../../../generated/file/model/updateVolumeGridReportDataRequest';
import { UpdateVolumeGridReportDataResponse } from '../../../../generated/file/model/updateVolumeGridReportDataResponse';
import { VolumeGridReportModel } from '../../../../generated/file/model/volumeGridReportModel';
import { AuthQuery } from '../../../auth/state/auth.query';
import { AccessLevelEnum, REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { ResourceLinkType } from '../../../shared/resource-links/resource-links.model';
import { ResourceLinksService } from '../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ApiPollingService } from '../../../shared/services/api-polling.service';
import { getServiceUrl } from '../../../shared/utils/backend-services';
import { isDefined } from '../../../shared/utils/general';
import {
  CrossSectionProgressReport,
  CrossSectionVolumeReport,
  ElevationGridHeatmapReport,
  GridReportType,
  REPORT_TYPE_NAMES,
  ReportEntity,
  ReportState,
  ReportType,
  VolumeGridHeatmapReport
} from './detailed-site-reports.model';
import { DetailedSiteReportsQuery } from './detailed-site-reports.query';
import { DetailedSiteReportsStore } from './detailed-site-reports.store';

export class UploadLogosError implements Error {
  name = 'UploadLogosError';
  message = 'Error uploading report logos';
  originalError?: Error;

  constructor(originalError?: Error) {
    this.originalError = originalError;
  }
}

@Injectable({ providedIn: 'root' })
export class DetailedSiteReportsService {
  constructor(
    private reportsStore: DetailedSiteReportsStore,
    private reportsQuery: DetailedSiteReportsQuery,
    private http: HttpClient,
    private authQuery: AuthQuery,
    private analyticsService: AnalyticsService,
    private resourceLinksService: ResourceLinksService,
    private apiPoller: ApiPollingService
  ) {}

  fetchCrossSectionVolumeReport(siteId: string, roadDesignId: string, reportId: string) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.crossSectionReports.read } };
    return this.http
      .get<GetCrossSectionReportDataResponse>(
        `${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}/crossSectionVolumeReports/${reportId}/data`,
        options
      )
      .pipe(
        tap(report => {
          if (isDefined(report)) {
            this.reportsStore.upsertCrossSectionVolumeReports([{ ...report, type: ReportType.CROSS_SECTION_VOLUME }]);
          }
        }),
        catchError(error => {
          console.error('Error fetching cross section volume report', error);
          return of(null);
        })
      );
  }

  fetchCrossSectionProgressReport(siteId: string, reportId: string) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.crossSectionReports.read } };
    return this.http
      .get<GetCrossSectionProgressReportResponse>(
        `${getServiceUrl('file')}/sites/${siteId}/crossSectionProgressReports/${reportId}`,
        options
      )
      .pipe(
        tap(report => {
          if (isDefined(report)) {
            this.reportsStore.upsertCrossSectionProgressReports([{ ...report, type: ReportType.CROSS_SECTION_PROGRESS }]);
          }
        }),
        catchError(error => {
          console.error('Error fetching cross section progress report', error);
          return of(null);
        })
      );
  }

  fetchGridReport(siteId: string, reportId: string) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gridHeatmapReports.read } };
    return this.http
      .get<VolumeGridReportModel>(`${getServiceUrl('file')}/volumeGridReports/sites/${siteId}/reports/${reportId}/data`, options)
      .pipe(
        tap(report => {
          if (isDefined(report)) {
            this.reportsStore.upsertGridReports([
              {
                ...report,
                type: report.gridReportType === GridReportType.EGHR ? ReportType.ELEVATION_GRID_HEATMAP : ReportType.VOLUME_GRID_HEATMAP
              } as VolumeGridHeatmapReport | ElevationGridHeatmapReport
            ]);
          }
        }),
        catchError(error => {
          console.error('Error fetching grid report', error);
          return of(null);
        })
      );
  }

  fetchSiteReports(siteId: string) {
    return forkJoin([
      this.fetchCrossSectionVolumeReports(siteId),
      this.fetchCrossSectionProgressReports(siteId),
      this.fetchGridReports(siteId)
    ]);
  }

  startReportsPolling(siteId: string, pollingFreq?: number) {
    return merge(
      this.pollCrossSectionVolumeReports(siteId, pollingFreq),
      this.pollCrossSectionProgressReports(siteId, pollingFreq),
      this.pollGridReports(siteId, pollingFreq)
    );
  }

  fetchCrossSectionVolumeReports(siteId: string) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.crossSectionReports.read)) {
      return of(null);
    }

    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.crossSectionReports.read } };
    return this.http
      .get<GetCrossSectionReportsDataResponse>(`${getServiceUrl('file')}/sites/${siteId}/crossSectionVolumeReports/data`, options)
      .pipe(
        tap(this.handleCrossSectionVolumeReportsFetch),
        catchError(error => {
          console.error('Error fetching cross section volume reports', error);
          return of(null);
        })
      );
  }

  private pollCrossSectionVolumeReports(siteId: string, pollingFreq?: number) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.crossSectionReports.read)) {
      return of(null);
    }

    return this.apiPoller
      .poll<GetCrossSectionReportsDataResponse>(
        `${getServiceUrl('file')}/sites/${siteId}/crossSectionVolumeReports/data`,
        PERMISSIONS.crossSectionReports.read,
        pollingFreq,
        true
      )
      .pipe(
        tap(this.handleCrossSectionVolumeReportsFetch),
        catchError(error => {
          console.error('Error polling cross section volume reports', error);
          return of(null);
        })
      );
  }

  private handleCrossSectionVolumeReportsFetch = (response: GetCrossSectionReportsDataResponse) => {
    if (isDefined(response?.getCrossSectionReportDataResponse)) {
      const reports = response.getCrossSectionReportDataResponse;

      // Find removed reports and update resource links
      const prevReports = this.reportsQuery.getAllCrossSectionVolumeReports();
      const removedReports = differenceBy(prevReports, reports, 'id');
      removedReports.forEach(report => this.resourceLinksService.removeResource(report.id, ResourceLinkType.REPORT));

      this.reportsStore.upsertCrossSectionVolumeReports(reports.map(report => ({ ...report, type: ReportType.CROSS_SECTION_VOLUME })));
    }
  };

  fetchCrossSectionProgressReports(siteId: string) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.crossSectionReports.read)) {
      return of(null);
    }

    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.crossSectionReports.read } };
    return this.http
      .get<GetCrossSectionProgressReportsDataResponse>(`${getServiceUrl('file')}/sites/${siteId}/crossSectionProgressReports/data`, options)
      .pipe(
        tap(this.handleCrossSectionProgressReportsFetch),
        catchError(error => {
          console.error('Error fetching cross section progress reports', error);
          return of(null);
        })
      );
  }

  private pollCrossSectionProgressReports(siteId: string, pollingFreq?: number) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.crossSectionReports.read)) {
      return of(null);
    }

    return this.apiPoller
      .poll<GetCrossSectionProgressReportsDataResponse>(
        `${getServiceUrl('file')}/sites/${siteId}/crossSectionProgressReports/data`,
        PERMISSIONS.crossSectionReports.read,
        pollingFreq,
        true
      )
      .pipe(
        tap(this.handleCrossSectionProgressReportsFetch),
        catchError(error => {
          console.error('Error polling cross section progress reports', error);
          return of(null);
        })
      );
  }

  private handleCrossSectionProgressReportsFetch = (response: GetCrossSectionProgressReportsDataResponse) => {
    if (isDefined(response?.crossSectionProgressReportsData)) {
      const reports = response.crossSectionProgressReportsData;

      // Find removed reports and update resource links
      const prevReports = this.reportsQuery.getAllCrossSectionProgressReports();
      const removedReports = differenceBy(prevReports, reports, 'id');
      removedReports.forEach(report => this.resourceLinksService.removeResource(report.id, ResourceLinkType.REPORT));

      this.reportsStore.upsertCrossSectionProgressReports(reports.map(report => ({ ...report, type: ReportType.CROSS_SECTION_PROGRESS })));
    }
  };

  fetchGridReports(siteId: string) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.gridHeatmapReports.read)) {
      return of(null);
    }

    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gridHeatmapReports.read } };
    return this.http.get<GetVolumeGridReportsResponse>(`${getServiceUrl('file')}/volumeGridReports/sites/${siteId}`, options).pipe(
      tap(this.handleGridReportsFetch),
      catchError(error => {
        console.error('Error polling grid reports', error);
        return of(null);
      })
    );
  }

  private pollGridReports(siteId: string, pollingFreq?: number) {
    if (!this.authQuery.hasAccessLevel(PERMISSIONS.gridHeatmapReports.read)) {
      return of(null);
    }

    return this.apiPoller
      .poll<GetVolumeGridReportsResponse>(
        `${getServiceUrl('file')}/volumeGridReports/sites/${siteId}`,
        PERMISSIONS.gridHeatmapReports.read,
        pollingFreq,
        true
      )
      .pipe(
        tap(this.handleGridReportsFetch),
        catchError(error => {
          console.error('Error polling grid reports', error);
          return of(null);
        })
      );
  }

  private handleGridReportsFetch = (response: GetVolumeGridReportsResponse) => {
    if (isDefined(response?.volumeGridReports)) {
      const reports = response.volumeGridReports;

      // Find removed reports and update resource links
      const prevReports = this.reportsQuery.getAllGridReports();
      const removedReports = differenceBy(prevReports, reports, 'id');
      removedReports.forEach(report => this.resourceLinksService.removeResource(report.id, ResourceLinkType.REPORT));

      this.reportsStore.upsertGridReports(
        reports.map(
          report =>
            ({
              ...report,
              type: report.gridReportType === GridReportType.EGHR ? ReportType.ELEVATION_GRID_HEATMAP : ReportType.VOLUME_GRID_HEATMAP
            } as VolumeGridHeatmapReport | ElevationGridHeatmapReport)
        )
      );
    }
  };

  generateCrossSectionVolumeReport(request: CreateCrossSectionReportDataRequest, logoFiles: { [name: string]: File }) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.crossSectionReports.create } };
    return this.http.post(`${getServiceUrl('file')}/crossSectionVolumeReports/generateCrossSectionVolumeReport`, request, options).pipe(
      tap((response: CreateCrossSectionVolumeReportResponse) => {
        if (response) {
          const report: CrossSectionVolumeReport = {
            ...request,
            id: response.reportId,
            type: ReportType.CROSS_SECTION_VOLUME,
            creationTime: new Date(),
            lastModifiedTime: new Date(),
            state: ReportState.PROCESSING
          };
          this.reportsStore.upsertCrossSectionVolumeReports([report]);
          this.analyticsService.generateReport(report);
        }
      }),
      switchMap((response: CreateCrossSectionVolumeReportResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: response.consultantLogoURL,
            customerLogo: response.customerLogoURL,
            signatureLogo: response.signatureLogoURL
          };
          return this.updateReportLogos(logoUrls, logoFiles);
        }

        return of(null);
      })
    );
  }

  updateCrossSectionVolumeReport(
    siteId: string,
    roadDesignId: string,
    reportId: string,
    request: UpdateCrossSectionReportDataRequest,
    logoFiles: { [name: string]: File }
  ) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.crossSectionReports.update } };
    return this.http
      .put(`${getServiceUrl('file')}/sites/${siteId}/roadDesigns/${roadDesignId}/crossSectionVolumeReports/${reportId}`, request, options)
      .pipe(
        tap((response: UpdateCrossSectionReportDataResponse) => {
          if (response) {
            const report: CrossSectionVolumeReport = {
              ...request,
              id: response.reportId,
              type: ReportType.CROSS_SECTION_VOLUME
            };
            this.reportsStore.upsertCrossSectionVolumeReports([report]);
            this.analyticsService.editReport(report);
          }
        }),
        switchMap((response: UpdateCrossSectionReportDataResponse) => {
          if (response) {
            const logoUrls = {
              consultantLogo: logoFiles.consultantLogo ? response.putConsultantLogoURL : response.deleteConsultantLogoURL,
              customerLogo: logoFiles.customerLogo ? response.putCustomerLogoURL : response.deleteCustomerLogoURL,
              signatureLogo: logoFiles.signatureLogo ? response.putSignatureLogoURL : response.deleteSignatureLogoURL
            };
            return this.updateReportLogos(logoUrls, logoFiles, true);
          }

          return of(null);
        }),
        switchMap(() => this.fetchCrossSectionVolumeReport(siteId, roadDesignId, reportId))
      );
  }

  generateCrossSectionProgressReport(request: CreateCrossSectionProgressReportDataRequest, logoFiles: { [name: string]: File }) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.crossSectionReports.create } };
    return this.http.post(`${getServiceUrl('file')}/crossSectionProgressReports/generateCrossSectionProgressReport`, request, options).pipe(
      tap((response: CreateCrossSectionProgressReportResponse) => {
        if (response) {
          const report: CrossSectionProgressReport = {
            ...request,
            designs: { designs: request.designs?.ids?.map(id => ({ id })) },
            tasks: { ...request.tasks, tasks: request.tasks?.ids?.map(id => ({ id })) },
            id: response.reportId,
            type: ReportType.CROSS_SECTION_PROGRESS,
            creationTime: new Date(),
            lastModifiedTime: new Date(),
            state: ReportState.PROCESSING
          };
          this.reportsStore.upsertCrossSectionProgressReports([report]);
          this.analyticsService.generateReport(report);
        }
      }),
      switchMap((response: CreateCrossSectionProgressReportResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: response.consultantLogoURL,
            customerLogo: response.customerLogoURL,
            signatureLogo: response.signatureLogoURL
          };
          return this.updateReportLogos(logoUrls, logoFiles);
        }

        return of(null);
      })
    );
  }

  updateCrossSectionProgressReport(
    siteId: string,
    reportId: string,
    request: UpdateCrossSectionReportDataRequest,
    logoFiles: { [name: string]: File }
  ) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.crossSectionReports.update } };
    return this.http.put(`${getServiceUrl('file')}/sites/${siteId}/crossSectionProgressReports/${reportId}`, request, options).pipe(
      tap((response: UpdateCrossSectionReportDataResponse) => {
        if (response) {
          const report: CrossSectionProgressReport = {
            ...request,
            id: response.reportId,
            type: ReportType.CROSS_SECTION_PROGRESS
          };
          this.reportsStore.upsertCrossSectionProgressReports([report]);
          this.analyticsService.editReport(report);
        }
      }),
      switchMap((response: UpdateCrossSectionReportDataResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: logoFiles.consultantLogo ? response.putConsultantLogoURL : response.deleteConsultantLogoURL,
            customerLogo: logoFiles.customerLogo ? response.putCustomerLogoURL : response.deleteCustomerLogoURL,
            signatureLogo: logoFiles.signatureLogo ? response.putSignatureLogoURL : response.deleteSignatureLogoURL
          };
          return this.updateReportLogos(logoUrls, logoFiles, true);
        }

        return of(null);
      }),
      switchMap(() => this.fetchCrossSectionProgressReport(siteId, reportId))
    );
  }

  generateGridReport(request: CreateVolumeGridReportRequest, logoFiles: { [name: string]: File }) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gridHeatmapReports.create } };
    return this.http.post(`${getServiceUrl('file')}/volumeGridReports/generateVolumeGridReport`, request, options).pipe(
      tap((response: CreateVolumeGridReportResponse) => {
        if (response) {
          const report: VolumeGridHeatmapReport | ElevationGridHeatmapReport = {
            ...request,
            id: response.reportId,
            type: request.gridReportType === GridReportType.EGHR ? ReportType.ELEVATION_GRID_HEATMAP : ReportType.VOLUME_GRID_HEATMAP,
            creationTime: new Date(),
            lastModifiedTime: new Date(),
            state: ReportState.PROCESSING
          };
          this.reportsStore.upsertGridReports([report]);
          this.analyticsService.generateReport(report);
        }
      }),
      switchMap((response: CreateVolumeGridReportResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: response.consultantLogoURL,
            customerLogo: response.customerLogoURL,
            signatureLogo: response.signatureLogoURL
          };
          return this.updateReportLogos(logoUrls, logoFiles);
        }

        return of(null);
      })
    );
  }

  updateGridReport(siteId: string, reportId: string, request: UpdateVolumeGridReportDataRequest, logoFiles: { [name: string]: File }) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gridHeatmapReports.update } };
    return this.http.put(`${getServiceUrl('file')}/volumeGridReports/sites/${siteId}/reports/${reportId}`, request, options).pipe(
      tap((response: UpdateVolumeGridReportDataResponse) => {
        if (response) {
          const report: VolumeGridHeatmapReport = {
            ...request,
            id: response.reportId,
            type: ReportType.VOLUME_GRID_HEATMAP
          };
          this.reportsStore.upsertGridReports([report]);
          this.analyticsService.editReport(report);
        }
      }),
      switchMap((response: UpdateVolumeGridReportDataResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: logoFiles.consultantLogo ? response.putConsultantLogoURL : response.deleteConsultantLogoURL,
            customerLogo: logoFiles.customerLogo ? response.putCustomerLogoURL : response.deleteCustomerLogoURL,
            signatureLogo: logoFiles.signatureLogo ? response.putSignatureLogoURL : response.deleteSignatureLogoURL
          };
          return this.updateReportLogos(logoUrls, logoFiles, true);
        }

        return of(null);
      }),
      switchMap(() => this.fetchGridReport(siteId, reportId))
    );
  }

  private updateReportLogos(urls: { [name: string]: string }, logoFiles: { [name: string]: File }, editMode = false) {
    const imageUploadRequests: Observable<any>[] = [];
    Object.entries(logoFiles).forEach(([name, file]) => {
      if (file) {
        imageUploadRequests.push(this.http.put(urls[name], file));
      } else if (editMode) {
        imageUploadRequests.push(this.http.delete(urls[name]));
      }
    });

    if (imageUploadRequests.length === 0) {
      return of(null);
    }

    return forkJoin(imageUploadRequests).pipe(catchError(error => throwError(() => new UploadLogosError(error))));
  }

  deleteReport(report: ReportEntity) {
    let url: string;
    let accessLevel: AccessLevelEnum;
    switch (report.type) {
      case ReportType.CROSS_SECTION_VOLUME:
        url = `${getServiceUrl('file')}/sites/${report.siteId}/crossSectionVolumeReports/${report.id}`;
        accessLevel = PERMISSIONS.crossSectionReports.delete;
        break;
      case ReportType.CROSS_SECTION_PROGRESS:
        url = `${getServiceUrl('file')}/sites/${report.siteId}/crossSectionProgressReports/${report.id}`;
        accessLevel = PERMISSIONS.crossSectionReports.delete;
        break;
      case ReportType.ELEVATION_GRID_HEATMAP:
      case ReportType.VOLUME_GRID_HEATMAP:
        url = `${getServiceUrl('file')}/volumeGridReports/sites/${report.siteId}/reports/${report.id}`;
        accessLevel = PERMISSIONS.gridHeatmapReports.delete;
        break;
    }

    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: accessLevel } };
    return this.http.delete(url, options).pipe(
      tap(() => {
        this.reportsStore.deleteReport(report);
        this.resourceLinksService.removeResource(report.id, ResourceLinkType.REPORT);
        this.analyticsService.deleteReport(report);
      })
    );
  }

  generateReportURL(report: ReportEntity) {
    const activeTenantId = this.authQuery.getActiveTenantId();
    const reportType = REPORT_TYPE_NAMES[report.type];
    return `/${activeTenantId}/sites/${report.siteId}/reports/${reportType}/${report.id}`;
  }

  openReport(report: ReportEntity) {
    this.analyticsService.showReport(report);

    const url = this.generateReportURL(report);
    window.open(url, '_blank');
  }

  resetStore() {
    this.reportsStore.resetStore();
  }
}
