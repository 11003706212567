/**
 * Measurements Management Service
 * APIs document (version: 2.0.5)
 *
 * OpenAPI spec version: 2.0.5
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BaseSurface {
  elevation?: number;
  id?: string;
  isTarget?: boolean;
  type?: BaseSurface.TypeEnum;
}
export namespace BaseSurface {
  export type TypeEnum = 'TASK' | 'DESIGN' | 'INTERPOLATED' | 'MINELEVATION' | 'CUSTOMELEVATION';
  export const TypeEnum = {
    TASK: 'TASK' as TypeEnum,
    DESIGN: 'DESIGN' as TypeEnum,
    INTERPOLATED: 'INTERPOLATED' as TypeEnum,
    MINELEVATION: 'MINELEVATION' as TypeEnum,
    CUSTOMELEVATION: 'CUSTOMELEVATION' as TypeEnum
  };
}
