import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'folding-cube-loader',
  template: `
    <div class="sk-folding-cube" [ngStyle]="{ width: size + 'px', height: size + 'px' }">
      <div class="sk-cube1 sk-cube"></div>
      <div class="sk-cube2 sk-cube"></div>
      <div class="sk-cube4 sk-cube"></div>
      <div class="sk-cube3 sk-cube"></div>
    </div>
  `,
  styleUrls: ['./folding-cube-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoldingCubeLoaderComponent implements OnInit {
  @Input() size: number;

  constructor() {}

  ngOnInit() {}
}
