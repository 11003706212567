/**
 * Measurements Management Service
 * APIs document (version: 2.0.5)
 *
 * OpenAPI spec version: 2.0.5
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BaseSurface } from './baseSurface';
import { CoordinateBlock } from './coordinateBlock';
import { CoordinateModel } from './coordinateModel';

export interface GetMeasurementResponse {
  baseSurface?: BaseSurface;
  coordinates?: Array<CoordinateModel>;
  createdBy?: string;
  creationTime?: Date;
  dataUrl?: string;
  dataVersion?: number;
  groupId?: string;
  id?: string;
  lastModifiedTime?: Date;
  layerId?: string;
  name?: string;
  positions?: Array<CoordinateBlock>;
  sourceModel?: GetMeasurementResponse.SourceModelEnum;
  taskId?: string;
  type?: GetMeasurementResponse.TypeEnum;
  values?: { [key: string]: number };
}
export namespace GetMeasurementResponse {
  export type SourceModelEnum = 'DSM' | 'DTM' | 'FLAT';
  export const SourceModelEnum = {
    DSM: 'DSM' as SourceModelEnum,
    DTM: 'DTM' as SourceModelEnum,
    FLAT: 'FLAT' as SourceModelEnum
  };
  export type TypeEnum = 'DISTANCE' | 'CROSS_SECTION' | 'ANGLE' | 'POINT' | 'AREA' | 'VOLUME';
  export const TypeEnum = {
    DISTANCE: 'DISTANCE' as TypeEnum,
    CROSS_SECTION: 'CROSS_SECTION' as TypeEnum, // DON'T REMOVE THE UNDER SCORE (CROSS_SECTION => CROSSSECTION)
    ANGLE: 'ANGLE' as TypeEnum,
    POINT: 'POINT' as TypeEnum,
    AREA: 'AREA' as TypeEnum,
    VOLUME: 'VOLUME' as TypeEnum
  };
}
