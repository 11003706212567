import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { isDefined } from '../utils/general';

interface SnackBarConfig {
  actionButtonLabel?: string;
  hideActionButton?: boolean;
  duration?: number;
  panelClass?: string | string[];
}

export interface SnackBarRef {
  dismiss: () => void;
  onAction: () => Observable<void>;
}

const DEFAULT_CONFIG: SnackBarConfig = {
  actionButtonLabel: $localize`:@@general.snackbarOKButton:OK`
};

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  constructor(private matSnackbar: MatSnackBar) {}

  open(message: string, config: SnackBarConfig = DEFAULT_CONFIG): SnackBarRef {
    const actionButtonLabel = config.hideActionButton ? undefined : config?.actionButtonLabel || DEFAULT_CONFIG.actionButtonLabel;

    // Only add relevant config params if exist, otherwise undefined changes default MatSnackBar behaviour
    const matSnackbarConfig: MatSnackBarConfig = {};
    if (isDefined(config.panelClass)) {
      matSnackbarConfig.panelClass = config.panelClass;
    }
    if (isDefined(config.duration)) {
      matSnackbarConfig.duration = config.duration;
    }

    const snackbarRef = this.matSnackbar.open(message, actionButtonLabel, matSnackbarConfig);

    return { dismiss: () => snackbarRef.dismiss(), onAction: snackbarRef.onAction };
  }

  openError(userMessage: string, context: Object, config?: SnackBarConfig): SnackBarRef;
  openError(userMessage: string, consoleMessage: string, context: Object, config?: SnackBarConfig): SnackBarRef;
  openError(userMessage: string, consoleMessageOrContext: string | Object, context?: Object, config: SnackBarConfig = DEFAULT_CONFIG) {
    let consoleMessage: string;
    if (typeof consoleMessageOrContext === 'object') {
      // No console message provider - use user message
      context = consoleMessageOrContext;
      consoleMessage = userMessage;
    } else {
      consoleMessage = consoleMessageOrContext;
    }

    console.error(consoleMessage, context);
    return this.open(userMessage, config);
  }
}
