/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.1.2
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetCrossSectionReportDataResponse {
  approvedBy?: string;
  comments?: string;
  consultantLogoURL?: string;
  creationTime?: Date;
  customerLogoURL?: string;
  endStation?: number;
  factorCut?: number;
  factorFill?: number;
  id?: string;
  lastModifiedTime?: Date;
  name?: string;
  offset1?: number;
  offset2?: number;
  offset3?: number;
  roadDesignId?: string;
  sectionInterval?: number;
  sectionMaxWidthLeft?: number;
  sectionMaxWidthRight?: number;
  showBreakLineLabels?: boolean;
  showOneChartPerRow?: boolean;
  signatureLogoURL?: string;
  siteId?: string;
  startStation?: number;
  state?: GetCrossSectionReportDataResponse.StateEnum;
  stationNamingFormatType?: GetCrossSectionReportDataResponse.StationNamingFormatTypeEnum;
  surface1?: GetCrossSectionReportDataResponse.Surface1Enum;
  surface2?: GetCrossSectionReportDataResponse.Surface2Enum;
  surface3?: GetCrossSectionReportDataResponse.Surface3Enum;
  taskId?: string;
}
export namespace GetCrossSectionReportDataResponse {
  export type StateEnum = 'PROCESSING' | 'READY' | 'FAILED';
  export const StateEnum = {
    PROCESSING: 'PROCESSING' as StateEnum,
    READY: 'READY' as StateEnum,
    FAILED: 'FAILED' as StateEnum
  };
  export type StationNamingFormatTypeEnum = 'FORMAT_A' | 'FORMAT_B' | 'FORMAT_C';
  export const StationNamingFormatTypeEnum = {
    A: 'FORMAT_A' as StationNamingFormatTypeEnum,
    B: 'FORMAT_B' as StationNamingFormatTypeEnum,
    C: 'FORMAT_C' as StationNamingFormatTypeEnum
  };
  export type Surface1Enum = 'TOP' | 'ASBUILD' | 'BOTTOM' | 'OFFSET';
  export const Surface1Enum = {
    TOP: 'TOP' as Surface1Enum,
    ASBUILD: 'ASBUILD' as Surface1Enum,
    BOTTOM: 'BOTTOM' as Surface1Enum,
    OFFSET: 'OFFSET' as Surface1Enum
  };
  export type Surface2Enum = 'TOP' | 'ASBUILD' | 'BOTTOM' | 'OFFSET';
  export const Surface2Enum = {
    TOP: 'TOP' as Surface2Enum,
    ASBUILD: 'ASBUILD' as Surface2Enum,
    BOTTOM: 'BOTTOM' as Surface2Enum,
    OFFSET: 'OFFSET' as Surface2Enum
  };
  export type Surface3Enum = 'TOP' | 'ASBUILD' | 'BOTTOM' | 'OFFSET';
  export const Surface3Enum = {
    TOP: 'TOP' as Surface3Enum,
    ASBUILD: 'ASBUILD' as Surface3Enum,
    BOTTOM: 'BOTTOM' as Surface3Enum,
    OFFSET: 'OFFSET' as Surface3Enum
  };
}
