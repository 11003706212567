import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { Cartesian3 } from 'angular-cesium';
import { at, differenceBy } from 'lodash';
import { concat, defer, forkJoin, from, merge, of } from 'rxjs';
import { catchError, filter, map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GetAllArtifactsResponse } from '../../../generated/file/model/getAllArtifactsResponse';
import { GetAllImagesResponse } from '../../../generated/file/model/getAllImagesResponse';
import { GetAllTasksResponse } from '../../../generated/fms/model/getAllTasksResponse';
import { TaskResponse } from '../../../generated/fms/model/taskResponse';
import { GetRemoteSitesResponse } from '../../../generated/integration/model/getRemoteSitesResponse';
import { GetBatchResponse } from '../../../generated/jms/model/getBatchResponse';
import { CreateMeasuredPointsRequest } from '../../../generated/mms/model/createMeasuredPointsRequest';
import { CreateMeasuredPointsResponse } from '../../../generated/mms/model/createMeasuredPointsResponse';
import { GetAllGcpsResponse } from '../../../generated/mms/model/getAllGcpsResponse';
import { GetAllMeasuredPointsResponse } from '../../../generated/mms/model/getAllMeasuredPointsResponse';
import { UpdateMeasuredPointsRequest } from '../../../generated/mms/model/updateMeasuredPointsRequest';
import { UpdateMeasuredPointsResponse } from '../../../generated/mms/model/updateMeasuredPointsResponse';
import { CloudFrontPreSignedPolicy } from '../../../generated/tenant/model/cloudFrontPreSignedPolicy';
import { AuthQuery } from '../../auth/state/auth.query';
import { ACCOUNT_USER_ACCESS_LEVELS, REQUIRED_ACCESS_LEVEL_HEADER } from '../../auth/state/auth.utils';
import PERMISSIONS from '../../auth/state/permissions';
import { ResourceLinkType } from '../../shared/resource-links/resource-links.model';
import { ResourceLinksService } from '../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { ApiPollingService } from '../../shared/services/api-polling.service';
import { DeviceService } from '../../shared/services/device.service';
import { LocaleService } from '../../shared/services/locale.service';
import { WebsocketService } from '../../shared/services/websocket.service';
import { ResourceType, SharedResource } from '../../shared/share-resource-dialog/share-resource-dialog.component';
import { ShareResourceDialogService } from '../../shared/share-resource-dialog/share-resource-dialog.service';
import { User } from '../../shared/users-and-teams-dialog/state/users-and-teams.model';
import { getServiceUrl } from '../../shared/utils/backend-services';
import { MapStyle } from '../../shared/utils/cesium-common';
import { isDefined } from '../../shared/utils/general';
import { Cartographic, GeoUtils, Rectangle } from '../../shared/utils/geo';
import { DistanceUnitsEnum, convertToMeters } from '../../shared/utils/unit-conversion';
import { IntegrationProject, Site, SiteAssociations } from '../../tenant/tenant.model';
import { TenantQuery } from '../../tenant/tenant.query';
import { GCPItem } from '../../upload-wizard/gcp-marking/state/gcp.model';
import { WizardDialogService } from '../../upload-wizard/services/wizard-dialog.service';
import { UploadWizardMode } from '../../upload-wizard/state/upload-wizard.model';
import { UploadWizardService } from '../../upload-wizard/state/upload-wizard.service';
import { Step } from '../../upload-wizard/wizard-stepper/steps';
import { TerrainProviderService } from '../services/terrain-provider.service';
import { TerrainSamplingService } from '../services/terrain-sampling.service';
import { ContourOptions, ElevationOptions } from '../site-map/map-overlays/elevation-contour.service';
import { DetailedSiteActivitiesService } from './detailed-site-activities/detailed-site-activities.service';
import { DetailedSiteDesignsService } from './detailed-site-designs/detailed-site-designs.service';
import { MeasurementType } from './detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from './detailed-site-entities/detailed-site-entities.query';
import { DetailedSiteEntitiesService } from './detailed-site-entities/detailed-site-entities.service';
import { DetailedSiteReportsService } from './detailed-site-reports/detailed-site-reports.service';
import {
  FlightSourceEnum,
  GEOREF_METHODS_WITH_GCP,
  GeorefMethodEnum,
  Image,
  MapTextureType,
  MeasuredPoints,
  OverlaysEnum,
  TabEnum,
  Task,
  TaskStateEnum
} from './detailed-site.model';
import { DetailedSiteQuery } from './detailed-site.query';
import { DetailedSiteStore } from './detailed-site.store';
import { generateViewerCredentialsQueryParams } from './detailed-site.utils';

interface WebsocketTaskProgressResponseData {
  siteId: string;
  taskId: string;
  state: TaskStateEnum;
  stage: string;
  substage: string;
  progress: number;
}

@Injectable({ providedIn: 'root' })
export class DetailedSiteService {
  constructor(
    private siteStore: DetailedSiteStore,
    private apiPoller: ApiPollingService,
    private router: Router,
    private http: HttpClient,
    private tenantQuery: TenantQuery,
    private siteQuery: DetailedSiteQuery,
    private authQuery: AuthQuery,
    private terrainProviderService: TerrainProviderService,
    private wizardDialog: WizardDialogService,
    private uploadWizardService: UploadWizardService,
    private designsService: DetailedSiteDesignsService,
    private siteActivitiesService: DetailedSiteActivitiesService,
    private reportsService: DetailedSiteReportsService,
    private siteEntitiesService: DetailedSiteEntitiesService,
    private siteEntitiesQuery: DetailedSiteEntitiesQuery,
    private analyticsService: AnalyticsService,
    private device: DeviceService,
    private dialog: MatDialog,
    private terrainSampling: TerrainSamplingService,
    private shareResourceDialog: ShareResourceDialogService,
    private resourceLinksService: ResourceLinksService,
    private websocketService: WebsocketService,
    private localeService: LocaleService
  ) {}

  fetchViewerCredentials(siteId: string) {
    return this.http
      .get(`${getServiceUrl('tenant')}/sites/${siteId}/getViewerSignedPolicy`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.viewerCredentialsRead }
      })
      .pipe(
        tap((viewerCredentials: CloudFrontPreSignedPolicy) => {
          this.siteStore.setViewerCredentials(viewerCredentials);
        })
      );
  }

  initSite(siteId: string, taskId?: string) {
    this.siteStore.setLoading(true);
    return concat(
      forkJoin([
        this.fetchViewerCredentials(siteId),
        this.fetchSiteDetails(siteId),
        this.tenantQuery.selectLoading().pipe(
          filter(loading => !loading),
          take(1)
        )
      ]).pipe(
        tap(([viewerCredentials, site]: [CloudFrontPreSignedPolicy, Site, boolean]) => {
          this.analyticsService.enterToSite(site.id, site.name);

          this.siteEntitiesService.init(site);
          this.designsService.init(site, viewerCredentials);
          this.siteActivitiesService.init();
        })
      ),
      forkJoin([
        this.siteEntitiesService.fetchLayers(siteId),
        this.siteEntitiesService.fetchGroups(siteId),
        this.siteEntitiesService.fetchSiteAnalytics(siteId),
        this.siteEntitiesService.fetchSiteAnnotations(siteId),
        this.siteEntitiesService.fetchModelEdits(siteId),
        this.designsService.getDesignCategories(siteId),
        this.designsService.getSiteDesigns(siteId),
        this.reportsService.fetchSiteReports(siteId),
        this.siteActivitiesService.fetchSiteProjectPlans(siteId),
        this.fetchMeasuredPoints(siteId),
        this.fetchSiteTasks(siteId).pipe(
          tap((response: GetAllTasksResponse) => {
            this.upsertTasks(response.tasks);
            this.subscribeToTasksProgress(siteId);
            this.makeTaskActiveWithValidation(siteId, taskId);
          })
        )
      ]),
      defer(() => {
        this.siteStore.setLoading(false);
        return of(null);
      })
    );
  }

  private subscribeToTasksProgress(siteId: string) {
    const websocketMessage = { action: 'siteProgress', data: { siteId } };
    this.websocketService.sendMessage(websocketMessage).subscribe({
      error: error => {
        console.error('Failed while requesting site progress', websocketMessage, error);
      }
    });

    this.websocketService.observe<WebsocketTaskProgressResponseData>('siteProgress').subscribe({
      next: taskProgressResponse => {
        if (isDefined(taskProgressResponse?.errorMessage)) {
          const errorResponse = taskProgressResponse;
          console.error('Error in site progress', errorResponse);
          return;
        }

        if (isDefined(taskProgressResponse?.data)) {
          const task = this.siteQuery.getTask(taskProgressResponse.data.taskId);
          if (!task) {
            return;
          }

          const id = taskProgressResponse.data.taskId;
          const state = isDefined(taskProgressResponse.data.state) ? taskProgressResponse.data.state : task.state;
          const progress =
            isDefined(taskProgressResponse.data.progress) &&
            taskProgressResponse.data.progress >= 0 &&
            taskProgressResponse.data.progress < 100
              ? taskProgressResponse.data.progress
              : null;
          this.updateTask({ id, progress, state });
        }
      },
      error: err => {
        console.error('Failed while observing site progress', err);
      }
    });
  }

  unsubscribeTasksProgress() {
    this.websocketService.sendMessage({ action: 'siteProgressUnsub' }).subscribe();
  }

  private fetchSiteDetails(siteId: string) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.read } };
    return this.http.get(`${getServiceUrl('tenant')}/sites/${siteId}`, options).pipe(
      map((site: Site) => {
        // If site CS is missing units - put site units
        if (site?.coordinateSystem && !site.coordinateSystem.units) {
          site = { ...site, coordinateSystem: { ...site.coordinateSystem, units: site.units } };
        }

        // Save site to store
        this.siteStore.setSite(site);

        // Save site offset
        if (site.siteOffset !== undefined && site.siteOffset !== null) {
          this.setTerrainHeightOffset(site.siteOffset);
        }

        // Init default spacing by site units
        let majorSpacing: number;
        let minorSpacing: number;
        if (site.units === DistanceUnitsEnum.METER) {
          majorSpacing = 1;
          minorSpacing = 0.25;
        } else {
          majorSpacing = convertToMeters(3, site.units);
          minorSpacing = convertToMeters(1, site.units);
        }
        this.updateContourOverlay({ majorSpacing, minorSpacing });

        return site;
      }),
      switchMap(site => this.fetchSiteAssociations(site))
    );
  }

  private fetchSiteAssociations(site: Site) {
    return this.http
      .get(`${getServiceUrl('integration')}/association/sites/${site.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.sites.read }
      })
      .pipe(
        map((response: GetRemoteSitesResponse) => {
          if (!isDefined(response?.remoteSites)) {
            return site;
          }

          const associations = response.remoteSites.reduce((sum, project: IntegrationProject) => {
            sum[project.integration] = project;
            return sum;
          }, {} as SiteAssociations);

          const updatedSite: Site = { ...site, associations };
          this.updateSite(updatedSite);

          return updatedSite;
        })
      );
  }

  updateSiteBounds(siteBounds: Rectangle) {
    this.siteStore.updateSiteBounds(siteBounds);
  }

  updateSite(site: Partial<Site>) {
    this.siteStore.updateSite(site);
  }

  setMapLoading(mapLoading: boolean) {
    this.siteStore.setMapLoading(mapLoading);
  }

  setMapDiffTask(task: Task) {
    this.siteStore.updateMapDiffTask(task);
  }

  setIsMapFullScreen(isFullScreen: boolean) {
    this.siteStore.setIsMapFullScreen(isFullScreen);
  }

  setSiteImageryLayer(imageryLayer: any) {
    this.siteStore.updateSiteImageryLayer(imageryLayer);
  }

  updateImageViewerImages(position: Cartesian3, task: Task) {
    if (task?.images) {
      let imageViewerImages: Image[] = [];
      if (task.images.length > 0) {
        const imagePositions = task.images.map(image => Cesium.Cartesian3.fromDegrees(image.longitude, image.latitude, image.altitude));
        const nearestPositionsIndexList = GeoUtils.nearestPositionsIndexList(position, imagePositions);
        imageViewerImages = at(task.images, nearestPositionsIndexList);
        this.setMapPositionPin(position);
        this.setCursorLocation(GeoUtils.cartesian3ToDeg(position), true);
      }

      this.siteStore.updateImageViewerImages(imageViewerImages);
    }
  }

  toggleImageViewerShow(show: boolean) {
    this.siteStore.toggleImageViewerShow(show);

    if (!show && !this.device.isTablet()) {
      this.setMapPositionPin(null);
    }
  }

  setMapPositionPin(position: Cartesian3) {
    this.siteStore.updateMapPositionPin(position);
  }
  setMyPositionPin(position: Cartesian3) {
    this.siteStore.updateMyPositionPin(position);
    this.analyticsService.setWatchingLocation(!!position, position ? GeoUtils.cartesian3ToDeg(position) : null);
  }

  setGoToPin(position: Cartesian3) {
    this.siteStore.updateGoToPin(position);
  }

  setCursorLocation(position: Cartographic, overridePositionPin = false) {
    // Don't hide cursor location if position pin is displayed
    if (!overridePositionPin && this.siteQuery.getMapPositionPin()) {
      return;
    }

    this.siteStore.updateCursorLocation(position);
  }

  private fetchSiteTasks(siteId: string) {
    return this.http.get(`${getServiceUrl('fms')}/tasks?site=${siteId}`, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flights.read }
    });
  }

  private fetchMeasuredPoints(siteId: string) {
    return this.tenantQuery.gradeCheckingFeatureFlag$
      .pipe(
        switchMap(gradeCheckingFeatureFlag =>
          gradeCheckingFeatureFlag
            ? this.http.get(`${getServiceUrl('mms')}/sites/${siteId}/measuredPoints`, {
                headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.measuredPoints.read }
              })
            : of(null)
        ),
        take(1)
      )
      .pipe(
        tap((response: GetAllMeasuredPointsResponse) => {
          if (response?.measuredPoints && response.measuredPoints.length > 0) {
            this.siteStore.addMeasuredPointsList(response.measuredPoints);
          }
        })
      );
  }

  fetchTaskImages(taskId: string, isLinked = true) {
    return this.http.get(`${getServiceUrl('file')}/images`, {
      params: { taskId, isLinked },
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.images.read }
    });
  }

  fetchImageViewerImages(taskId: string) {
    const task = this.siteQuery.getTask(taskId);
    if (task?.images) {
      return of({ images: task.images });
    }

    return this.fetchTaskImages(taskId, true).pipe(
      tap((response: GetAllImagesResponse) => {
        this.siteStore.setTaskImages(taskId, response?.images || []);
      })
    );
  }

  upsertTasks = (tasks: TaskResponse[]) => {
    const tasksWithName = tasks.map(task => ({
      ...task,
      flightDateLabel: this.localeService.formatDateName({ date: task.missionFlightDate })
    }));
    this.siteStore.upsertTasks(tasksWithName);
  };

  saveMeasuredPoints(measuredPoints: MeasuredPoints) {
    const request: CreateMeasuredPointsRequest = {
      measurementDate: measuredPoints.measurementDate,
      points: measuredPoints.points
    };
    const siteId = this.siteQuery.getSiteId();
    return this.http
      .post(`${getServiceUrl('mms')}/sites/${siteId}/measuredPoints`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.measuredPoints.create }
      })
      .pipe(
        map((response: CreateMeasuredPointsResponse) => {
          const newMeasuredPoints: MeasuredPoints = { ...measuredPoints, id: response.id, points: response.points };
          if (response?.id) {
            this.siteStore.addMeasuredPointsList([newMeasuredPoints]);
          }

          return newMeasuredPoints;
        })
      );
  }

  deleteMeasuredPoints(measuredPoints: MeasuredPoints) {
    const siteId = this.siteQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('mms')}/sites/${siteId}/measuredPoints/${measuredPoints.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.measuredPoints.delete }
      })
      .pipe(tap(() => this.siteStore.deleteMeasuredPoints(measuredPoints)));
  }

  updateMeasuredPoints(measuredPoints: MeasuredPoints) {
    const request: UpdateMeasuredPointsRequest = {
      measurementDate: measuredPoints.measurementDate,
      points: measuredPoints.points
    };
    const siteId = this.siteQuery.getSiteId();
    return this.http
      .put(`${getServiceUrl('mms')}/sites/${siteId}/measuredPoints/${measuredPoints.id}`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.measuredPoints.update }
      })
      .pipe(
        map((response: UpdateMeasuredPointsResponse) => {
          const updatedMeasuredPoints: MeasuredPoints = { ...measuredPoints, points: response.points };
          this.siteStore.updateMeasuredPoints(updatedMeasuredPoints);
          return updatedMeasuredPoints;
        })
      );
  }

  updateTask(updatedTask: Partial<Task>) {
    this.siteStore.updateTask(updatedTask);

    // If current task is updated and can't be active - make latest task active
    if (this.siteQuery.getActiveTaskId() === updatedTask.id) {
      const siteId = this.siteQuery.getSiteId();
      this.makeTaskActiveWithValidation(siteId, updatedTask.id);
    }
  }

  deleteTask(task: Task) {
    const isDeletingActiveTask = this.siteQuery.getActiveTaskId() === task.id;

    const tenantId = this.authQuery.getActiveTenantId();
    const siteId = this.siteQuery.getSiteId();
    return this.http
      .delete(`${getServiceUrl('fms')}/tenants/${tenantId}/sites/${siteId}/tasks/${task.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flights.delete }
      })
      .pipe(
        tap(() => {
          this.siteStore.deleteTask(task.id);
          this.resourceLinksService.removeResource(task.id, ResourceLinkType.TASK);
          this.analyticsService.deleteFlight(task);

          if (isDeletingActiveTask) {
            this.makeTaskActiveWithValidation(siteId);
          }
        })
      );
  }

  addTask(task: Task) {
    this.siteStore.addTask(task);
  }

  startTaskUpdatesPolling(siteId: string) {
    return this.apiPoller.poll<GetAllTasksResponse>(`${getServiceUrl('fms')}/tasks?site=${siteId}`, PERMISSIONS.flights.read).pipe(
      tap((response: GetAllTasksResponse) => {
        // Find removed tasks and update resource links
        const prevTasks = this.siteQuery.getAllTasks();
        const removedTasks = differenceBy(prevTasks, response.tasks, 'id');
        removedTasks.forEach(task => this.resourceLinksService.removeResource(task.id, ResourceLinkType.TASK));

        this.upsertTasks(response.tasks);
      })
    );
  }

  setActiveTask(siteId: string, task: Task = null) {
    this.siteStore.tasks.setActive(task?.id);

    if (task) {
      this.analyticsService.enterToFlight(task.id, task.flightDateLabel);

      return merge(
        this.checkAvailableModels(task.id),
        this.siteEntitiesService.fetchMeasurements(siteId, task.id),
        this.fetchTaskGCPs(siteId, task)
      );
    }

    return of(null);
  }

  makeTaskActiveWithValidation(siteId: string, taskId?: string) {
    const finishedTasks = this.siteQuery.getAllFinishedTasks();
    if (finishedTasks && finishedTasks.length > 0) {
      let task: Task;
      if (taskId) {
        task = finishedTasks.find(t => t.id === taskId);
      }

      // If no taskId is given or task not found, select latest task
      if (!task) {
        task = finishedTasks[finishedTasks.length - 1];
      }

      this.makeTaskActive(siteId, task.id);
    } else {
      this.makeTaskActive(siteId);
    }
  }

  makeTaskActive(siteId: string, taskId?: string) {
    this.siteEntitiesService.setMeasurementsLoading(true);
    const activeEntityType = this.siteEntitiesQuery.getActiveEntityType();
    if (isDefined(activeEntityType) && activeEntityType in MeasurementType) {
      this.siteEntitiesService.setActiveEntity(null);
    }

    const tenantId = this.authQuery.getActiveTenantId();
    const navigationOptions: NavigationExtras = { replaceUrl: true, queryParamsHandling: 'merge' };
    if (taskId) {
      if (this.siteQuery.getActiveTaskId() === taskId) {
        return;
      }
      this.router.navigate([tenantId, 'sites', siteId, 'tasks', taskId], navigationOptions);
    } else {
      this.router.navigate([tenantId, 'sites', siteId], navigationOptions);
    }
  }

  private checkAvailableModels(taskId: string) {
    const terrainCompleteJsonUrl = this.siteQuery.getViewerUrls(taskId).terrainComplete + '/layer.json';
    const queryParams = generateViewerCredentialsQueryParams(this.siteQuery.getViewerCredentials());

    return this.isModelAvailable(`${terrainCompleteJsonUrl}?${queryParams}`).pipe(
      tap(available => this.siteStore.updateDSMAvailable(!!available))
    );
  }

  private isModelAvailable(url: string) {
    return from(fetch(url, { method: 'head' }).then(response => response?.ok)).pipe(catchError(() => of(false)));
  }

  private fetchTaskGCPs(siteId: string, task: Task) {
    // Don't fetch task GCPs if task georef method doesn't support GCPs, or is autogeoref task (in prod), or task is imported
    if (
      !(task.georefMethod in GEOREF_METHODS_WITH_GCP) ||
      (environment.production && task.georefMethod === GeorefMethodEnum.AUTOGEOREF) ||
      task.flightSource === FlightSourceEnum.IMPORTED
    ) {
      this.siteStore.setGCPOverlay(task.id, []);
      return of(null);
    }

    const gcpOverlay = this.siteQuery.getOverlay(OverlaysEnum.GCPS);

    // GCPs already fetched
    if ('taskId' in gcpOverlay && gcpOverlay.taskId === task.id) {
      return of(null);
    }

    return this.getTaskGCPs(siteId, task.id).pipe(
      tap((response: GetAllGcpsResponse) => {
        if (isDefined(response?.gcps)) {
          const offset = this.siteQuery.getTerrainHeightOffset();
          const gcps = offset ? response.gcps.map(g => ({ ...g, z: g.z - offset })) : response.gcps;
          this.siteStore.setGCPOverlay(task.id, gcps);

          this.clampGCPsToCurrentModel().then();
        }
      })
    );
  }

  getTaskGCPs(siteId: string, taskId: string) {
    return this.http.get(`${getServiceUrl('mms')}/sites/${siteId}/tasks/${taskId}/gcps`, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.gcps.read }
    });
  }

  private async clampGCPsToCurrentModel() {
    const siteId = this.siteQuery.getSiteId();
    const taskId = this.siteQuery.getActiveTaskId();
    const sourceModel = this.siteQuery.getCurrentSourceModel();
    const terrain = await this.terrainProviderService.getTerrainProvider(siteId, taskId, 'TASK', sourceModel);

    const gcpOverlay = this.siteQuery.getOverlay(OverlaysEnum.GCPS) as { show: boolean; taskId: string; gcps: GCPItem[] };
    const positions = gcpOverlay.gcps
      .map(gcp => {
        if (gcp.longitude && gcp.latitude) {
          return Cesium.Cartesian3.fromDegrees(gcp.longitude, gcp.latitude);
        }
      })
      .filter(pos => !!pos);

    if (positions && positions.length > 0) {
      this.terrainSampling.sampleTerrain(positions, terrain, false).then(clampedPositions => {
        const clampedGCPs = gcpOverlay.gcps.map((gcp, i) => {
          const { height } = clampedPositions[i];
          return { ...gcp, z: height, altitude: height };
        });
        this.siteStore.updateGCPOverlay(taskId, clampedGCPs);
      });
    }
  }

  showOverlay(overlay: OverlaysEnum, show: boolean) {
    this.siteStore.showOverlay(overlay, show);
  }

  updateElevationOverlay(options: ElevationOptions) {
    this.siteStore.updateElevationOverlay(options);
  }

  updateContourOverlay(options: ContourOptions) {
    this.siteStore.updateContourOverlay(options);
  }

  generateDownloadableContours(taskId: string, major: number, minor: number) {
    const pollingFrequency = 10 * 1000;
    const siteId = this.siteQuery.getSiteId();
    this.siteStore.setContourGenerationInProgress(true);
    return this.http
      .post(
        `${getServiceUrl('fms')}/tasks/${taskId}/generateContours`,
        { major, minor },
        { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.downloads } }
      )
      .pipe(
        switchMap(({ batchId }: { batchId: string }) =>
          this.apiPoller.poll(`${getServiceUrl('jms')}/sites/${siteId}/batches/${batchId}`, PERMISSIONS.downloads, pollingFrequency)
        ),
        skipWhile((response: GetBatchResponse) => response.state === GetBatchResponse.StateEnum.PROCESSING),
        switchMap((response: GetBatchResponse) => {
          this.siteStore.setContourGenerationInProgress(false);
          return of(response.state === GetBatchResponse.StateEnum.READY);
        })
      );
  }

  generateTaskECWArtifact(taskId: string) {
    const siteId = this.siteQuery.getSiteId();
    return this.http
      .post(`${getServiceUrl('file')}/sites/${siteId}/tasks/${taskId}/artifacts/generateEcwForTask`, null)
      .pipe(tap(() => this.analyticsService.generateFlightOrthophotoECW(siteId, taskId)));
  }

  generateTaskImagesZipArtifact(taskId: string) {
    const siteId = this.siteQuery.getSiteId();
    return this.http
      .post(`${getServiceUrl('file')}/sites/${siteId}/tasks/${taskId}/artifacts/generateZipImagesForTask`, null)
      .pipe(tap(() => this.analyticsService.generateFlightImagesZip(siteId, taskId)));
  }

  setMapTextureType(mapTextureType: MapTextureType, byUser = true) {
    this.siteStore.updateMapTexture(mapTextureType);

    this.clampGCPsToCurrentModel().then();

    if (byUser) {
      this.analyticsService.changeMapTextureType(mapTextureType);
    }
  }

  setTerrainHeightOffset(offset: number) {
    // Convert siteOffset to meters if site CS is not local
    if (!this.siteQuery.getIsLocalCS()) {
      offset = convertToMeters(offset, this.siteQuery.getSiteUnits());
    }

    this.siteStore.setTerrainHeightOffset(offset);
    this.designsService.setSiteOffset(offset);
  }

  fetchTaskArtifacts(siteId: string, taskId: string) {
    return this.http.get<GetAllArtifactsResponse>(`${getServiceUrl('file')}/sites/${siteId}/tasks/${taskId}/artifacts`, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.downloads }
    });
  }

  fetchGradeCheckingReports(siteId: string) {
    return this.http.get(`${getServiceUrl('file')}/sites/${siteId}/gradeCheckReports`, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.downloads }
    });
  }

  getGradeCheckingReportsForMeasuredPoints(siteId: string, measuredPoints: MeasuredPoints) {
    return this.http.get(`${getServiceUrl('file')}/sites/${siteId}/gradeCheckReports/measuredPoints/${measuredPoints.id}`, {
      headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.downloads }
    });
  }

  getGradeCheckingReportsForRoadDesigns() {
    return of({ gradeCheckReportsList: [] });
  }

  openFlightWizard({ step, taskId }: { step: Step; taskId?: string }) {
    // Check permissions before allowing to open wizard
    let accessLevel = ACCOUNT_USER_ACCESS_LEVELS[0];
    if (step === Step.GCP) {
      accessLevel = PERMISSIONS.gcps.create;
    } else if ([Step.IMAGES, Step.FLIGHT_INFO].includes(step)) {
      accessLevel = taskId ? PERMISSIONS.flights.update : PERMISSIONS.flights.create;
    } else if (step === Step.PREPROCESSING) {
      accessLevel = PERMISSIONS.images.create;
    }
    if (!this.authQuery.hasAccessLevel(accessLevel)) {
      return;
    }

    const mode = !taskId
      ? UploadWizardMode.CREATE
      : this.siteQuery.getTaskState(taskId) === TaskStateEnum.UPLOADEDBYOPERATOR
      ? UploadWizardMode.APPROVE
      : UploadWizardMode.EDIT;

    const prevTask = taskId && this.siteQuery.getPrevTask(taskId, true);

    const site = this.siteQuery.getSite();
    const wizardDialogRef = this.wizardDialog.open({
      site,
      taskId,
      taskIndex: (taskId ? this.siteQuery.getTaskIndex(taskId) : this.siteQuery.getAllTasksCount()) + 1,
      prevTaskHadGCP: this.siteQuery.getPrevTaskHadGCP(taskId),
      prevTaskId: prevTask?.id,
      step,
      mode,
      callbacks: {
        updateSite: (updatedSite: Site) => this.updateSite(updatedSite),
        addTask: (newTask: Task) => this.addTask(newTask),
        updateTask: (updatedTask: Task) => this.updateTask(updatedTask),
        selectTask: (taskId: string) => this.siteQuery.selectTask(taskId),
        fetchTaskImages: (taskId: string, isLinked: boolean) => this.fetchTaskImages(taskId, isLinked)
      }
    });

    this.siteStore.setFlightWizardDialogId(wizardDialogRef.id);
  }

  closeFlightWizard() {
    const flightWizardDialogId = this.siteQuery.getFlightWizardDialogId();
    if (flightWizardDialogId) {
      this.dialog.getDialogById(flightWizardDialogId)?.close();
      this.siteStore.setFlightWizardDialogId(null);
    }
  }

  resumeFlightUpload(siteId: string, taskId: string) {
    const currentSiteId = this.siteQuery.getSiteId();
    if (siteId === currentSiteId) {
      this.uploadWizardService.setUploadingLoading(true);
      return this.http
        .get(`${getServiceUrl('fms')}/tasks/${taskId}`, {
          headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.flights.read }
        })
        .pipe(
          tap((task: Task) => {
            this.upsertTasks([task]);
            this.openFlightWizard({ step: Step.PREPROCESSING, taskId });
          }),
          switchMap(() => this.uploadWizardService.uploadTaskImages(taskId, true))
        );
    }
  }

  setIsSimpleView(isSimpleView: boolean) {
    this.siteStore.setIsSimpleView(isSimpleView);
  }

  addExitSiteToAnalytics() {
    this.analyticsService.exitFromSite();
  }

  setIsScreenshotGenerating(isScreenshotGenerating: boolean) {
    this.siteStore.setIsScreenshotGenerating(isScreenshotGenerating);
  }

  resetStore() {
    this.designsService.resetStore();
    this.siteEntitiesService.resetStore();
    this.siteActivitiesService.resetStore();
    this.reportsService.resetStore();
    this.siteStore.reset();
  }

  setMapOpacity(mapOpacity: number) {
    this.siteStore.setMapOpacity(mapOpacity);
    this.analyticsService.changeMapOpacity(mapOpacity);
  }

  setMapStyle(mapStyle: MapStyle) {
    this.siteStore.setMapStyle(mapStyle);
    this.analyticsService.changeMapStyle(mapStyle);
  }

  setActiveTab(tab: TabEnum) {
    this.siteStore.setActiveTab(tab);
  }

  expandGroup(tab: TabEnum, groupId: string, isExpanded: boolean) {
    this.siteStore.setTabExpandedGroups(tab, groupId, isExpanded);
  }

  clearTabExpandedGroups(tab: TabEnum) {
    this.siteStore.clearTabExpandedGroups(tab);
  }

  openShareDialog(resource: SharedResource) {
    const site = this.siteQuery.getSite();
    this.shareResourceDialog.openDialog(
      resource,
      site,
      resource.type === ResourceType.MEASUREMENT ? this.siteQuery.getActiveTask() : null,
      (users: User[]) => this.updateSite({ ...site, users: [...site.users, ...users] })
    );
  }
}
