import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthQuery } from '../state/auth.query';
import { AccessLevelEnum, REQUIRED_ACCESS_LEVEL_HEADER } from '../state/auth.utils';

@Injectable()
export class AccessLevelInterceptor implements HttpInterceptor {
  constructor(private authQuery: AuthQuery) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // If access level header is present - check access level before sending request
    if (request.headers.has(REQUIRED_ACCESS_LEVEL_HEADER)) {
      if (!this.authQuery.hasAccessLevel(request.headers.get(REQUIRED_ACCESS_LEVEL_HEADER) as AccessLevelEnum)) {
        return throwError(
          () =>
            new HttpErrorResponse({
              error: 'You do not have sufficient permissions to make the request',
              status: 401,
              statusText: 'Unauthorized'
            })
        );
      }

      // Remove access level header
      request = request.clone({
        headers: request.headers.delete(REQUIRED_ACCESS_LEVEL_HEADER)
      });
    }

    return next.handle(request);
  }
}
