/**
 * Datumate UMS
 *
 *
 * OpenAPI spec version: 2.6.7
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LoginResponse {
  accessLevel?: LoginResponse.AccessLevelEnum;
  accessToken?: string;
  emailVerified?: boolean;
  firstName?: string;
  groupNames?: Array<string>;
  lastName?: string;
  logInState?: LoginResponse.LogInStateEnum;
  mfaRequestToken?: string;
  mfaRequired?: boolean;
  phone?: string;
  phoneVerified?: boolean;
  refreshToken?: string;
  tenantId?: string;
  tenantName?: string;
  userId?: string;
}
export namespace LoginResponse {
  export type AccessLevelEnum = 'OPERATOR_USER' | 'VIEW_ONLY' | 'BASIC_USER' | 'ANALYTICS_USER' | 'TENANT_ADMIN';
  export const AccessLevelEnum = {
    OPERATORUSER: 'OPERATOR_USER' as AccessLevelEnum,
    VIEWONLY: 'VIEW_ONLY' as AccessLevelEnum,
    BASICUSER: 'BASIC_USER' as AccessLevelEnum,
    ANALYTICSUSER: 'ANALYTICS_USER' as AccessLevelEnum,
    TENANTADMIN: 'TENANT_ADMIN' as AccessLevelEnum
  };
  export type LogInStateEnum = 'SUCCESS' | 'LOCKED' | 'INVALID_CREDENTIALS' | 'USER_NOT_ACTIVE' | 'INVALID_IP_RANGE' | 'EXPIRED_PACKAGE';
  export const LogInStateEnum = {
    SUCCESS: 'SUCCESS' as LogInStateEnum,
    LOCKED: 'LOCKED' as LogInStateEnum,
    INVALIDCREDENTIALS: 'INVALID_CREDENTIALS' as LogInStateEnum,
    USERNOTACTIVE: 'USER_NOT_ACTIVE' as LogInStateEnum,
    INVALIDIPRANGE: 'INVALID_IP_RANGE' as LogInStateEnum,
    EXPIREDPACKAGE: 'EXPIRED_PACKAGE' as LogInStateEnum
  };
}
