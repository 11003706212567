import { Injectable } from '@angular/core';
import { Cartesian3 } from 'angular-cesium';
import { capitalize, groupBy } from 'lodash';
import moment from 'moment';
import { LocaleService } from '../../../shared/services/locale.service';
import { isDefined } from '../../../shared/utils/general';
import { roundTo } from '../../../shared/utils/math';
import { UnitsEnum, convertFromMeters, getUnitSign } from '../../../shared/utils/unit-conversion';
import { DetailedSiteQuery } from '../detailed-site.query';
import {
  Activity,
  ActivityMeasurement,
  ActivityMeasurementType,
  ActivityType,
  DateDiffUnits,
  DatesDiffWithUnits
} from './detailed-site-activities.model';
import { DetailedSiteActivitiesQuery } from './detailed-site-activities.query';

let newMeasurementIdsCount = 0;
const NEW_ACTIVITY_MEASUREMENT_ID_PREFIX = 'new-act-meas-';

export function generateNewActivityMeasurementId() {
  return `${NEW_ACTIVITY_MEASUREMENT_ID_PREFIX}${++newMeasurementIdsCount}`;
}

export function isNewActivityMeasurement(id: string) {
  return id?.startsWith(NEW_ACTIVITY_MEASUREMENT_ID_PREFIX);
}

export function isActivityMeasurementPositionsLengthValid(type: ActivityType, positions: Cartesian3[]) {
  if (positions) {
    switch (type) {
      case ActivityType.COUNT:
        return positions.length === 1;
      case ActivityType.LENGTH:
        return positions.length > 1;
      case ActivityType.AREA:
      case ActivityType.VOLUME:
      case ActivityType.WEIGHT:
        return positions.length > 2;
      default:
        return false;
    }
  }
  return false;
}

const getUnitExponentFromActivityType = (activityType: ActivityType) => {
  switch (activityType) {
    case ActivityType.LENGTH:
      return 1;
    case ActivityType.AREA:
      return 2;
    case ActivityType.VOLUME:
      return 3;
    case ActivityType.WEIGHT:
    case ActivityType.COUNT:
    default:
      return null;
  }
};

export function isCount(value: Activity | ActivityType) {
  if (!isDefined(value)) {
    return;
  }
  return (value as Activity)?.activityType === ActivityType.COUNT || (value as ActivityType) === ActivityType.COUNT;
}

export function isPlanned(value: ActivityMeasurement | ActivityMeasurementType) {
  if (!isDefined(value)) {
    return;
  }
  return (
    (value as ActivityMeasurement)?.measurementType === ActivityMeasurementType.PLANNED ||
    (value as ActivityMeasurementType) === ActivityMeasurementType.PLANNED
  );
}

export function isActual(value: ActivityMeasurement | ActivityMeasurementType) {
  return !isPlanned(value);
}

export function isTemp(measurement: ActivityMeasurement) {
  if (!isDefined(measurement)) {
    return;
  }
  return measurement.isTemp;
}

export const sortMeasurementsByDate = (m1: ActivityMeasurement, m2: ActivityMeasurement) => (moment(m1.date).isAfter(m2.date) ? 1 : -1);

export function getDatesDiffWithUnits(
  comparisonDate: Date,
  baseDate: Date,
  options: { daysLimit: number; monthLimit: number } = { daysLimit: 100, monthLimit: 100 }
): DatesDiffWithUnits {
  if (!(isDefined(comparisonDate) && isDefined(baseDate))) {
    return;
  }
  const formattedComparisonDate = moment.utc(comparisonDate);
  const formattedBaseDate = moment.utc(baseDate);

  let value = formattedComparisonDate.diff(formattedBaseDate, 'days');
  let units: DateDiffUnits = DateDiffUnits.DAY;
  if (Math.abs(value) >= options.daysLimit) {
    value = formattedComparisonDate.diff(formattedBaseDate, 'month');
    units = DateDiffUnits.MONTH;
    if (Math.abs(value) >= options.monthLimit) {
      value = formattedComparisonDate.diff(formattedBaseDate, 'year');
      units = DateDiffUnits.YEAR;
    }
  }
  return { value, units };
}

export function groupMeasurementsByType(measurements: ActivityMeasurement[]) {
  const groupedMeasurements = groupBy(measurements, 'measurementType');
  return {
    plannedMeasurements: groupedMeasurements[ActivityMeasurementType.PLANNED] ?? [],
    actualMeasurements: groupedMeasurements[ActivityMeasurementType.ACTUAL] ?? []
  };
}

const MEASUREMENT_NAME_DIVIDERS = {
  [ActivityMeasurementType.PLANNED]: '-',
  [ActivityMeasurementType.ACTUAL]: '#'
};

export function getMeasurementValue(measurement: ActivityMeasurement) {
  const valueType = measurement?.volumeValueType?.toLowerCase();
  return isDefined(measurement?.values) ? (valueType ? measurement.values[valueType] : Object.values(measurement.values)[0]) : null;
}

export function calcMeasurementValuesSum(measurements: ActivityMeasurement[]) {
  return isDefined(measurements) ? measurements.reduce((sum, m) => sum + getMeasurementValue(m), 0) : null;
}

export const ACTIVITY_TYPE_NAME = {
  [ActivityType.AREA]: $localize`:@@detailedSite.activityTypeName.area:Area`,
  [ActivityType.COUNT]: $localize`:@@detailedSite.activityTypeName.count:Count`,
  [ActivityType.LENGTH]: $localize`:@@detailedSite.activityTypeName.length:Length`,
  [ActivityType.VOLUME]: $localize`:@@detailedSite.activityTypeName.volume:Volume`,
  [ActivityType.WEIGHT]: $localize`:@@detailedSite.activityTypeName.weight:Weight`
};

@Injectable({
  providedIn: 'root'
})
export class DetailedSiteActivitiesUtilsService {
  constructor(
    private activitiesQuery: DetailedSiteActivitiesQuery,
    private siteQuery: DetailedSiteQuery,
    private localeService: LocaleService
  ) {}

  generatePlannedMeasurementName(activityType: ActivityType) {
    if (isCount(activityType)) {
      return `${capitalize(activityType)}`;
    }

    const plannedMeasurementsWithName = this.activitiesQuery.getPlannedMeasurements().filter(pm => isDefined(pm.name));
    const counter = this.calcNameCounter(plannedMeasurementsWithName);
    return `${capitalize(activityType)}${MEASUREMENT_NAME_DIVIDERS[ActivityMeasurementType.PLANNED]}${counter}`;
  }

  generateActualMeasurementName(plannedMeasurementId: string) {
    const plannedMeasurementName = this.activitiesQuery.getActivityMeasurementById(plannedMeasurementId).name;
    const actualMeasurementsWithName = this.activitiesQuery
      .getActualMeasurementsByPlannedMeasurementId(plannedMeasurementId)
      ?.filter(am => isDefined(am.name));
    const counter = this.calcNameCounter(actualMeasurementsWithName);
    return `${plannedMeasurementName}: ${MEASUREMENT_NAME_DIVIDERS[ActivityMeasurementType.ACTUAL]}${counter}`;
  }

  convertToSiteUnitsValueWithSign(value: number, activityType: ActivityType): string {
    if (!isDefined(value)) {
      return '';
    }

    const exponent = getUnitExponentFromActivityType(activityType);
    if (isDefined(exponent)) {
      const siteUnits = this.siteQuery.getSiteUnits();
      value = convertFromMeters(value, siteUnits, exponent);
    }

    const formatedValue = this.localeService.formatNumber(roundTo(value), {
      minimumFractionDigits: isCount(activityType) ? 0 : 2
    });

    const unitsSign = this.getUnitSignFromActivityType(activityType);
    return `${formatedValue}${unitsSign ? ' ' + unitsSign : ''}`;
  }

  private calcNameCounter(measurements: ActivityMeasurement[]) {
    if (!isDefined(measurements)) {
      return 1;
    }
    const existingCounters = measurements.map(m => {
      const name = m.name;
      const divider = MEASUREMENT_NAME_DIVIDERS[m.measurementType];
      const lastIndex = name.lastIndexOf(divider);
      return lastIndex > 0 ? parseInt(name.slice(lastIndex + 1)) : 0;
    });
    return Math.max(...existingCounters) + 1;
  }

  getUnitSignFromActivityType(activityType: ActivityType) {
    const siteUnits = this.siteQuery.getSiteUnits();
    switch (activityType) {
      case ActivityType.LENGTH:
        return getUnitSign(siteUnits);
      case ActivityType.AREA:
        return getUnitSign(siteUnits, 2);
      case ActivityType.VOLUME:
        return getUnitSign(siteUnits, 3);
      case ActivityType.WEIGHT:
        return getUnitSign(UnitsEnum.TON);
      case ActivityType.COUNT:
      default:
        return '';
    }
  }
}
