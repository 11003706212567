import { ColorRangesModel } from '../../../../generated/file/model/colorRangesModel';
import { ColorsPaletteModel } from '../../../../generated/file/model/colorsPaletteModel';
import { GetCrossSectionProgressReportDataResponse } from '../../../../generated/file/model/getCrossSectionProgressReportDataResponse';
import { GetCrossSectionReportDataResponse } from '../../../../generated/file/model/getCrossSectionReportDataResponse';
import { GetRoadDesignProgressReportModelResponse } from '../../../../generated/file/model/getRoadDesignProgressReportModelResponse';
import { GetTasksProgressReportModelResponse } from '../../../../generated/file/model/getTasksProgressReportModelResponse';
import { VolumeGridReportModel } from '../../../../generated/file/model/volumeGridReportModel';

export import CrossSectionReportSurfaceEnum = GetCrossSectionReportDataResponse.Surface1Enum;

export const CROSS_SECTION_REPORT_SURFACE_TYPE_CAPTION = {
  [GetCrossSectionReportDataResponse.Surface1Enum.ASBUILD]: $localize`:@@detailedSite.crossSectionVolumeReport.surfaceModel:Model surface`,
  [GetCrossSectionReportDataResponse.Surface1Enum.TOP]: $localize`:@@detailedSite.crossSectionVolumeReport.surfaceTop:Top Surface`,
  [GetCrossSectionReportDataResponse.Surface1Enum.BOTTOM]: $localize`:@@detailedSite.crossSectionVolumeReport.surfaceBottom:Bottom Surface`
};

export interface CrossSectionVolumeReport extends GetCrossSectionReportDataResponse {
  type: ReportType.CROSS_SECTION_VOLUME;
}

export interface CrossSectionProgressReport extends GetCrossSectionProgressReportDataResponse {
  type: ReportType.CROSS_SECTION_PROGRESS;
}

export import GridReportType = VolumeGridReportModel.GridReportTypeEnum;
export type ColorRange = ColorRangesModel;
export type ColorPalette = ColorsPaletteModel;
export import ColorsPaletteType = VolumeGridReportModel.ColorsPaletteTypeEnum;

export interface VolumeGridHeatmapReport extends VolumeGridReportModel {
  type: ReportType.VOLUME_GRID_HEATMAP;
}

export interface ElevationGridHeatmapReport extends VolumeGridReportModel {
  type: ReportType.ELEVATION_GRID_HEATMAP;
}

export type ReportEntity = CrossSectionVolumeReport | VolumeGridHeatmapReport | ElevationGridHeatmapReport | CrossSectionProgressReport;
export import ReportState = GetCrossSectionReportDataResponse.StateEnum;

export import ProgressReportTasksSurfaceType = GetTasksProgressReportModelResponse.SurfaceTypeEnum;
export import ProgressReportDesignSurfaceType = GetRoadDesignProgressReportModelResponse.SurfaceTypeEnum;

export enum ReportType {
  CROSS_SECTION_VOLUME = 'CROSS_SECTION_VOLUME',
  CROSS_SECTION_PROGRESS = 'CROSS_SECTION_PROGRESS',
  VOLUME_GRID_HEATMAP = 'VOLUME_GRID_HEATMAP',
  ELEVATION_GRID_HEATMAP = 'ELEVATION_GRID_HEATMAP'
}

export const REPORT_TYPE_CAPTIONS = {
  [ReportType.CROSS_SECTION_VOLUME]: $localize`:@@reports.crossSectionVolumeReport:Cross Section Volume Report`,
  [ReportType.CROSS_SECTION_PROGRESS]: $localize`:@@reports.crossSectionProgressReport:Cross Section Progress Report`,
  [ReportType.VOLUME_GRID_HEATMAP]: $localize`:@@reports.volumeGridHeatmapReport:Volume Grid Heatmap Report`,
  [ReportType.ELEVATION_GRID_HEATMAP]: $localize`:@@reports.elevationGridHeatmapReport:Elevation Grid Heatmap Report`
};

export const REPORT_TYPE_ICONS = {
  [ReportType.CROSS_SECTION_VOLUME]: 'report_cross_sec_vol',
  [ReportType.CROSS_SECTION_PROGRESS]: 'report_cross_sec_prog',
  [ReportType.VOLUME_GRID_HEATMAP]: 'report_vol_grid_heat',
  [ReportType.ELEVATION_GRID_HEATMAP]: 'report_elev_grid_heat'
};

export const REPORT_TYPE_NAMES = {
  [ReportType.CROSS_SECTION_VOLUME]: 'crossSectionVolume',
  [ReportType.CROSS_SECTION_PROGRESS]: 'crossSectionProgress',
  [ReportType.VOLUME_GRID_HEATMAP]: 'volumeGridHeatmap',
  [ReportType.ELEVATION_GRID_HEATMAP]: 'elevationGridHeatmap'
};
