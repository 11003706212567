export const GZIP_HEADERS = Object.freeze({
  'Content-Type': 'application/json',
  'Content-Encoding': 'gzip'
});

export async function gzip(obj: Object) {
  const gzipPako = (await import('../../../../node_modules/pako')).gzip;
  const gzippedBody = gzipPako(decodeURIComponent(encodeURIComponent(JSON.stringify(obj))));
  return new Blob([gzippedBody]);
}
