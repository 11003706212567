import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { GetResourceLinksResponse } from '../../../generated/resourcelinks/model/getResourceLinksResponse';
import { UpdateResourceLinksRequest } from '../../../generated/resourcelinks/model/updateResourceLinksRequest';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../auth/state/auth.utils';
import PERMISSIONS from '../../auth/state/permissions';
import { AnalyticsService } from '../services/analytics.service';
import { getServiceUrl } from '../utils/backend-services';
import { isDefined } from '../utils/general';
import { ResourceLink, ResourceLinkType } from './resource-links.model';
import { ResourceLinksQuery } from './resource-links.query';
import { ResourceLinksStore } from './resource-links.store';

@Injectable({
  providedIn: 'root'
})
export class ResourceLinksService {
  constructor(
    private store: ResourceLinksStore,
    private query: ResourceLinksQuery,
    private http: HttpClient,
    private analytics: AnalyticsService
  ) {}

  /**
   * Fetches all resource links for a specified resource. Saves them into the store as a side-effect.
   *
   * @param siteId the siteId of the resource
   * @param resourceId ID of resource to fetch links for.
   * @param resourceType type of resource to fetch links for.
   * @returns an array of links for the resource
   */
  fetchResourceLinks(siteId: string, resourceId: string, resourceType: ResourceLinkType): Observable<ResourceLink[]> {
    return this.http
      .get<GetResourceLinksResponse>(`${getServiceUrl('resourcelinks')}/sites/${siteId}/resourceLinks/${resourceId}`, {
        params: { type: resourceType },
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.resourceLinks.read }
      })
      .pipe(
        map(response => response?.links),
        tap(links => {
          if (isDefined(links)) {
            this.insertResourceLinks(resourceId, resourceType, links);

            links.forEach(link => this.addResourceLink(link.resourceId, link.resourceType, { resourceId, resourceType }, false));
          }
        })
      );
  }

  /**
   * Inserts links to a resource in the store
   *
   * @param resourceId ID of resource to link from.
   * @param resourceType type of resource to link from.
   * @param links the links to insert.
   */
  insertResourceLinks(resourceId: string, resourceType: ResourceLinkType, links: ResourceLink[]) {
    this.store.insertResourceLinks(resourceId, resourceType, links);
  }

  /**
   * Adds a new link from a resource to another.
   *
   * @param resourceId ID of resource to link from.
   * @param resourceType type of resource to link from.
   * @param link the link to add.
   * @param markOnly When true adds the link and marks it for save, when false add to links without marking. Default true.
   */
  addResourceLink(resourceId: string, resourceType: ResourceLinkType, link: ResourceLink, markOnly = true) {
    let links = this.query.getResourceLinks(resourceId, resourceType);

    const existingLink = links.find(l => l.resourceId === link.resourceId && l.resourceType === link.resourceType);
    if (existingLink) {
      links = links.map(l =>
        l.resourceId === link.resourceId && l.resourceType === link.resourceType
          ? { ...l, markedForDelete: false, markedForSave: false }
          : l
      );
    } else {
      links = [...links, { ...link, markedForSave: markOnly }];
    }

    const isDirty = this.calcIsLinksDirty(links);
    this.store.updateResourceLinks(resourceId, resourceType, links, isDirty);

    if (markOnly) {
      this.analytics.addResourceLink(resourceId, resourceType, link.resourceId, link.resourceType);
    }
  }

  /**
   * Removes a link between resources.
   *
   * @param resourceId ID of resource the link starts from.
   * @param resourceType type of resource the link starts from.
   * @param link the link to remove.
   * @param markOnly When true only marks the link for delete, when false removes it immediately from links in store without marking. Default true.
   */
  removeResourceLink(resourceId: string, resourceType: ResourceLinkType, link: ResourceLink, markOnly = true) {
    let links = this.query.getResourceLinks(resourceId, resourceType);

    const existingLink = links.find(l => l.resourceId === link.resourceId && l.resourceType === link.resourceType);
    if (!existingLink) {
      return;
    }

    if (existingLink.markedForSave || !markOnly) {
      // New link OR not markOnly - remove from links list
      links = links.filter(l => l.resourceId !== link.resourceId || l.resourceType !== link.resourceType);
    } else {
      links = links.map(l =>
        l.resourceId === link.resourceId && l.resourceType === link.resourceType ? { ...l, markedForDelete: markOnly } : l
      );
    }

    const isDirty = this.calcIsLinksDirty(links);
    this.store.updateResourceLinks(resourceId, resourceType, links, isDirty);

    if (markOnly) {
      this.analytics.removeResourceLink(resourceId, resourceType, link.resourceId, link.resourceType);
    }
  }

  /**
   * Called when a resource is deleted to remove its links and all the links from other resources to it.
   
   * @param resourceId ID of resource that was removed.
   * @param resourceType type of resource that was removed.
   */
  removeResource(resourceId: string, resourceType: ResourceLinkType) {
    this.store.removeResource(resourceId, resourceType);
  }

  /**
   * Called when multiple resources of a certain type are deleted to remove their links and all the links from other resources to them.
   * Mainly used when a project plan version is deleted.
   *
   * @param resourceIds a list of resource ids
   * @param resourceType type of resources that were removed.
   */
  removeResourcesOfType(resourceIds: string[], resourceType: ResourceLinkType) {
    this.store.removeResourcesOfType(resourceIds, resourceType);
  }

  /**
   * Called when all resources of a certain type are deleted to remove their links and all the links from other resources to them.
   * Mainly used when all project plans that contain activities are deleted.
   *
   * @param resourceType type of resources that were removed.
   */
  removeAllResourcesOfType(resourceType: ResourceLinkType) {
    this.store.removeAllResourcesOfType(resourceType);
  }

  private calcIsLinksDirty(links: ResourceLink[]) {
    return links?.some(link => link.markedForDelete || link.markedForSave);
  }

  /**
   * Delete all dirty links of a resource (links that are marked for save/delete)
   * @param resourceId
   * @param resourceType
   */
  discardDirtyResourceLinks(resourceId: string, resourceType: ResourceLinkType) {
    // Remove links to save and remove delete mark
    const links = this.query
      .getResourceLinks(resourceId, resourceType)
      .filter(link => !link.markedForSave)
      .map(link => ({ ...link, markedForDelete: false }));

    this.store.updateResourceLinks(resourceId, resourceType, links, false);
  }

  /**
   * Save all links of a resource to the server. Removes all save/delete marks and updates linked resources of the change
   * @param siteId
   * @param resourceId
   * @param resourceType
   */
  serverUpdateResourceLinks(siteId: string, resourceId: string, resourceType: ResourceLinkType) {
    const links = this.query.getResourceLinks(resourceId, resourceType);

    // Remove links to delete and remove save mark
    const updatedLinks = links.filter(link => !link.markedForDelete).map(link => ({ ...link, markedForSave: false }));

    let request$: Observable<any>;
    if (isDefined(updatedLinks)) {
      const request: UpdateResourceLinksRequest = {
        links: updatedLinks,
        resourceId,
        resourceType
      };
      request$ = this.http.post(`${getServiceUrl('resourcelinks')}/sites/${siteId}/resourceLinks`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.resourceLinks.update }
      });
    } else {
      // Call delete API if links array is empty
      request$ = this.http.delete(`${getServiceUrl('resourcelinks')}/sites/${siteId}/resourceLinks/${resourceId}`, {
        params: { type: resourceType },
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.resourceLinks.delete }
      });
    }

    this.analytics.saveResourceLinks(resourceId, resourceType);

    return request$.pipe(
      tap(() => {
        this.store.updateResourceLinks(resourceId, resourceType, updatedLinks, false);

        // Update newly added links in linked resources
        const linksToSave = links.filter(link => link.markedForSave);
        linksToSave.forEach(link => {
          this.addResourceLink(link.resourceId, link.resourceType, { resourceId, resourceType }, false);
        });

        // Remove deleted links in linked resources
        const linksToDelete = links.filter(link => link.markedForDelete);
        linksToDelete.forEach(link => this.removeResourceLink(link.resourceId, link.resourceType, { resourceId, resourceType }, false));
      })
    );
  }
}
